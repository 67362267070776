import React, { useContext, useEffect, useRef } from 'react';
import { ContextProviderHook, ContextConnector, createActionComponent, yieldEventLoop, useDispatchContext } from './appcontext.js';
import * as appcontext from './appcontext.js';
import { AppFrameAction, AppFrameContext, useAppFrameContext } from './appframe_minimal.js';
import { DataBrowser } from './dataBrowser';
import { ErrorMessage } from '../../sys_app_pages/errorMessage.js';
import * as jsdset from './jsdsetconnect.js';
import * as dswidget from './dsetwidget.js';

/* visual components */
var Menu;
var SubMenu;
var MenuItem;
var Divider;

const staticComponents = {};

const CONFIGS = {
	SERVER_API_URI: '/api/',
	INVALID_SESSION_STATUS: 481,
	MAX_ROW_BROWSE: 15,
};
const FRAME_CONTAINER_BRANCH_NAME = 'appPage';
const FRAME_CONTAINER_PATH = '/' + FRAME_CONTAINER_BRANCH_NAME;

const GLOBAL_MODULES = {};

function initVisualLib(visualLibs) {
	Menu = visualLibs.Menu;
	SubMenu = visualLibs.SubMenu;
	MenuItem = visualLibs.MenuItem;
	Divider = visualLibs.Divider;
}

function setStaticComponents(components = {}) {
	Object.assign(staticComponents, components);
}

function initGlobalModules(modules) {
	Object.assign(GLOBAL_MODULES, modules);
}

function setupConfigs(configs) {
	Object.assign(CONFIGS, configs);
}

var StdAppState = {
	requesting: false,
	requestError: false,
	requestErrMessage: '',

	isLogin: false,
	loginError: false,
	loginErrMessage: '',

	loginToken: null,
	userID: '',
	userName: '',

	globals: {}, // global object for storing shared libaries and functions
	modules: {}, // loaded modules from server
};

const MenuAutoKeyGenerator = {
	counter: 0,
	getNext: function () {
		this.counter += 1;
		return 'menuAutoKey_' + this.counter;
	},
};

async function waitNSeconds(nSec) {
	return new Promise(resolve => {
		setTimeout(() => resolve(), nSec * 1000);
	});
}

/*
	abortableFetch: return object with abort() method to cancel fetch, and ready() to await
	credits to: Jake Archibald
	https://developers.google.com/web/updates/2017/09/abortable-fetch
*/

function abortableFetch(request, opts) {
	const controller = new AbortController();
	const signal = controller.signal;

	return {
		abort: () => controller.abort(),
		ready: fetch(request, { ...opts, signal }),
	};
}

var StdAppReducers = {
	getState: (state, { ref }) => {
		ref.state = { ...state };
	},
	startRequest: state => ({
		...state,
		requesting: true,
		requestError: false,
		requestErrMessage: '',
	}),
	finishRequest: (state, { isError, errMessage }) => ({
		...state,
		requesting: false,
		requestError: isError,
		requestErrMessage: errMessage,
	}),

	startLogin: state => ({
		...state,
		isLogin: false,
		loginToken: null,
		loginError: false,
		loginErrMessage: '',
	}),
	setLogin: (state, { token, userName, corporate, role }) => ({
		...state,
		isLogin: true,
		loginToken: token,
		userName,
		corporate,
		role,
	}),
	setExpiredSession: state => ({
		...state,
		isLogin: false,
		loginToken: null,
		loginError: true,
		loginErrMessage: 'Session expired. Re-login required',
	}),
	setLoginError: (state, { errMessage }) => ({
		...state,
		isLogin: false,
		loginError: true,
		loginErrMessage: errMessage,
	}),
	setLogout: state => ({
		...state,
		isLogin: false,
		loginToken: null,
		userName: null,
		corporate: {},
		globals: {},
		modules: {},
	}),

	/* 
	setExternalActions: (state, {frameAction}) => ({...state, frameAction}),
	setFrameActive: (state, {frameAction, isActive}) => {frameAction.setMainFrameActive(isActive);},
  */

	setGlobal: (state, { key, value }) => ({
		...state,
		globals: { ...state.globals, [key]: value },
	}),
	incGlobal: (state, { key, incValue }) => ({
		...state,
		globals: {
			...state.globals,
			[key]: (state.globals[key] || 0) + incValue,
		},
	}),
	setModule: (state, { moduleId, imports }) => ({
		...state,
		modules: { ...state.modules, [moduleId]: imports },
	}),
};

async function callServerAPI(path, payload = undefined, options = {}) {
	// options may contain the following
	// method: can ge 'POST' or 'GET' (default)
	// content_type:
	var fetchParams = {};
	var response;
	var payloadText;
	var { method, content_type } = options;

	if (typeof payload == 'object' && payload.constructor == Object) {
		payloadText = JSON.stringify(payload);
		fetchParams.headers = { 'content-type': 'application/json' };
	} else if (typeof payload == 'string') {
		payloadText = payload;
		if (content_type) fetchParams.headers = { 'content-type': content_type };
	} else if (typeof payload == 'object' && payload.constructor == FormData) {
		payloadText = payload;
	}

	if (payloadText) fetchParams.body = payloadText;

	fetchParams.method = method || 'GET';
	fetchParams.credentials = 'same-origin';

	var fetchResponse = await fetch(CONFIGS.SERVER_API_URI + path, fetchParams);
	const httpStatus = fetchResponse.status;
	const httpReason = fetchResponse.statusText;
	if (fetchResponse.status != 200) return { httpStatus, httpReason };
	var contentType = fetchResponse.headers.get('content-type');
	if (contentType && contentType.includes('application/json')) {
		response = await fetchResponse.json();
	} else if (contentType && (contentType.includes('text/csv') || contentType.includes('application/pdf'))) {
		var filename = fetchResponse.headers.get('filename');
		// convert into blob
		var blob = await fetchResponse.blob();

		// create blob link to download
		const url = window.URL.createObjectURL(new Blob([blob]));
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', filename);
		// append to html page
		document.body.appendChild(link);
		// force download
		link.click();
		// clean up and remove the link
		link.parentNode.removeChild(link);
		response = 'success';
	} else {
		response = await fetchResponse.text();
	}
	return { httpStatus, httpReason, contentType, response };
}

const StdAppContext = React.createContext(null);
class StdAppAction_Base {
	constructor(disp) {
		this.disp = disp;
	}

	checkFrameAction() {
		if (!this.frameAction) throw Error('Incomplete StdAppAction_Base object, this.frameAction is not set');
	}

	setFrameAction(frameAction) {
		this.frameAction = frameAction;
	}

	async apiRequest(path, requestPayload, batchRequest = false, method = 'GET') {
		var actRequest;
		var isError, errMessage, errObject;

		this.checkFrameAction();

		if (!batchRequest) {
			await yieldEventLoop();
			this.disp({ type: 'startRequest' });
		}

		if (requestPayload) method = 'POST'; // requests with payload must use 'POST' method

		try {
			var { httpStatus, httpReason, contentType, response } = await callServerAPI(path, requestPayload, { method });
			// dummy wait to test wait effect
			// await waitNSeconds(1)
			if (httpStatus == CONFIGS.INVALID_SESSION_STATUS) {
				this.disp({ type: 'setExpiredSession' });
				this.frameAction.clearPages();
				throw new Error('Expired session');
			} else {
				if (httpStatus != 200) throw new Error(`HTTP error ${httpStatus}: ${httpReason}`);
				if (typeof response == 'object' && contentType.includes('application/json') && response.status != '000') {
					if (path == 'app/login') {
						throw new Error(`${response.description}`);
					} else {
						throw new Error(`${response.err_info}`);
					}
				}
			}

			isError = false;
		} catch (err) {
			isError = true;
			errMessage = err.message;
			errObject = err;
			console.error(err);
		}

		if (!batchRequest) this.disp({ type: 'finishRequest', isError, errMessage });
		else if (isError) {
			throw errObject;
		}
		return [response, isError, errMessage];
	}

	// apiRequests: make sequence of API requests
	// each array component is an object containing the following data:
	// path: path of request
	// method: method of request, default = 'GET'
	// requestData: basic template of request
	// rmap: (req, i_s) => {}, map req (requestData) and i_s (initial state) to request data
	// sproc: (i_s, req, resp) => {}, map i_s (initial state), req (request) and resp (response) to create next state (initial state for next request)

	// any exception in the middle shall break the process
	async apiMultiRequest(arrRequests, initState) {
		var isError, errMessage, errObject;

		await yieldEventLoop();
		this.disp({ type: 'startRequest' });
		try {
			for (var i = 0; i < arrRequests.length; ++i) {
				var rdata = arrRequests[i];

				var path = rdata.path;
				var req = rdata.requestData;
				var method = rdata.method || 'GET';
				var rmap = rdata.rmap || ((req, i_s) => req);
				var sproc = rdata.sproc || ((i_s, req, resp) => i_s);

				var requestData = rmap(req, initState);
				var [response] = await this.apiRequest(path, requestData, true, method);

				var nextState = sproc(initState, requestData, response);
				initState = nextState;
			}
			isError = false;
		} catch (err) {
			isError = true;
			errMessage = err.message;
			errObject = err;
			console.error(err);
		}
		this.disp({ type: 'finishRequest', isError, errMessage });
		return [response, isError, errMessage];
	}

	async yieldEventLoop() {
		await yieldEventLoop();
	}

	async resetUI() {
		this.checkFrameAction();

		const frameAction = this.frameAction;

		frameAction.clearPages();
		frameAction.createPage('AppPage', FRAME_CONTAINER_BRANCH_NAME, 'Application', true, undefined, { appAction: this });
		frameAction.setInstanceTree([
			{
				name: FRAME_CONTAINER_BRANCH_NAME,
				pages: [], // to be added later dynamically
			},
		]);
	}

	async login(userId, password, appId, geolocation_info) {
		await yieldEventLoop();
		this.disp({ type: 'startLogin' });

		var [response, isError, errMessage] = await this.apiRequest(
			'app/login',
			{
				user_id: userId,
				password,
				app_id: appId,
				geolocation_info,
			},
			false,
			'POST',
		);

		if (isError) {
			this.disp({ type: 'setLoginError', errMessage });
		} else {
			if (response.status === '000') {
				const { corporate_id, corporate_name, role, user_name, isVa } = response;
				userId = userId.split('/')[0];
				this.disp({
					type: 'setLogin',
					token: response.token,
					userName: response.user_name,
					userID: userId,
					role,
					isVa,
					corporate: {
						corporate_id,
						corporate_name,
					},
				});
				this.resetUI();
			} else {
				this.disp({
					type: 'setLoginError',
					errMessage: response.description,
				});
			}
		}

		return { isError, response, errMessage };
	}

	// new fucntion created for keycloak
	async loginKeycloak(tokens) {
		await yieldEventLoop();
		this.disp({ type: 'startLogin' });

		var [response, isError, errMessage] = await this.apiRequest('app/login', { tokens, app_id: 'admin' }, false, 'POST');

		// console.log('login keycloak: ', response, isError, errMessage);
		// console.log('testing masuk ....')

		if (response.status === '000') {
			const { corporate_id, corporate_name, user_name, role } = response;
			this.disp({
				type: 'setLogin',
				token: response.token,
				userName: response.user_name,
				role,
				corporate: {
					corporate_id,
					corporate_name,
				},
			});
			this.resetUI();
		} else {
			this.disp({
				type: 'setLoginError',
				errMessage: response.description,
			});
		}
	}

	async logout() {
		try {
			var [response, isError, errMessage] = await this.apiRequest('app/logout', undefined, false, 'POST');
			if (isError) console.error(`Logout error ! ${errMessage}`);
			else {
				console.log('Logout successful');
				window.location.href = '/';
			}
		} finally {
			try {
				if (this.frameAction) this.frameAction.clearPages();
			} finally {
				this.disp({ type: 'setLogout' });
			}
		}
	}

	async fetchModule(moduleId, globals = undefined, enforceReload = false) {
		// moduleId can either be string for server-side resource module id
		// or can be array [string, ModuleDefinition] where ModuleDefinition is function

		var cstate = await this.getState(); // todo --> make it cleaner with current state parameter
		var modules = cstate.modules;
		var initModuleF;

		if (
			Array.isArray(moduleId) &&
			moduleId.length >= 2 &&
			typeof moduleId[0] === 'string' &&
			typeof moduleId[1] === 'function'
			//  && moduleId[1].name === 'ModuleDefinition'
			// FIXME: commented for development purpose only, at server it return false. file won't be found on server,
		) {
			initModuleF = moduleId[1]();
			moduleId = moduleId[0];
		}

		if (!enforceReload && modules[moduleId]) {
			return modules[moduleId];
		}

		globals = globals || {};

		if (!initModuleF) {
			var rawResult = await this.apiRequest(`app/getmodule?module_id=${encodeURIComponent(moduleId)}`, undefined, false, 'GET');
			var [resp, isErr, errMessage] = rawResult;
			if (isErr) {
				throw new Error(errMessage);
			}
			var moduleCode = resp;
			initModuleF = eval(moduleCode);
		}

		if (typeof initModuleF !== 'function') {
			throw new Error(`Module code in "${typeof moduleId === 'string' ? moduleId : 'ModuleDefinition'}" is not a function`);
		}

		return await this.evalModule(initModuleF, globals, moduleId);
	}

	async evalModule(initModuleFOrCode, globals = {}, moduleId) {
		var imports;
		var initModuleF;

		initModuleF = typeof initModuleFOrCode == 'string' ? eval(initModuleFOrCode) : initModuleFOrCode;
		var allGlobals = {
			_moduleId: moduleId,
			DataBrowser,
			StdAppAction,
			AppFrameAction,
			appAction: this,
			frameAction: this.frameAction,
			useAppFrameContext,
			useStdAppContext,
			appcontext,
			jsdset,
			dswidget,
			...GLOBAL_MODULES,
			...globals,
			staticComponents,
		};

		if (initModuleF.constructor.name === 'AsyncFunction') {
			imports = await initModuleF(React, allGlobals);
		} else if (initModuleF.constructor.name == 'Function') {
			imports = initModuleF(React, allGlobals);
		}

		if (moduleId) this.disp({ type: 'setModule', moduleId, imports });

		return imports;
	}

	async fetchAndExecModule(moduleId, globals = undefined, execParams = undefined, enforceReload = false) {
		const module = await this.fetchModule(moduleId, globals, enforceReload);
		return module.componentFactory(execParams);
	}

	async registersStaticModules(modules = {}) {
		var moduleInst;
		var globals;

		const moduleKeys = Object.keys(modules);
		for (var i = 0; i < moduleKeys.length; ++i) {
			var moduleInfo = modules[moduleKeys[i]];

			if (Array.isArray(moduleInfo)) {
				moduleInst = moduleInfo[0];
				globals = moduleInfo[1];
			} else {
				var moduleInst = moduleInfo;
				globals = undefined;
			}
			await this.fetchModule([moduleKeys[i], moduleInst], globals);
		}
	}

	setAccessToken(moduleId, resourceType, resourceId, tokenOrTokens) {
		// console.log(`setAccessToken called ${moduleId} ${resourceType} ${resourceId}`)
		if (resourceType == 'menu' && Array.isArray(tokenOrTokens)) {
			var tokens = tokenOrTokens;
			var dictTokens = {};
			for (var i = 0; i < tokens.length; ++i) {
				dictTokens[tokens[i].id] = tokens[i].token;
			}
			this.setGlobal(`menu_tokens/${moduleId}/${resourceId}`, dictTokens);
		} else if (resourceType == 'frame' && typeof tokenOrTokens == 'string') {
			this.setGlobal(`frame_token/${moduleId}`, tokenOrTokens);
		}
	}

	async getAccessToken(moduleId, resourceType, resourceId, itemId = undefined) {
		var cstate = await this.getState(); // todo --> make it cleaner with current state parameter

		console.log(`getAccessToken called moduleId: ${moduleId} resourceType: ${resourceType} resourceId: ${resourceId} itemId: ${itemId}`);
		if (resourceType == 'menu') {
			const tokens = cstate.globals[`menu_tokens/${moduleId}/${resourceId}`];
			return tokens && typeof tokens == 'object' && !Array.isArray(tokens) ? tokens[itemId] : undefined;
		} else if (resourceType == 'frame') {
			return cstate.globals[`frame_token/${moduleId}`];
		}
	}

	async fetchResource(moduleId, resourceType, dataId, authToken, payload = undefined) {
		var method = resourceType == 'postdata' ? 'POST' : 'GET';
		const enc_ = encodeURIComponent;
		var uri =
			`app/resource/${enc_(moduleId.replace(/\./g, '/'))}?data_id=${enc_(dataId)}` +
			(resourceType ? `&type=${enc_(resourceType)}` : '') +
			(authToken ? (dataId == 'auth_token' ? `&access_token=${enc_(authToken)}` : `&auth_token=${enc_(authToken)}`) : '');

		var [resp, isErr, errMessage] = await this.apiRequest(uri, payload, false, method);

		if (isErr) {
			throw new Error(errMessage);
		} else return resp;
	}

	async postData(moduleId, methodId, authToken, payload = {}) {
		const enc_ = encodeURIComponent;
		var uri = `app/post/${enc_(moduleId.replace(/\./g, '/'))}?method_id=${enc_(methodId)}` + (authToken ? `&auth_token=${enc_(authToken)}` : '');
		var [resp, isErr, errMessage] = await this.apiRequest(uri, payload, false, 'POST');
		if (isErr) {
			throw new Error(errMessage);
		} else return resp;
	}

	async postForm(moduleId, methodId, authToken, payload) {
		// payload must be FormData object

		if (typeof payload !== 'object' || payload.constructor !== FormData) throw Error('FormData payload required');
		const enc_ = encodeURIComponent;
		var uri = `app/postform/${enc_(moduleId.replace(/\./g, '/'))}?method_id=${enc_(methodId)}` + (authToken ? `&auth_token=${enc_(authToken)}` : '');
		var [resp, isErr, errMessage] = await this.apiRequest(uri, payload, false, 'POST');
		if (isErr) {
			throw new Error(errMessage);
		} else return resp;
	}

	async fetchTokenOfFrame(moduleId, menuModuleId, menuId, key) {
		const requiredAccessToken = menuId ? await this.getAccessToken(menuModuleId, 'menu', menuId, key) : await this.getAccessToken(menuModuleId, 'frame');

		const resFetch = await this.fetchResource(moduleId, '', 'auth_token', requiredAccessToken);
		if (resFetch.status == '000') return resFetch.token;
		else throw new Error(`Fetch frame ${moduleId} token error: ${resFetch.description || 'Unknown error'}\nCalled from menu ${menuModuleId}|${menuId}|${key}`);
	}

	async fetchFrame(moduleName, factoryParameters, globals, className, instanceName, title, treePath, componentProps = {}) {
		this.checkFrameAction();

		globals = { ...(globals || { DataBrowser, jsdset, dswidget }) };
		const { componentFactory } = await this.fetchModule(moduleName, globals);
		const componentClass = componentFactory(factoryParameters);

		this.frameAction.addClass(className, componentClass);
		this.frameAction.createPage(className, instanceName, title, true, treePath, { ...componentProps, appAction: this });
	}

	// menuInfo must contain the following keys: menuModuleId, menuId, key
	async fetchFrameWithToken(
		moduleId,
		factoryParameters,
		globals,
		className,
		instanceName,
		title,
		treePath,
		menuInfo = {
			menuModuleId: undefined,
			menuId: undefined,
			key: undefined,
		},
		componentProps = {},
	) {
		this.checkFrameAction();
		var { menuModuleId, menuId, key } = menuInfo;
		try {
			const _authToken = await this.fetchTokenOfFrame(moduleId, menuModuleId, menuId, key);
			this.setAccessToken(moduleId, 'frame', '', _authToken);
			return await this.fetchFrame(moduleId, factoryParameters, globals, className, instanceName, title, treePath, {
				...componentProps,
				_authToken,
			});
		} catch (err) {
			await this.frameAction.showMessage(err.message, 'Server error', {
				messageType: 'error',
			});
		}
	}

	async fetchTokenizedFrame(moduleId, title, menuInfo, componentProps = {}, factoryParameters = {}, globals = {}) {
		return await this.fetchFrameWithToken(moduleId, factoryParameters, globals, 'frm_' + moduleId, 'frm_' + moduleId, title, FRAME_CONTAINER_PATH, menuInfo, componentProps);
	}

	// menuInfo must contain the following keys: menuModuleId, menuId, key
	async fetchFrameComponentWithToken(moduleId, factoryParameters, globals, menuInfo = { menuModuleId: '', menuId: '', key: '' }) {
		this.checkFrameAction();
		var { menuModuleId, menuId, key } = menuInfo;
		var _authToken, componentClass;

		try {
			_authToken = await this.fetchTokenOfFrame(moduleId, menuModuleId, menuId, key);
			componentClass = await this.fetchAndExecModule(moduleId, globals, factoryParameters);
		} catch (err) {
			await this.frameAction.showMessage(err.message, 'Server error', {
				messageType: 'error',
			});
			_authToken = undefined;
			componentClass = undefined;
		}

		return { _authToken, componentClass };
	}

	async fetchMenu(_moduleId, menuId, moduleAuthToken, globals = {}) {
		const menuSource = await this.fetchResource(_moduleId, 'menu', menuId);
		const modMenu = await this.evalModule(menuSource, {
			DataBrowser,
			_moduleId,
			_resourceId: menuId,
			...globals,
		});
		const { menuStructure, menuHandlers } = modMenu.componentFactory();

		const menuComponent = this.renderMenu(menuStructure, menuHandlers, _moduleId, menuId);

		if (moduleAuthToken && moduleAuthToken != '') {
			const tokenResponse = await this.fetchResource(_moduleId, 'menu_tokens', menuId, moduleAuthToken);
			this.setAccessToken(_moduleId, 'menu', menuId, tokenResponse.tokens);
		}

		return menuComponent;
	}

	async browseData(apiInfo, arrFields, serverKeyField, selectedFields, apiParams, options) {
		// apiInfo keys and values:
		// moduleId: string
		// dataId: string
		// authToken: string (optional)

		// set arrFields to undefined or zero-length and serverKeyField to undefined or '' to use server-defined fields

		// options' keys and value types:
		// directSelection: boolean (in directSelection mode, single dataset will automatically be selected, and zero dataset will return null (not undefined !))
		// xx-not implemented usePopup: boolean (use pop up instead of modal)
		// xx-not implementedframeInstanceName: string (required when usePopup is set)
		// title: string (default: 'Select data', title of browse container. use null for title-less / compact container)
		// pendingLoad: boolean (do not load data in the beginning, wait for user to trigger)
		// size: string (default: 'fit', options are: "fit", "mini", "tiny", "small", "large", "fullscreen" for modal mode, or "fit" for popup mode. used to default width and height)
		// width: string (browser width (if size not specified), use css format such "100px" )
		// height: string (browser height (if size not specified), use css format such "100px" )
		// left: string (pop up left position (iif usePopup is set), use css format such as "100px" or "center" )
		// top: string (pop up top position (iif usePopup is set), use css format such as "100px" or "center" )
		// hideColumnTitles: boolean
		// minimalist: boolean (overrides and implies hideSortOptions, hideNavButtons and hideSearch. see below)
		// hideSortOptions: boolean (hide sort option combo box)
		// hideNavButtons: boolean (hide navigation (refresh, prev, next) buttons)
		// hideSearch: boolean (hide search)

		this.checkFrameAction();
		var { moduleId, dataId, authToken } = apiInfo;

		options = options || {};

		const rowClick = fields => {
			// if (!options.usePopup) {
			if (fields) {
				var { __sysFields, ...restFields } = fields;
				this.frameAction.closeModal(restFields);
			} else if (fields === null) {
				this.frameAction.closeModal(null);
			}
			// }
			// else {
			//   if (fields) {
			//     var {__sysFields, ...restFields} = fields
			//     this.frameAction.closePopUp(options.frameInstanceName, restFields)
			//   }
			//   else if (fields === null) {
			//     this.frameAction.closePopUp(options.frameInstanceName, null)
			//   }
			// }
		};

		const useServerFields = (!arrFields || (Array.isArray(arrFields) && arrFields.length == 0)) && !serverKeyField;

		try {
			var response = await this.fetchResource(
				moduleId,
				'scroll_query',
				dataId,
				authToken,
				Object.assign(
					{
						max_row: options.max_row || CONFIGS.MAX_ROW_BROWSE,
						get_client_fields: useServerFields ? 'true' : 'false',
					},
					apiParams || {},
				),
			);
		} catch (err) {
			await this.frameAction.showMessage(err.message, 'Fetch resource error', { messageType: 'error' });
			return null;
		}

		if (options.directSelection && response.rows.length == 0) {
			return null;
		}

		if (useServerFields) serverKeyField = response.key_field;

		const metadata = {
			table: {
				fields: useServerFields ? response.client_fields : [...arrFields],
				indexes: [useServerFields ? response.key_field : arrFields[0].name],
			},
		};
		const [dataProvider, dataStore] = jsdset.dsetMetaProviderEx(jsdset.dsetCreateContext(), metadata, { 'main:table': [] });
		dataStore.loadDataset('main', response.rows);

		if (options.directSelection && response.rows.length == 1) {
			return dataStore.datasets.main.rowFields[0];
		}

		const DataBrowserContainer = props => {
			return (
				<>
					<DataBrowser
						stdAppAction={this}
						dataProvider={dataProvider}
						dsetPath="main"
						moduleId={moduleId}
						dataId={dataId}
						reqAuthToken={Boolean(authToken)}
						authToken={authToken}
						apiParams={apiParams}
						keyField={serverKeyField}
						selFields={selectedFields}
						pendingLoad={options.pendingLoad}
						hideColumnTitles={options.hideColumnTitles}
						hideNavButtons={options.minimalist || options.hideNavButtons}
						hideSortOptions={options.minimalist || options.hideSortOptions}
						hideSearch={options.minimalist || options.hideSearch}
						onRowClick={rowClick}
						initialDataState={response}
					/>
					{/* {
            options.title === null && !options.usePopup ?
              <div>
                <br />
                <button onClick={() => this.frameAction.closeModal()}>Close</button>
              </div>
              :
              <></>
          } */}
				</>
			);
		};

		// if (!options.usePopup) {
		var modalResult = await this.frameAction.showModalAsync({
			contentClass: DataBrowserContainer,
			size: options.size || 'small',
			width: options.width,
			height: options.height,
			title: options.title !== null ? options.title || 'Select data' : null,
			clickOverlayClose: true,
		});
		return modalResult;
		// }
		// else {
		//   var popupResult = await this.frameAction.showPopUpAsync(
		//     options.frameInstanceName,
		//     'browse', DataBrowserContainer, {}, null, {vAlign: 'center', hAlign: 'center', fitWidth: true, fitHeight: true}
		//   )
		//   return popupResult
		//   //
		// }
	}

	async getState() {
		var ref = {};
		await yieldEventLoop();
		this.disp({ type: 'getState', ref });
		return ref.state;
	}

	setGlobal(key, value) {
		this.disp({ type: 'setGlobal', key, value });
	}

	incGlobal(key, incValue) {
		this.disp({ type: 'incGlobal', key, incValue });
	}

	renderMenu(menuItems, handlers = {}, moduleId, menuId, verticalHorizontal = 'vertical', visualProps = {}) {
		// HOC returning visual menu component
		// returns a function component for menu

		// menuItems is array of menuItem object
		// every menuItem object has the following properties:
		// - title (use '-' for divider)
		// - key
		// - items (for sub menu)
		// handlers is object having menu keys as property keys and function to handle click event
		// the function will receive 4 parameters: (appAction, menuKey, eventData, props)

		visualProps = visualProps || {};
		const appAction = this;

		function _scanEmptyKeys(items) {
			if (!Array.isArray(items)) throw new Error('menuItems parameter or its submenu must be array');
			return items.map(item =>
				item.items
					? {
							...item,
							key: item.key || MenuAutoKeyGenerator.getNext(),
							items: _scanEmptyKeys(item.items),
					  }
					: {
							...item,
							key: item.key || MenuAutoKeyGenerator.getNext(),
					  },
			);
		}

		function _getFlatItems(items) {
			return items.reduce(
				(prev, item) =>
					item.items
						? prev.concat(_getFlatItems(item.items))
						: item.key
						? prev.concat({
								key: item.key,
								title: item.title,
								std_handler: item.std_handler,
								handler: item.handler,
						  })
						: prev,
				[],
			);
		}

		const allMenuItems = Object.fromEntries(_getFlatItems(menuItems).map(item => [item.key, item]));
		const menuItemsWithKeys = _scanEmptyKeys(menuItems);

		// console.log(allMenuItems)

		function _renderMenuItems(items, appState, props = null) {
			if (!Array.isArray(items)) throw new Error('menuItems parameter or its submenu must be array');

			const _cmsgroup_handler = {
				Reaktivasi: 'Active',
				Suspend: 'Suspend',
				Deaktivasi: 'Deactive',
			};
			return items.map(menuItem =>
				menuItem.title === '-' ? (
					<Divider key={menuItem.key} />
				) : menuItem.items ? (
					<SubMenu title={menuItem.title} key={menuItem.key}>
						{_renderMenuItems(menuItem.items, appState)}
					</SubMenu>
				) : !menuItem.title || (moduleId == 'settings.cMSUserGroupList' && _cmsgroup_handler[menuItem.title] == props.currentRow.cms_group_status) ? (
					false
				) : (
					<MenuItem key={menuItem.key}>{menuItem.globalItem ? String(menuItem.title) + ` (${String(appState.globals[menuItem.globalItem])})` : menuItem.title}</MenuItem>
				),
			);
		}

		function MenuComponent(props) {
			const [appState] = useStdAppContext();

			const handleClick = e => {
				const menuKey = e.key;
				const item = allMenuItems[menuKey];
				if (item && item.std_handler) {
					if (typeof props.hidePopup === 'function') {
						props.hidePopup(null);
					}

					const handlerInfo = item.handler;
					if (item.std_handler === 'frame') {
						if (handlerInfo.module_id)
							appAction
								.fetchTokenizedFrame(
									handlerInfo.module_id,
									handlerInfo.frame_title || '',
									{
										menuModuleId: moduleId,
										menuId,
										key: menuKey,
									},
									handlerInfo.props || {},
								)
								.then(() => {});
					} else if (item.std_handler == 'context_modal') {
						if (handlerInfo.module_id) {
							const contextSetting = handlerInfo.props_context || {};
							const mapping = contextSetting.map || [];
							const contextRow = props.currentRow;
							const contextProps = contextRow ? Object.fromEntries(mapping.map(k => [k, contextRow[k]])) : {};
							Object.assign(contextProps, contextSetting.consts || {});
							if (handlerInfo.need_context && !contextRow) {
								(async () => {
									await appAction.frameAction.showMessage(handlerInfo.need_context_message || 'No data is selected');
								})().then(() => {});
							} else {
								(async () => {
									const { _authToken, componentClass } = await appAction.fetchFrameComponentWithToken(
										handlerInfo.module_id,
										{},
										{},
										{
											menuModuleId: moduleId,
											menuId,
											key: menuKey,
										},
									);
									await appAction.frameAction.showModalAsync({
										contentClass: componentClass,
										contentProps: {
											_authToken,
											...contextProps,
										},
										...(handlerInfo.modal || {}),
									});
								})().then(() => {});
							}
						}
					}
				} else {
					const handler = handlers[menuKey];
					if (handler && typeof handler === 'function') {
						if (typeof props.hidePopup === 'function') {
							props.hidePopup(null);
						}
						if (handler.constructor.name === 'AsyncFunction') {
							handler(appAction, menuKey, e, props).then(() => {});
						} else {
							handler(appAction, menuKey, e, props);
						}
					}
				}
			};

			// const refAppAction = React.useRef(null)
			return (
				<Menu mode={verticalHorizontal} onClick={handleClick} {...visualProps}>
					{_renderMenuItems(menuItemsWithKeys, appState, props)}
				</Menu>
			);

			// return <Menu
			//   mode="vertical"
			//   onClick={() => {console.log('test')}}
			// >
			//   <MenuItem key="menu1">First menu</MenuItem>
			//   <MenuItem key="menu2">Second menu</MenuItem>
			//   <MenuItem key="menu3">Third menu</MenuItem>
			// </Menu>
		}

		return MenuComponent;
	}

	connect(components, otherValues = {}) {
		const self = this;
		function connector(Component) {
			function ConnectedComponent(props) {
				const augProps = Object.assign({ appAction: self }, props, otherValues);
				return <Component {...augProps}>{props.children}</Component>;
			}

			return ConnectedComponent;
		}
		return Object.fromEntries(Object.entries(components).map(([key, comp]) => [key, connector(comp)]));
	}
}

const useStdAppContext = (proxyParams = undefined) => {
	const [frameState, frameAction] = useAppFrameContext();
	const [stdAppState, stdAppAction] = useDispatchContext(StdAppContext, (proxyParams = undefined));
	stdAppAction.setFrameAction(frameAction);
	return [stdAppState, stdAppAction];
};

const frameShow_Handler = instance => {
	// console.log(
	// 	`Frame shown. Instance name ${instance.instanceName}, class name ${instance.className}`,
	// );
};

const frameClose_Handler = instance => {
	// console.log(
	// 	`Frame closed. Instance name ${instance.instanceName}, class name ${instance.className}`,
	// );
};

const frameHide_Handler = instance => {
	// console.log(
	// 	`Frame hidden. Instance name ${instance.instanceName}, class name ${instance.className}`,
	// );
};

function createStdAppProvider(reducers = StdAppReducers, initialState = StdAppState, actionClass = StdAppAction_Base) {
	const ProviderComponent = ContextProviderHook(StdAppContext, reducers, initialState, actionClass);
	function StdAppProviderComponent(props) {
		const [, frameAction] = useAppFrameContext();

		useEffect(
			() => {
				frameAction.setEventHandlers({
					onShow: frameShow_Handler,
					onClose: frameClose_Handler,
					onHide: frameHide_Handler,
				});
			},
			[], // do not forget this [], unless it will be infinite recursion
		);

		return <ProviderComponent>{props.children}</ProviderComponent>;
	}

	return StdAppProviderComponent;
}

const StdAppProvider = createStdAppProvider();

const ACTION_METHOD_NAMES = [
	'checkFrameAction',
	'apiRequest',
	'apiMultiRequest',
	'yieldEventLoop',
	'resetUI',
	'login',
	'logout',
	'fetchModule',
	'evalModule',
	'fetchAndExecModule',
	'setAccessToken',
	'getAccessToken',
	'fetchResource',
	'postData',
	'postForm',
	'fetchTokenOfFrame',
	'fetchFrame',
	'fetchFrameWithToken',
	'fetchFrameComponentWithToken',
	'fetchMenu',
	'browseData',
	'getState',
	'setGlobal',
	'incGlobal',
	'renderMenu',
	'connect',
	'loginKeycloak',
];

class StdAppProxy extends React.PureComponent {
	// implicit properties (added by renderActionObject)
	// disp : reference to dispatch
	// frameAction : reference to AppFrameAction object

	constructor(props) {
		super(props);
		this.baseAction = new StdAppAction_Base(undefined); // the disp property of StdAppAction_Base will be set by ActionComponent
		this.ActionComponent = createActionComponent(StdAppContext, this);
		ACTION_METHOD_NAMES.forEach(v => {
			this[v] = this.baseAction[v];
		});
	}

	render() {
		return (
			<AppFrameContext.Consumer>
				{({ actionObject }) => {
					this.frameAction = actionObject;
					return <this.ActionComponent />;
				}}
			</AppFrameContext.Consumer>
		);
	}
}

const StdAppAction = StdAppProxy; // the use of "Action" is now deprecated for objects with current state (and hence dynamic), the term "Proxy" is more preferable

const StdAppInterfaces = {
	// standard "templates" for visual components to connect
	loginAndRequest: ContextConnector(StdAppContext, (state, props) => ({
		isLogin: state.isLogin,
		loginError: state.loginError,
		loginErrMessage: state.loginErrMessage,
		requesting: state.requesting,
		requestError: state.requestError,
		requestErrMessage: state.requestErrMessage,
	})),

	userInfo: ContextConnector(StdAppContext, (state, props) => ({
		userID: state.userID,
		userName: state.userName,
		corporate: state.corporate,
	})),
};

export {
	// initVisualLib is exported to allow replacement of visual-components inside global app state.
	// for initial version, this can be used to customize Menu, MenuItem, SubMenu and Divider components
	initVisualLib,
	// call init GlobalModules to set default identifiers to be injected to dynamic modules
	initGlobalModules,
	// use setupConfigs to change several global constants (see CONSTANTS on the top)
	setupConfigs,
	StdAppProvider,
	StdAppContext,
	StdAppAction,
	StdAppProxy,
	StdAppInterfaces,
	useStdAppContext,
	FRAME_CONTAINER_BRANCH_NAME,
	FRAME_CONTAINER_PATH,
	// StdAppReducers, StdAppState, StdAppAction_Base, createStdAppProvider are exported to allow custom global app state management module
	StdAppReducers,
	StdAppState,
	StdAppAction_Base,
	createStdAppProvider,
	// for global static components
	setStaticComponents,
};
