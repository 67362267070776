/*
  DAFReact framework module - Application form (hook)
  appname: corporateAccount
  filename: corporateAccountEdit.js
  moduleid: corporateAccountEdit
  author: IK
*/

import React from 'react'; //--DAFReact: development
import { FaSearch } from 'react-icons/fa';

// 'use strict'; //--DAFReact: deploy

export function ModuleDefinition() {
	//--DAFReact: development

	// (function () { //--DAFReact: deploy

	async function getImports(React, globals) {
		// checking required libraries, may also include asynchronously loading other remote module, using appAction.loadModule
		const {
			_moduleId,
			StdAppAction,
			appAction,
			jsdset,
			dswidget,
			ndswidget,
			frameAction,
			staticComponents: {
				FormLabel,
				Button,
				AlertComponent,
				RadioGroup,
				Input,
			},
		} = globals;
		if (!_moduleId || !StdAppAction || !appAction || !jsdset || !dswidget) {
			throw new Error(
				'One of required components (_moduleId, StdAppAction, appAction, jsdset, dswidget) not found in globals',
			);
		}

		/* other required modules are asynchronously loaded here */
		const { metadata, initialData, serverDataMapping, editUIData } =
			await appAction.fetchAndExecModule(
				'corporate.corporateAccountMetadata',
			);

		const { PanelDataDisplay, FieldDataDisplay, PanelButton } = dswidget;
		const { FieldDataInput } = ndswidget;

		function componentFactory(params) {
			function AppForm(props) {
				const dataContext = React.useMemo(
					() => jsdset.dsetCreateContext(),
					[],
				);
				const DSetProvider = React.useMemo(
					() =>
						jsdset.dsetMetaProvider(
							dataContext,
							metadata,
							initialData,
							editUIData,
						),
					[],
				);
				return (
					<DSetProvider>
						<AppFormUI dataContext={dataContext} {...props} />
						{/* any other props will be passed down */}
					</DSetProvider>
				);
			}

			function AppFormUI(props) {
				const [state, setState] = React.useState({
					isErr: false,
					errMessage: '',
					isEditing: false,
				});
				const ref_account_no = React.useRef(null);

				// bind controls to _moduleId and _authToken
				const vComps = React.useMemo(
					() =>
						appAction.connect(
							{
								PanelDataDisplay,
								FieldDataDisplay,
								PanelButton,
								FieldDataInput,
							},
							{ _moduleId, _getToken: () => props._authToken },
						),
					[],
				);

				// bind controls to datasets
				const [mainComps] = React.useMemo(
					() => [
						jsdset.connect(
							{ context: props.dataContext, dsetPath: 'main' },
							vComps,
						),
					],
					[props.dataContext, vComps],
				);

				// obtain action objects from data context
				const [, dsMainAction, dsMainProxy] = jsdset.useDSetContext(
					props.dataContext,
					'main',
				);

				// load data function
				const loadData = React.useCallback(async () => {
					setState(state => ({
						...state,
						isErr: false,
						errMessage: '',
					}));

					try {
						const response = await appAction.fetchResource(
							_moduleId,
							'single_data',
							'dataSO',
							props._authToken,
							{ corporate_code: props.corporate_code },
							true,
						);
						dsMainAction.loadStore(
							response,
							'std',
							serverDataMapping,
							true,
						);
						dsMainAction.recalcFormulas();
					} catch (err) {
						setState(prevState => ({
							...prevState,
							isErr: true,
							errMessage: err.message,
						}));
						return;
					}
				}, [dsMainAction, props._authToken, props.corporate_code]);

				// set event on component mounting
				React.useEffect(() => {
					(async function () {
						if (props.uiMode == 'edit') {
							await loadData();
						} else {
							dsMainAction.addRow({
								is_member: 'F',
								is_transaction: 'T',
								corporate_code: props.corporate_code,
								issuer_id: props.issuer_id,
							});
						}
					})();
				}, [dsMainAction, loadData, props.uiMode]);

				var currentRow = dsMainProxy.unloadStore(serverDataMapping, {});

				// UI event handlers
				const switchEditClick = () => {
					//
					setState(prevState => ({
						...prevState,
						isEditing: !prevState.isEditing,
					}));
				};

				const saveDataClick = async () => {
					try {
						const { fieldValidStates, fieldValidErrors } =
							dsMainProxy;
						const isInValid = Object.entries(
							fieldValidStates,
						).filter(([i, v]) => {
							return !v;
						});

						if (isInValid.length > 0)
							throw new Error(fieldValidErrors[isInValid[0][0]]);

						var dataUnload = dsMainProxy.unloadStore(
							serverDataMapping,
							{
								includeLoadedRows: false,
								includeDeletedRows: true,
							},
						);

						if (dataUnload?.data?.length > 0) {
							// console.log(dataUnload?.data[0]);
							if (
								(dataUnload?.data[0].account_name !== '') &
								(dataUnload?.data[0].account_no !== '')
							) {
								await appAction.postData(
									_moduleId,
									'saveData',
									props._authToken,
									dataUnload,
								);
								appAction.frameAction.closeModal();
								AlertHandler(
									'Data berhasil disimpan',
									'success',
									'Silahkan Refresh data',
								);
							} else {
								throw new Error(
									'Silahkan Lengkapi Data Rekening',
								);
							}
						} else {
							throw new Error('Silahkan Lengkapi data');
						}
					} catch (err) {
						AlertHandler(err.message, 'alert');
					}
				};

				// load data rekening
				const loadDataRek = React.useCallback(async () => {
					// setState(state => ({
					// 	...state,
					// 	isErr: false,
					// 	errMessage: '',
					// }));

					try {
						const response = await appAction.fetchResource(
							_moduleId,
							'method',
							'getAccount',
							props._authToken,
							{
								account_no: ref_account_no.current.value,
								is_member: currentRow.data[0]?.is_member,
								primary_cif_no: props.primary_cif_no,
							},
							true,
						);

						if (response) {
							if (response.err_info) {
								throw new Error(response.err_info);
							}

							let {
								nama: account_name,
								currency_code = 'IDR',
								service_type = 'B',
							} = response;

							dsMainAction.setFields({
								account_name,
								currency_code,
								service_type,
							});
						} else {
							AlertHandler('No data Rekening', 'warning');
						}
					} catch (err) {
						// setState(prevState => ({
						// 	...prevState,
						// 	isErr: true,
						// 	errMessage: err.message,
						// }));
						AlertHandler(err.message, 'warning');

						return;
					}
				}, [
					dsMainAction,
					props._authToken,
					currentRow.data[0]?.is_member,
				]);

				const AlertHandler = async (msg, type, body) => {
					await frameAction.showModal({
						contentClass: props => (
							<AlertComponent
								{...props}
								title={msg}
								type={type}
								body={body}
							/>
						),
						size: 'small',
					});
				};

				const ChangeMemberHandler = e => {
					dsMainAction.setFields({
						account_name: '',
						account_no: '',
						currency_code: '',
						service_type: '',
						is_member: e.target.value,
						is_transaction: 'T',
						corporate_code: props.corporate_code,
						issuer_id: props.issuer_id,
					});
					ref_account_no.current.value = '';
					// setState(state => ({
					// 	...state,
					// 	isErr: false,
					// 	errMessage: '',
					// }));
				};

				const handleChangeInput = text => {
					dsMainAction.setFields({
						account_no: text,
						account_name: '',
						currency_code: '',
						service_type: '',
					});
					// setState(state => ({
					// 	...state,
					// 	isErr: false,
					// 	errMessage: '',
					// }));
				};

				// render
				return (
					<div>
						<FormLabel label="Kode Korporat">
							<mainComps.FieldDataInput
								elProps={{ disabled: true }}
								fieldName="corporate_code"
							/>
						</FormLabel>
						<FormLabel label="Tipe Nasabah/Member" style={{}}>
							<RadioGroup
								data={Object.entries({
									F: 'Nasabah',
									T: 'Member',
								})}
								name={'is_member'}
								currentValue={dsMainProxy.fields.is_member}
								initialValue="F"
								type={'icon'}
								variant="block"
								withborder={true}
								onChange={e => ChangeMemberHandler(e)}
							/>
						</FormLabel>
						<FormLabel label="Nomor Rekening">
							<div
								style={{
									display: 'flex',
									width: '100%',
									justifyContent: 'space-between',
								}}
							>
								<Input
									reference={ref_account_no}
									onChange={e => {
										handleChangeInput(e.target.value);
									}}
								/>

								{/* <mainComps.FieldDataInput
									fieldName="account_no"
									elProps={{ reference: ref_account_no }}
								/> */}
								<Button
									onClick={() => {
										loadDataRek();
									}}
									style={{
										display: 'flex',
										alignItems: 'center',
									}}
								>
									Cari
								</Button>
							</div>
						</FormLabel>
						{/* <FormLabel label="Issuer ID">
							<mainComps.FieldDataInput
								elProps={{ disabled: true }}
								fieldName="issuer_id"
							/>
						</FormLabel> */}
						<FormLabel label="Nama Rekening">
							<mainComps.FieldDataInput
								elProps={{ disabled: true }}
								fieldName="account_name"
							/>
						</FormLabel>
						{/* <FormLabel label="Nomor Account">
							<mainComps.FieldDataInput fieldName="issuer_account_no" />
						</FormLabel> */}
						{/* <FormLabel label="Kode Produk">
							<mainComps.FieldDataInput fieldName="issuer_product_code" />
						</FormLabel> */}
						<div
							style={{
								display: 'flex',
								justifyContent: 'space-between',
							}}
						>
							<FormLabel
								label="Mata Uang"
								style={{
									flexGrow: 1,
									marginRight: 5,
								}}
							>
								<mainComps.FieldDataInput
									elProps={{ disabled: true }}
									fieldName="currency_code"
								/>
							</FormLabel>
							<FormLabel
								label="Tipe Rekening"
								style={{
									flexGrow: 1,
								}}
							>
								<mainComps.FieldDataInput
									elProps={{ disabled: true }}
									fieldName="service_type"
								/>
							</FormLabel>
						</div>
						<FormLabel label="Transaksi Diizinkan">
							<mainComps.FieldDataInput
								// elProps={{ readOnly: true }}
								fieldName="is_transaction"
							/>
						</FormLabel>
						<br />
						<div
							style={{
								display: 'flex',
								justifyContent: 'flex-end',
							}}
						>
							<Button
								onClick={() =>
									appAction.frameAction.closeModal()
								}
								type="bordered"
								style={{
									marginRight: 10,
								}}
							>
								Batalkan
							</Button>
							<Button onClick={saveDataClick}>
								Simpan Rekening Korporat
							</Button>
						</div>
						<br />
						<div
							style={{ display: state.isErr ? 'block' : 'none' }}
						>
							{state.errMessage}
						</div>
						<br />
					</div>
				);
			}

			// return value may be different depending on params
			return React.memo(AppForm);
		}

		return { componentFactory };
	}

	async function initModuleF(aReact, globals) {
		return await getImports(aReact, globals);
	}

	return initModuleF;
	// })()  //--DAFReact: deploy
} //--DAFReact: development
