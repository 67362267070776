/*
  DAFReact framework module - Application form (class)
  appname: corporateAccount
  filename: corporateAccountView.js
  moduleid: corporateAccountView
  author: IK
*/

import React from 'react'; //--DAFReact: development

// 'use strict'; //--DAFReact: deploy

export function ModuleDefinition() {
	//--DAFReact: development

	// (function () { //--DAFReact: deploy

	async function getImports(React, globals) {
		// checking required libraries, may also include asynchronously loading other remote module, using appAction.loadModule
		const {
			_moduleId,
			StdAppAction,
			AppFrameAction,
			appAction,
			jsdset,
			ndswidget,
			dswidget,
			staticComponents: {
				Button,
				FormLabel,
				RadioIcon,
				InputGroupButton,
			},
		} = globals;
		if (
			!StdAppAction ||
			!AppFrameAction ||
			!appAction ||
			!jsdset ||
			!dswidget
		) {
			throw new Error(
				'One of required components (StdAppAction, AppFrameAction, appAction, jsdset, dswidget) not found in globals',
			);
		}

		/* other required modules are asynchronously loaded here */
		const { metadata, initialData, serverDataMapping, editUIData } =
			await appAction.fetchAndExecModule(
				'corporate.corporateAccountMetadata',
			);

		const {
			PanelDataDisplay,
			FieldDataDisplay,
			PanelButton,
			GridDataDisplay,
		} = dswidget;
		const { FieldDataInput } = ndswidget;

		function componentFactory(params) {
			class CorporateAccountView extends React.PureComponent {
				constructor(props) {
					super(props);
					this.dsMainAction = null;
					this.appAction = appAction;
					this.dataContext = jsdset.dsetCreateContext();
					this.DSetProvider = jsdset.dsetMetaProvider(
						this.dataContext,
						metadata,
						initialData,
						editUIData,
					);
					this.state = { isErr: false, errMessage: '' };
				}

				async loadData(e) {
					var prevState = this.state;
					this.setState({
						...prevState,
						isErr: false,
						errMessage: '',
					});

					try {
						var response = await this.appAction.fetchResource(
							_moduleId,
							'single_data',
							'dataSO',
							this.props._authToken,
							{ corporate_code: params.corporate_code },
						);
						console.log(response);

						this.dsMainAction.loadStore(
							response,
							'std',
							serverDataMapping,
							true,
						);
					} catch (err) {
						this.setState({
							...prevState,
							isErr: true,
							errMessage: err.message,
						});
						return;
					}
				}

				async componentDidMount() {
					/* load menu components or data from server here */
					await this.loadData();
				}

				render() {
					var vcomps = {
						PanelDataDisplay,
						FieldDataDisplay,
						PanelButton,
						FieldDataInput,
					}; // object of unconnected visual components

					var mainComps = jsdset.connectComponents(
						{ context: this.dataContext, dsetPath: 'main' },
						vcomps,
					);
					const DSetAction = jsdset.DSetAction;
					const DSetProvider = this.DSetProvider;

					return (
						<DSetProvider>
							<DSetAction
								ref={val => {
									this.dsMainAction = val;
								}}
								context={this.dataContext}
								dsetPath="main"
							/>
							{/* <mainComps.PanelDataDisplay delFields={[]}/>      */}
							<div>
								{/* <h5 style={{
                  color: '#b7282d'
                }}>Data Rekening korporat</h5>
                <br /> */}
								<div
									style={{
										display: 'flex',
										width: '-webkit-fill-available',
									}}
								>
									<div
										style={{
											marginRight: 10,
											width: '-webkit-fill-available',
										}}
									>
										<FormLabel label="Kode Korporat :">
											<b>
												<mainComps.FieldDataDisplay fieldName="corporate_code" />
											</b>
										</FormLabel>
										<FormLabel label="Nomor Rekening :">
											<b>
												<mainComps.FieldDataDisplay fieldName="account_no" />
											</b>
										</FormLabel>
										{/* <FormLabel label="Issuer ID :">
											<b>
												<mainComps.FieldDataDisplay fieldName="issuer_id" />
											</b>
										</FormLabel> */}
										<FormLabel label="Mata Uang :">
											<b>
												<mainComps.FieldDataDisplay fieldName="currency_code" />
											</b>
										</FormLabel>
										{/* <FormLabel label="Nomor Account Issuer :">
											<b>
												<mainComps.FieldDataDisplay fieldName="issuer_account_no" />
											</b>
										</FormLabel> */}
									</div>
									<div
										style={{
											// marginRight: 10,
											width: '-webkit-fill-available',
										}}
									>
										<FormLabel label="Nama Rekening :">
											<b>
												<mainComps.FieldDataDisplay fieldName="account_name" />
											</b>
										</FormLabel>
										<FormLabel label="Tipe Rekening :">
											<b>
												<mainComps.FieldDataDisplay fieldName="service_type" />
											</b>
										</FormLabel>

										<FormLabel label="Kode Produk :">
											<b>
												<mainComps.FieldDataDisplay fieldName="issuer_product_code" />
											</b>
										</FormLabel>
									</div>
								</div>

								<FormLabel label="Izin Rekening Korporat">
									<b>
										<mainComps.FieldDataDisplay
											// elProps={{ readOnly: true }}
											fieldName="is_transaction"
										/>
									</b>
								</FormLabel>
								<br />

								<br />
								<div
									style={{
										display: this.state.isErr
											? 'block'
											: 'none',
									}}
								>
									{this.state.errMessage}
								</div>
							</div>
						</DSetProvider>
					);
				}
			}

			// return value may be different depending on params
			return CorporateAccountView;
		}

		return { componentFactory };
	}

	async function initModuleF(aReact, globals) {
		return await getImports(aReact, globals);
	}

	return initModuleF;
	// })()  //--DAFReact: deploy
} //--DAFReact: development
