/*
  DAFReact framework module - Application form (hook)
  appname: loginLog
  filename: loginLogCList.js
  moduleid: loginLogCList
  author: IK
*/

// 'use strict';

export function ModuleDefinition() {
	//--DAFReact: development

	// (function () { //--DAFReact: deploy

	async function getImports(React, globals) {
		const {
			_moduleId,
			StdAppAction,
			AppFrameAction,
			appAction,
			jsdset,
			dswidget,
			DataBrowser,
			NDataBrowser,
			staticComponents: {
				FormLabel,
				PageTitle,
				SearchBox,
				Filter,
				FilterActionButtons,
				ReactIconFa,
				ReactIconSi,
				Dropdown,
				DatePicker,
			},
		} = globals;
		if (
			!_moduleId ||
			!StdAppAction ||
			!AppFrameAction ||
			!appAction ||
			!jsdset ||
			!dswidget ||
			!DataBrowser
		) {
			throw new Error(
				'One of required components (_moduleId, StdAppAction, AppFrameAction, appAction, jsdset, dswidget, _moduleId) not found in globals',
			);
		}

		const { FaRetweet } = ReactIconFa;
		const { SiMicrosoftexcel } = ReactIconSi;

		const { editUIData } = await appAction.fetchAndExecModule(
			'log.loginLogMetadata',
		);

		function componentFactory(params) {
			function AppForm(props) {
				const [state, setState] = React.useState({
					popupComponent: undefined,
				});

				const initialFilter = {
					start_date: new Date(),
					end_date: new Date(),
					corporate_code: '',
				};
				const [filter, setFilter] = React.useState(initialFilter);

				const dsetProvider = React.useMemo(
					() => jsdset.dsetEmptyProvider(),
					[],
				);

				React.useEffect(() => {
					(async function () {
						const popupComponent = await appAction.fetchMenu(
							_moduleId,
							'popup_menu',
							props._authToken,
						);
						setState({ popupComponent });
					})();
				}, [props._authToken]);

				const ActionButtons = React.useCallback(
					({ clearFilter, params }) => {
						return [
							{
								type: 'bordered',
								name: 'Import Excel',
								onClick: async () => {
									try {
										await appAction.postData(
											_moduleId,
											'importList',
											props._authToken,
											{ ...params, ...filter },
										);
									} catch (error) {
										alert(error.message);
										console.log(error);
									}
								},
								render: () => <SiMicrosoftexcel />,
							},
							{
								type: 'bordered',
								name: 'Reload',
								onClick: () => {
									setFilter(initialFilter);
									clearFilter();
								},
								render: () => <FaRetweet />,
							},
						];
					},
					[],
				);

				const handleFilter = React.useCallback(
					e => {
						setFilter(prev => ({
							...prev,
							start_date: e.start_date || prev.start_date,
							end_date: e.end_date || prev.end_date,
						}));
					},
					[filter],
				);
				console.log(state.corporate_code);
				return (
					<>
						{/* PageTitle */}
						<PageTitle
							title="Log Aktivitas User"
							subtitle="Monitoring"
							style={{ marginBottom: `1.5rem` }}
						/>

						<NDataBrowser
							stdAppAction={appAction}
							moduleId={_moduleId}
							dataId="listSO"
							dataProvider={dsetProvider}
							authToken={props._authToken}
							dsetPath="main"
							params={filter}
							uiData={editUIData}
							filterComponent={({
								sortFields,
								clearFilter,
								changeParams,
								params,
							}) => {
								return (
									<Filter>
										<FormLabel
											label="Tanggal Transaksi"
											style={{
												maxWidth: '250px',
												marginRight: '1rem',
											}}
										>
											<div
												style={{
													display: 'flex',
												}}
											>
												<DatePicker
													value={filter.start_date}
													onSelect={e => {
														handleFilter({
															start_date: e,
														});
													}}
												/>
												<div style={{ padding: 10 }}>
													s.d.
												</div>
												<DatePicker
													value={filter.end_date}
													onSelect={e => {
														handleFilter({
															end_date: e,
														});
													}}
												/>
											</div>
										</FormLabel>
										<FormLabel
											label="Urutkan"
											style={{ maxWidth: '200px' }}
										>
											<Dropdown
												onChange={e =>
													changeParams({
														sortField:
															e.target.value,
													})
												}
												item={sortFields}
												width="100%"
												value={params.sortField}
											/>
										</FormLabel>
										<FormLabel
											label="Kata Kunci"
											style={{
												maxWidth: `300px`,
												width: `100%`,
												marginRight: `1.5rem`,
											}}
										>
											<SearchBox
												style={{
													marginLeft: 5,
													width: '100%',
													maxWidth: '250px',
												}}
												handleSearch={src => {
													changeParams({
														start_date:
															filter.start_date,
														end_date:
															filter.end_date,
														src: src,
													});
												}}
												srcText={params.src}
											/>
										</FormLabel>

										{/* actionbox */}
										<FormLabel label="&nbsp;">
											<div
												style={{
													height: `100%`,
													backgroundColor: `#e6e6e6`,
													width: `1px`,
													margin: `0 1rem`,
												}}
											></div>
											<FormLabel label="&nbsp;">
												<FilterActionButtons
													buttons={ActionButtons({
														clearFilter,
														params,
													})}
												/>
											</FormLabel>
										</FormLabel>
									</Filter>
								);
							}}
							popupComponent={state.popupComponent}
						/>
					</>
				);
			}
			return React.memo(AppForm);
		}
		return { componentFactory };
	}

	return async (aReact, globals) => {
		return await getImports(aReact, globals);
	};

	// })()  //--DAFReact: deploy
} //--DAFReact: development
