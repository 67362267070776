/*
  DAFReact framework module - Application form (class)
  appname: digitalTransactionCorp
  filename: digitalTransactionCorpView.js
  moduleid: digitalTransactionCorpView
  author: IK
*/

import React from 'react'; //--DAFReact: development

// 'use strict'; //--DAFReact: deploy

export function ModuleDefinition () { //--DAFReact: development

// (function () { //--DAFReact: deploy

  async function getImports (React, globals) {

    // checking required libraries, may also include asynchronously loading other remote module, using appAction.loadModule
    const { _moduleId, StdAppAction, AppFrameAction, appAction, jsdset, dswidget,ndswidget, staticComponents:{FormLabel} } = globals
    if (!StdAppAction || !AppFrameAction || !appAction || !jsdset || !dswidget) {
      throw new Error('One of required components (StdAppAction, AppFrameAction, appAction, jsdset, dswidget) not found in globals')
    }

    /* other required modules are asynchronously loaded here */
    const { metadata, initialData, serverDataMapping,editUIData } = await appAction.fetchAndExecModule('monitoring_corp.digitalTransactionCorpMetadata')

    const {PanelDataDisplay, PanelButton} = dswidget
    const {FieldDataDisplay, FieldDataInput} = ndswidget
    
    function componentFactory (params) {

      class DigitalTransactionCorpView extends React.PureComponent {

        constructor (props) {
          super(props)
          this.dsMainAction = null
          this.appAction = appAction
          this.dataContext = jsdset.dsetCreateContext();
          this.DSetProvider = jsdset.dsetMetaProvider(this.dataContext, metadata, initialData,editUIData);
          this.state = {isErr: false, errMessage: ''}
        }
              
        async loadData (e) {
          var prevState = this.state
          this.setState({...prevState, isErr: false, errMessage: ''})
          console.log('Loading DigitalTransactionCorp data ' + params.id_transaction)
      
          try {
            var response = await this.appAction.fetchResource(_moduleId, 'single_data', 'dataSO', this.props._authToken, {id_transaction: params.id_transaction})
            this.dsMainAction.loadStore(response, 'std', serverDataMapping, true)
          }
          catch(err) {
            this.setState({...prevState, isErr: true, errMessage: err.message})
            return
          }    
        }

        async componentDidMount () {
          /* load menu components or data from server here */
          await this.loadData()
        }

        render () {
          var vcomps = {PanelDataDisplay, FieldDataDisplay, PanelButton, FieldDataInput} // object of unconnected visual components
      
          var mainComps = jsdset.connectComponents({context: this.dataContext, dsetPath: 'main'}, vcomps)
          const DSetAction = jsdset.DSetAction
          const DSetProvider = this.DSetProvider
        
          return (
            <DSetProvider>
                <DSetAction ref={(val) => {this.dsMainAction = val}} context={this.dataContext} dsetPath="main" />
                <div>
                  <div style={{display:'flex'}}>
                    <FormLabel label="Tanggal Input" style={{width:'100%'}}>
                      <b><mainComps.FieldDataDisplay fieldName="tx_date" /></b>   
                    </FormLabel>
                    <FormLabel label="Jenis"  style={{width:'100%'}}>
                      <b><mainComps.FieldDataDisplay fieldName="tx_type" /></b>   
                    </FormLabel>
                  </div>

                  <div style={{display:'flex'}} >
                    <FormLabel label="Rek. Sumber" style={{width:'100%'}}>
                      <b><mainComps.FieldDataDisplay fieldName="account_no" /></b>   
                    </FormLabel>
                    <FormLabel label="Nama Rek. Sumber" style={{width:'100%'}}>
                      <b><mainComps.FieldDataDisplay fieldName="account_name" /></b>  
                    </FormLabel>
                  </div>

                  <div style={{display:'flex'}}>
                    <FormLabel label="Rek. Tujuan" style={{width:'100%'}}>
                      <b><mainComps.FieldDataDisplay fieldName="credit_account_no" /></b>  
                    </FormLabel>
                    <FormLabel label="Nama Rek. Tujuan" style={{width:'100%'}}>
                      <b><mainComps.FieldDataDisplay fieldName="credit_account_name" /></b>  
                    </FormLabel>
                  </div>

                  <div style={{display:'flex'}}>
                    <FormLabel label="Nama Produk" style={{width:'100%'}}>
                      <b><mainComps.FieldDataDisplay fieldName="product_desc" /></b>  
                    </FormLabel>
                    <FormLabel label="Nama Transaksi" style={{width:'100%'}}>
                      <b><mainComps.FieldDataDisplay fieldName="tx_desc" /></b>  
                    </FormLabel>
                  </div>

                  <div style={{display:'flex'}}>
                    <FormLabel label="Nominal" style={{width:'100%'}}>
                      <b><mainComps.FieldDataDisplay fieldName="amount" /></b>  
                    </FormLabel>
                    <FormLabel label="Keterangan" style={{width:'100%'}}>
                      <b><mainComps.FieldDataDisplay fieldName="description" /></b>  
                    </FormLabel>
                  </div>

                  <div style={{display:'flex'}}>
                    <FormLabel label="No. Referensi" style={{width:'100%'}}>
                      <b><mainComps.FieldDataDisplay fieldName="ref_number" /></b>  
                    </FormLabel>
                    <FormLabel label="Status" style={{width:'100%'}}>
                      <b><mainComps.FieldDataDisplay fieldName="exec_status" /></b>  
                    </FormLabel>
                  </div>
                  <div style={{display: this.state.isErr ? "block" : "none"}}>{this.state.errMessage}</div>
                </div>
            </DSetProvider>
          )
        }
      }
          
      // return value may be different depending on params
      return DigitalTransactionCorpView
    }

    return { componentFactory }
  }

  async function initModuleF(aReact, globals) {
    return await getImports(aReact, globals)
  }

  return initModuleF
// })()  //--DAFReact: deploy

} //--DAFReact: development

