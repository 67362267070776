/*
  DAFReact framework module - Application form (class)
  appname: cMSUser
  filename: cMSUserView.js
  moduleid: cMSUserView
  author: IK
*/

//--DAFReact: development
// 'use strict'; //--DAFReact: deploy

export function ModuleDefinition() {
	//--DAFReact: development

	// (function () { //--DAFReact: deploy

	async function getImports(React, globals) {
		// checking required libraries, may also include asynchronously loading other remote module, using appAction.loadModule
		const {
			_moduleId,
			StdAppAction,
			AppFrameAction,
			appAction,
			jsdset,
			dswidget,
			staticComponents: { FormLabel },
		} = globals;
		if (
			!StdAppAction ||
			!AppFrameAction ||
			!appAction ||
			!jsdset ||
			!dswidget
		) {
			throw new Error(
				'One of required components (StdAppAction, AppFrameAction, appAction, jsdset, dswidget) not found in globals',
			);
		}

		/* other required modules are asynchronously loaded here */
		const { metadata, initialData, serverDataMapping } =
			await appAction.fetchAndExecModule('settings.cMSUserMetadata');

		const {
			PanelDataDisplay,
			FieldDataDisplay,
			PanelButton,
			FieldDataInput,
			GridDataDisplay,
		} = dswidget;

		function componentFactory(params) {
			class CMSUserView extends React.PureComponent {
				constructor(props) {
					super(props);
					this.dsMainAction = null;
					this.appAction = appAction;
					this.dataContext = jsdset.dsetCreateContext();
					this.DSetProvider = jsdset.dsetMetaProvider(
						this.dataContext,
						metadata,
						initialData,
					);
					this.state = {
						isErr: false,
						errMessage: '',
						url: '',
						groupName: '',
					};
				}

				async loadData(e) {
					var prevState = this.state;
					this.setState({
						...prevState,
						isErr: false,
						errMessage: '',
					});

					try {
						var response = await this.appAction.fetchResource(
							_moduleId,
							'single_data',
							'dataSO',
							this.props._authToken,
							{ user_id: params.user_id },
						);
						this.dsMainAction.loadStore(
							response,
							'std',
							serverDataMapping,
							true,
						);
						this.setState({
							...prevState,
							url: response.data[0].image_ref,
							groupName: response.data[0].group_name,
						});
					} catch (err) {
						this.setState({
							...prevState,
							isErr: true,
							errMessage: err.message,
						});
						return;
					}
				}

				async componentDidMount() {
					/* load menu components or data from server here */
					await this.loadData();
				}

				render() {
					var vcomps = {
						PanelDataDisplay,
						FieldDataDisplay,
						PanelButton,
						FieldDataInput,
					}; // object of unconnected visual components

					var mainComps = jsdset.connectComponents(
						{ context: this.dataContext, dsetPath: 'main' },
						vcomps,
					);
					const DSetAction = jsdset.DSetAction;
					const DSetProvider = this.DSetProvider;

					return (
						<DSetProvider>
							<DSetAction
								ref={val => {
									this.dsMainAction = val;
								}}
								context={this.dataContext}
								dsetPath="main"
							/>
							{this.state.url && this.state.url !== '' ? (
								<center style={{ marginBottom: 10 }}>
									<img
										className="preview-image"
										src={this.state.url}
										alt="preview"
									/>
								</center>
							) : null}
							<div
								style={{
									display: 'flex',
									width: '-webkit-fill-available',
								}}
							>
								<div
									style={{
										marginRight: 10,
										width: '-webkit-fill-available',
									}}
								>
									<FormLabel label="User ID">
										<b>
											<mainComps.FieldDataDisplay fieldName="user_id" />
										</b>
									</FormLabel>
									<FormLabel label="Nama User">
										<b>
											<mainComps.FieldDataDisplay fieldName="user_name" />
										</b>
									</FormLabel>
									<FormLabel label="ID Group">
										<b>
											<mainComps.FieldDataDisplay fieldName="group_id" />{' '}
											- {this.state.groupName}
										</b>
									</FormLabel>
								</div>
								<div
									style={{
										// marginRight: 10,
										width: '-webkit-fill-available',
									}}
								>
									<FormLabel label="Nomor Telepon">
										<b>
											<mainComps.FieldDataDisplay fieldName="handphone_no" />
										</b>
									</FormLabel>
									<FormLabel label="Email">
										<b>
											<mainComps.FieldDataDisplay fieldName="email_address" />
										</b>
									</FormLabel>
									<FormLabel label="Status">
										<b>
											<mainComps.FieldDataDisplay fieldName="cms_user_status" />
										</b>
									</FormLabel>
								</div>
							</div>
						</DSetProvider>
					);
				}
			}

			// return value may be different depending on params
			return CMSUserView;
		}

		return { componentFactory };
	}

	async function initModuleF(aReact, globals) {
		return await getImports(aReact, globals);
	}

	return initModuleF;
	// })()  //--DAFReact: deploy
} //--DAFReact: development
