// 'use strict'; //--DAFReact: deploy

export function ModuleDefinition() {
	//--DAFReact: development

	// (function () { //--DAFReact: deploy

	async function getImports(React, globals) {
		// checking required libraries, may also include asynchronously loading other remote module, using appAction.loadModule
		const {
			_moduleId,
			StdAppAction,
			AppFrameAction,
			appAction,
			jsdset,
			dswidget,
			DataBrowser,
			staticComponents: {
				Validator: { isExist },
			},
		} = globals;
		if (!StdAppAction || !AppFrameAction || !appAction || !jsdset || !dswidget || !DataBrowser) {
			throw new Error('One of required components (StdAppAction, AppFrameAction, appAction, jsdset, dswidget) not found in globals');
		}

		if (!_moduleId) {
			throw new Error('_moduleId not defined');
		}

		function componentFactory() {
			const metadata = {
				settingNotification: {
					fields: [
						{
							name: 'account_no',
							type: 'string',
						},
						{
							name: 'account_name',
							type: 'string',
						},
						{
							name: 'tx_code',
							type: 'string',
							validator: (value, fieldName) => {
								let check = isExist(value, 'Transaksi', 1, 10);
								return check;
							},
						},
						{
							name: 'tx_name',
							type: 'string',
						},
						{
							name: 'name',
							type: 'string',
							length: 50,
						},
						{
							name: 'ch_email',
							type: 'string',
						},
						{
							name: 'ch_sms',
							type: 'string',
						},
						{
							name: 'cr_min',
							type: 'int',
						},
						{
							name: 'db_min',
							type: 'int',
						},
						{
							name: 'id_notif',
							type: 'string',
						},
						{
							name: 'is_active',
							type: 'string',
						},
					],

					indexes: ['id_notif'],
				},
			};

			const initialData = {
				'main:settingNotification': [],
			};

			const serverDataMapping = {
				data: {
					dset: 'main',
					type: 'settingNotification',
					fieldMapping: {
						'*': true, // allow default fields
					},
				},
			};

			const editUIData = {
				settingNotification: {
					fields: {
						sms: {
							inputType: 'checkbox',
						},
						mail: {
							inputType: 'checkbox',
						},
						ch_email: {
							lookup: {
								style: {
									input: true,
									button: true,
								},
								dataId: 'listEmail',
								selFields: ['ch_email'],
								fieldMap: {
									ch_email: 'ch_email',
								},
								apiParameterF: (fields, isValidation = false) => {
									return isValidation
										? {
												searchMode: 1,
												searchKey: fields.account_no,
										  }
										: {
												account_no: fields.account_no,
										  };
								},
							},
						},
						ch_sms: {
							lookup: {
								style: {
									input: true,
									button: true,
								},
								dataId: 'listPhone',
								selFields: ['ch_sms'],
								fieldMap: {
									ch_sms: 'ch_sms',
								},
								apiParameterF: (fields, isValidation = false) => {
									return isValidation
										? {
												searchMode: 1,
												searchKey: fields.account_no,
										  }
										: {
												account_no: fields.account_no,
										  };
								},
							},
						},
						tx_code: {
							lookup: {
								style: {
									input: true,
									button: true,
								},
								dataId: 'listTxCode',
								selFields: ['tx_code', 'description'],
								fieldMap: {
									tx_code: 'tx_code',
									tx_name: 'description',
								},
							},
						},
						cr_min: { thousand: true, decimal: 2 },
						db_min: { thousand: true, decimal: 2 },
						is_active: {
							inputType: 'radio',
							readOnly: false,
							type: 'icon',
							variant: 'bordered',
							dataSets: {
								True: 'Aktif',
								False: 'Tidak Aktif',
							},
							initialValue: 'False',
						},
					},
				},
			};

			// return value may be different depending on params
			return { metadata, initialData, serverDataMapping, editUIData };
		}

		return { componentFactory };
	}

	async function initModuleF(aReact, globals) {
		return await getImports(aReact, globals);
	}

	return initModuleF;
	// })()  //--DAFReact: deploy
} //--DAFReact: development
