// 'use strict';

export function ModuleDefinition() {
	//--DAFReact: development

	// (function () { //--DAFReact: deploy

	async function getImports(React, globals) {
		const {
			_moduleId,
			StdAppAction,
			AppFrameAction,
			appAction,
			jsdset,
			dswidget,
			NDataBrowser,
			frameAction,
			staticComponents: { Button, PageTitle, SearchBox, Filter, FilterActionButtons, AlertComponent, ReactIconFa, Dropdown },
		} = globals;
		if (!_moduleId || !StdAppAction || !AppFrameAction || !appAction || !jsdset || !dswidget || !NDataBrowser) {
			throw new Error('One of required components (_moduleId, StdAppAction, AppFrameAction, appAction, jsdset, dswidget, _moduleId) not found in globals');
		}

		const { editUIData } = await appAction.fetchAndExecModule('settings.autosweep.autosweep-metadata');

		const { FaPlusCircle, FaRetweet } = ReactIconFa;

		function componentFactory(params) {
			function AppForm(props) {
				const [state, setState] = React.useState({
					popupComponent: undefined,
				});

				const dsetProvider = React.useMemo(() => {
					return jsdset.dsetEmptyProvider();
				}, []);

				React.useEffect(() => {
					(async function () {
						const popupComponent = await appAction.fetchMenu(_moduleId, 'popup_menu', props._authToken);
						setState({ popupComponent });
					})();
				}, [props._authToken]);

				const handleAddButton = React.useCallback(async () => {
					const { _authToken, componentClass } = await appAction.fetchFrameComponentWithToken(
						'settings.autosweep.autosweep-form',
						{},
						{},
						{
							menuId: 'popup_menu',
							menuModuleId: _moduleId,
							key: 'mnuAutosweepForm',
						},
					);
					if (componentClass && _authToken) {
						await frameAction.showModalAsync({
							headerProps: {
								title: 'Buat Konfigurasi Baru',
								icon: 'FaUserPlus',
							},
							contentClass: componentClass,
							contentProps: {
								_authToken,
								uiMode: 'add',
							},
							size: 'large',
						});
					}
				}, []);

				const ActionButtons = React.useCallback(clearFilter => {
					return [
						{
							type: 'bordered',
							name: 'Reload',
							onClick: () => clearFilter(),
							render: () => <FaRetweet />,
						},
					];
				}, []);

				const confirmComponent = ({ message, closeModal }) => {
					return (
						<div>
							<div
								style={{
									paddingTop: '6rem',
									paddingBottom: '6rem',
									marginBottom: 15,
									fontSize: '1.2rem',
								}}
							>
								{message}
							</div>
							<div
								style={{
									display: 'flex',
									justifyContent: 'flex-end',
									marginTop: 30,
								}}
							>
								<Button
									type="bordered"
									style={{
										marginRight: 10,
									}}
									onClick={() => closeModal(false)}
								>
									Batal
								</Button>
								<Button onClick={() => closeModal(true)}>Proses</Button>
							</div>
						</div>
					);
				};

				const AlertHandler = async (msg, type = 'warning') => {
					await frameAction.showModal({
						contentClass: props => <AlertComponent {...props} title={msg} type={type} />,
						size: 'small',
					});
				};

				return (
					<>
						<PageTitle title="Tags" subtitle="Pengaturan" style={{ marginBottom: `1.5rem` }}>
							<Button onClick={handleAddButton}>
								<FaPlusCircle /> Buat Konfigurasi Baru
							</Button>
						</PageTitle>

						<NDataBrowser
							stdAppAction={appAction}
							moduleId={_moduleId}
							dataId="getDataList"
							resourceType="method"
							// resourceType="scroll_query"
							dataProvider={dsetProvider}
							authToken={props._authToken}
							dsetPath="main"
							uiData={editUIData}
							popupComponent={p => <state.popupComponent {...p} confirmComponent={confirmComponent} AlertHandler={(msg, type) => AlertHandler(msg, type)} />}
							filterComponent={({ sortFields, clearFilter, changeParams, params }) => {
								return (
									<Filter>
										<Dropdown
											label="Sort and search: "
											onChange={e =>
												changeParams({
													sortField: e.target.value,
												})
											}
											item={sortFields}
											value={params.sortField}
										/>

										<SearchBox
											style={{
												marginLeft: 5,
											}}
											handleSearch={src => changeParams({ src })}
											srcText={params.src}
										/>
										<FilterActionButtons buttons={ActionButtons(clearFilter)} />
									</Filter>
								);
							}}
						/>
					</>
				);
			}

			return React.memo(AppForm);
		}

		return { componentFactory };
	}

	return async (aReact, globals) => {
		return await getImports(aReact, globals);
	};

	// })()  //--DAFReact: deploy
} //--DAFReact: development
