/*
  DAFReact framework module - Application form (hook)
  appname: cMSWorkflow
  filename: cMSWorkflowList.js
  moduleid: cMSWorkflowList
  author: IK
*/

// 'use strict';

export function ModuleDefinition() {
	//--DAFReact: development

	// (function () { //--DAFReact: deploy

	async function getImports(React, globals) {
		const {
			_moduleId,
			StdAppAction,
			AppFrameAction,
			appAction,
			jsdset,
			dswidget,
			DataBrowser,
			NDataBrowser,
			frameAction,
			staticComponents: {
				FormLabel,
				Button,
				PageTitle,
				SearchBox,
				Filter,
				FilterActionButtons,
				AlertComponent,
				ReactIconFa,
				Dropdown,
				ReactIconSi: { SiMicrosoftexcel },
			},
		} = globals;
		if (
			!_moduleId ||
			!StdAppAction ||
			!AppFrameAction ||
			!appAction ||
			!jsdset ||
			!dswidget ||
			!DataBrowser
		) {
			throw new Error(
				'One of required components (_moduleId, StdAppAction, AppFrameAction, appAction, jsdset, dswidget, _moduleId) not found in globals',
			);
		}

		const { FaPlusCircle, FaRetweet } = ReactIconFa;

		const { editUIData } = await appAction.fetchAndExecModule(
			'settings.cMSWorkflowMetadata',
		);

		function componentFactory(params) {
			function AppForm(props) {
				const [state, setState] = React.useState({
					popupComponent: undefined,
				});

				const dsetProvider = React.useMemo(
					() => jsdset.dsetEmptyProvider(),
					[],
				);

				const initialFilter = {
					start_date: new Date(),
					end_date: new Date(),
				};
				const [filter, setFilter] = React.useState(initialFilter);

				React.useEffect(() => {
					(async function () {
						const popupComponent = await appAction.fetchMenu(
							_moduleId,
							'popup_menu',
							props._authToken,
						);
						setState({ popupComponent });
					})();
				}, [props._authToken]);

				var loadFunction;

				const handleAddButton = React.useCallback(async () => {
					const { _authToken, componentClass } =
						await appAction.fetchFrameComponentWithToken(
							'settings.cMSWorkflowEditNew',
							{},
							{},
							{
								menuId: 'popup_menu',
								menuModuleId: _moduleId,
								key: 'mnuAddCMSWorkflow',
							},
						);

					if (componentClass && _authToken) {
						const modalshow = await frameAction.showModalAsync({
							headerProps: {
								title: 'Master Workflow Otorisasi Baru',
								icon: 'FaSquareWave',
							},
							contentClass: componentClass,
							contentProps: {
								_authToken,
								uiMode: 'add',
							},
							size: 'large',
						});
						if (!modalshow) {
							loadFunction();
						}
					}
				}, []);

				const ActionButtons = React.useCallback(
					({ clearFilter, params }) => {
						return [
							{
								type: 'bordered',
								name: 'Print Excel',
								onClick: async () => {
									try {
										await appAction.postData(
											_moduleId,
											'importList',
											props._authToken,
											{ ...params, ...filter },
										);
									} catch (err) {
										AlertHandler(err.message, 'alert')
									}
								},
								render: () => <SiMicrosoftexcel />,
							},
							{
								type: 'bordered',
								name: 'Reload',
								onClick: () => {
									setFilter(initialFilter);
									clearFilter();
								},
								render: () => <FaRetweet />,
							},
						];
					}, []);

				const AlertHandler = async (msg, type) => {
					await frameAction.showModal({
						contentClass: props => (
							<AlertComponent
								{...props}
								title={msg}
								type={type}
							/>
						),
						size: 'small',
					});
				}

				const setLoadFunction = React.useCallback(
					loadDataClick => (loadFunction = loadDataClick),
					[],
				);

				return (
					<>
						{/* PageTitle */}
						<PageTitle
							title="Master Workflow Otorisasi"
							subtitle="Pengaturan"
							style={{
								marginBottom: `1.5rem`,
								marginRight: '1rem',
							}}
						>
							<Button onClick={handleAddButton}>
								<FaPlusCircle />Buat Workflow Baru
							</Button>
						</PageTitle>

						<NDataBrowser
							stdAppAction={appAction}
							moduleId={_moduleId}
							dataId="listSO"
							dataProvider={dsetProvider}
							authToken={props._authToken}
							dsetPath="main"
							uiData={editUIData}
							filterComponent={({
								sortFields,
								loadDataClick,
								clearFilter,
								changeParams,
								params,
							}) => {
								setLoadFunction(loadDataClick);

								return (
									<Filter>
										<FormLabel
											label="Urutkan"
											style={{
												maxWidth: '200px',
												marginRight: '1rem',
											}}
										>
											<Dropdown
												onChange={e =>
													changeParams({
														sortField:
															e.target.value,
													})
												}
												item={sortFields}
												value={params.sortField}
												width="100%"
												style={{
													justifyContent: 'flex-end',
												}}
											/>
										</FormLabel>
										<FormLabel label="Cari Data">
											<SearchBox
												style={{
													marginLeft: 5,
													width: '300px',
												}}
												handleSearch={src =>
													changeParams({ src })
												}
												srcText={params.src}
											/>
										</FormLabel>

										<FilterActionButtons
											buttons={ActionButtons({
												clearFilter,
												params,
											})}
										/>
									</Filter>
								);
							}}
							popupComponent={state.popupComponent}
						/>
					</>
				);
			}

			return React.memo(AppForm);
		}

		return { componentFactory };
	}

	return async (aReact, globals) => {
		return await getImports(aReact, globals);
	};

	// })()  //--DAFReact: deploy
} //--DAFReact: development
