/*
  DAFReact framework module - Application form (class)
  appname: loginLogCorp
  filename: loginLogCorpView.js
  moduleid: loginLogCorpView
  author: IK
*/

import React from 'react'; //--DAFReact: development

// 'use strict'; //--DAFReact: deploy

export function ModuleDefinition() {
	//--DAFReact: development

	// (function () { //--DAFReact: deploy

	async function getImports(React, globals) {
		// checking required libraries, may also include asynchronously loading other remote module, using appAction.loadModule
		const {
			_moduleId,
			StdAppAction,
			appAction,
			jsdset,
			dswidget,
			ndswidget,
			staticComponents: { FormLabel },
		} = globals;

		if (!_moduleId || !StdAppAction || !appAction || !jsdset || !dswidget) {
			throw new Error(
				'One of required components (_moduleId, StdAppAction, appAction, jsdset, dswidget) not found in globals',
			);
		}

		/* other required modules are asynchronously loaded here */
		const { metadata, initialData, serverDataMapping, editUIData } =
			await appAction.fetchAndExecModule('log_corp.loginLogCorpMetadata');

		const { PanelDataDisplay, PanelButton } = dswidget;
		const { FieldDataDisplay, FieldDataInput } = ndswidget;

		function componentFactory(params) {
			function AppForm(props) {
				const dataContext = React.useMemo(
					() => jsdset.dsetCreateContext(),
					[],
				);
				const DSetProvider = React.useMemo(
					() =>
						jsdset.dsetMetaProvider(
							dataContext,
							metadata,
							initialData,
							editUIData,
						),
					[],
				);
				return (
					<DSetProvider>
						<AppFormUI dataContext={dataContext} {...props} />{' '}
						{/* any other props will be passed down */}
					</DSetProvider>
				);
			}

			function AppFormUI(props) {
				const [state, setState] = React.useState({
					isErr: false,
					errMessage: '',
					isEditing: false,
				});

				// bind controls to _moduleId and _authToken
				const vComps = React.useMemo(
					() =>
						appAction.connect(
							{
								PanelDataDisplay,
								FieldDataDisplay,
								PanelButton,
								FieldDataInput,
							},
							{ _moduleId, _getToken: () => props._authToken },
						),
					[],
				);

				// bind controls to datasets
				const [mainComps] = React.useMemo(
					() => [
						jsdset.connect(
							{ context: props.dataContext, dsetPath: 'main' },
							vComps,
						),
					],
					[props.dataContext, vComps],
				);

				// obtain action objects from data context
				const [, dsMainAction, dsMainProxy] = jsdset.useDSetContext(
					props.dataContext,
					'main',
				);

				// load data function
				const loadData = React.useCallback(async () => {
					setState(state => ({
						...state,
						isErr: false,
						errMessage: '',
					}));

					try {
						const response = await appAction.fetchResource(
							_moduleId,
							'single_data',
							'dataSO',
							props._authToken,
							{ id_login: params.id_login },
							true,
						);
						dsMainAction.loadStore(
							response,
							'std',
							serverDataMapping,
							true,
						);
						dsMainAction.recalcFormulas();
					} catch (err) {
						setState(prevState => ({
							...prevState,
							isErr: true,
							errMessage: err.message,
						}));
						return;
					}
				}, [dsMainAction, props._authToken, props.id_login]);

				// set event on component mounting
				React.useEffect(() => {
					(async function () {
						await loadData();
					})();
				}, [dsMainAction, loadData, props.uiMode]);

				// UI event handlers
				const switchEditClick = () => {
					//
					setState(prevState => ({
						...prevState,
						isEditing: !prevState.isEditing,
					}));
				};

				const saveDataClick = async () => {
					try {
						var dataUnload = dsMainProxy.unloadStore(
							serverDataMapping,
							{
								includeLoadedRows: false,
								includeDeletedRows: true,
							},
						);
						await appAction.postData(
							_moduleId,
							'saveData',
							props._authToken,
							dataUnload,
						);
						appAction.frameAction.closeModal();
					} catch (err) {
						await appAction.frameAction.showMessage(
							err.message,
							'Error save data',
							{ messageType: 'error' },
						);
					}
				};

				// render
				return (
					<div>
						<div
							style={{
								display: 'flex',
								width: '-webkit-fill-available',
							}}
						>
							<div
								style={{
									marginRight: 10,
									width: '50%',
									wordWrap: 'break-word',
								}}
							>
								<FormLabel label="Corporate Code">
									<b>
										{dsMainProxy.fields.corporate_code &&
										dsMainProxy.fields.corporate_code !==
											'' ? (
											<mainComps.FieldDataDisplay fieldName="corporate_code" />
										) : (
											'-'
										)}
									</b>
								</FormLabel>
								<FormLabel label="User ID">
									<b>
										{dsMainProxy.fields.user_id &&
										dsMainProxy.fields.user_id !== '' ? (
											<mainComps.FieldDataDisplay fieldName="user_id" />
										) : (
											'-'
										)}
									</b>
								</FormLabel>
								<FormLabel label="ID Login">
									<b>
										{dsMainProxy.fields.id_login &&
										dsMainProxy.fields.id_login !== '' ? (
											<mainComps.FieldDataDisplay fieldName="id_login" />
										) : (
											'-'
										)}
									</b>
								</FormLabel>
							</div>
							<div
								style={{
									// marginRight: 10,
									width: '50%',
									wordWrap: 'break-word',
								}}
							>
								<FormLabel label="Akses Menu">
									<b>
										{dsMainProxy.fields.access_menu &&
										dsMainProxy.fields.access_menu !==
											'' ? (
											<mainComps.FieldDataDisplay fieldName="access_menu" />
										) : (
											'-'
										)}
									</b>
								</FormLabel>
								<FormLabel label="Login Date">
									<b>
										{dsMainProxy.fields.login_datetime &&
										dsMainProxy.fields.login_datetime !==
											'' ? (
											<mainComps.FieldDataDisplay fieldName="login_datetime" />
										) : (
											'-'
										)}
									</b>
								</FormLabel>
								<FormLabel label="Logout Date">
									<b>
										{dsMainProxy.fields.logout_datetime &&
										dsMainProxy.fields.logout_datetime !==
											'' ? (
											<mainComps.FieldDataDisplay fieldName="logout_datetime" />
										) : (
											'-'
										)}
									</b>
								</FormLabel>
								<FormLabel label="Geolocation Info">
									<b>
										{dsMainProxy.fields.geolocation_info &&
										dsMainProxy.fields.geolocation_info !==
											'' ? (
											<mainComps.FieldDataDisplay fieldName="geolocation_info" />
										) : (
											'-'
										)}
									</b>
								</FormLabel>
							</div>
							<div
								style={{
									display: state.isErr ? 'block' : 'none',
								}}
							>
								{state.errMessage}
							</div>
						</div>
					</div>
				);
			}

			// return value may be different depending on params
			return React.memo(AppForm);
		}

		return { componentFactory };
	}

	async function initModuleF(aReact, globals) {
		return await getImports(aReact, globals);
	}

	return initModuleF;
	// })()  //--DAFReact: deploy
} //--DAFReact: development
