import React from 'react'; //--DAFReact: development

// 'use strict'; //--DAFReact: deploy

export function ModuleDefinition() {
	//--DAFReact: development

	// (function () { //--DAFReact: deploy

	async function getImports(React, globals) {
		// checking required libraries, may also include asynchronously loading other remote module, using appAction.loadModule
		const {
			_moduleId,
			StdAppAction,
			appAction,
			jsdset,
			dswidget,
			ndswidget,
			frameAction,
			staticComponents: { FormLabel, Button, AlertComponent },
		} = globals;
		if (!_moduleId || !StdAppAction || !appAction || !jsdset || !dswidget) {
			throw new Error(
				'One of required components (_moduleId, StdAppAction, appAction, jsdset, dswidget) not found in globals',
			);
		}

		/* other required modules are asynchronously loaded here */
		const { metadata, initialData, serverDataMapping, editUIData } =
			await appAction.fetchAndExecModule(
				'settings.cMSUserGroupLimitMetadata',
			);

		const { PanelDataDisplay, PanelButton } = dswidget;
		const { FieldDataDisplay, FieldDataInput } = ndswidget;

		function componentFactory(params) {
			function AppForm(props) {
				const dataContext = React.useMemo(
					() => jsdset.dsetCreateContext(),
					[],
				);
				const DSetProvider = React.useMemo(() => {
					return jsdset.dsetMetaProvider(
						dataContext,
						metadata,
						initialData,
						editUIData,
					);
				}, []);
				return (
					<DSetProvider>
						<AppFormUI dataContext={dataContext} {...props} />
						{/* any other props will be passed down */}
					</DSetProvider>
				);
			}

			function AppFormUI(props) {
				const [state, setState] = React.useState({
					isErr: false,
					errMessage: '',
					isEditing: false,
				});

				// bind controls to _moduleId and _authToken
				const vComps = React.useMemo(
					() =>
						appAction.connect(
							{
								PanelDataDisplay,
								FieldDataDisplay,
								PanelButton,
								FieldDataInput,
							},
							{ _moduleId, _getToken: () => props._authToken },
						),
					[],
				);

				// bind controls to datasets
				const [mainComps] = React.useMemo(
					() => [
						jsdset.connect(
							{ context: props.dataContext, dsetPath: 'main' },
							vComps,
						),
					],
					[props.dataContext, vComps],
				);

				// obtain action objects from data context
				const [, dsMainAction, dsMainProxy] = jsdset.useDSetContext(
					props.dataContext,
					'main',
				);

				// load data function
				const loadData = React.useCallback(async () => {
					setState(state => ({
						...state,
						isErr: false,
						errMessage: '',
					}));

					try {
						const response = await appAction.fetchResource(
							_moduleId,
							'single_data',
							'dataSO',
							props._authToken,
							{
								corporate_code: props.corporate_code,
								tx_code: props.tx_code,
							},
							true,
						);
						if (response?.data?.length > 0) {

							dsMainAction.loadStore(
								response,
								'std',
								serverDataMapping,
								true,
							);
							dsMainAction.recalcFormulas();
						} else {
							appAction.frameAction.closeModal();
							AlertHandler(
								'Data Tidak Bisa di Ubah',
								'warning',
							);
						}
					} catch (err) {
						setState(prevState => ({
							...prevState,
							isErr: true,
							errMessage: err.message,
						}));
						return;
					}
				}, [
					dsMainAction,
					props._authToken,
					props.corporate_code,
					props.tx_code,
				]);

				// set event on component mounting
				React.useEffect(() => {
					(async function () {
						if (props.uiMode == 'edit') {
							await loadData();
						} else {
							dsMainAction.addRow({
								corporate_code: props.corporate_code,
								is_blocked: 'F',
								max_debit: '0',
								max_debit_daily: '0',
								max_count: '0',
							});
						}
					})();
				}, [
					dsMainAction,
					loadData,
					props.uiMode,
					props.corporate_code,
				]);

				const saveDataClick = async () => {
					try {
						const { fieldValidStates, fieldValidErrors } =
							dsMainProxy;
						const isInValid = Object.entries(
							fieldValidStates,
						).filter(([i, v]) => {
							return !v;
						});

						if (isInValid.length > 0)
							throw new Error(fieldValidErrors[isInValid[0][0]]);

						var dataUnload = dsMainProxy.unloadStore(
							serverDataMapping,
							{
								includeLoadedRows: false,
								includeDeletedRows: true,
							},
						);

						await appAction.postData(
							_moduleId,
							'saveData',
							props._authToken,
							dataUnload,
						);
						appAction.frameAction.closeModal();
						AlertHandler('Data berhasil disimpan', 'success');
					} catch (err) {
						AlertHandler(err.message, 'alert');
					}
				};

				const AlertHandler = async (msg, type) => {
					await frameAction.showModal({
						contentClass: props => (
							<AlertComponent
								{...props}
								title={msg}
								type={type}
							/>
						),
						size: 'small',
					});
				};

				// render
				return (
					<div style={{ padding: '1rem' }}>
						{/* lookup behavior of customer no is automatically set based on uiData */}

						{props.uiMode === 'add' ? (
							<FormLabel label="No. Transaksi">
								<mainComps.FieldDataInput
									fieldName="tx_code"
									elProps={{ readOnly: true }}
								/>
							</FormLabel>
						) : (
							<FormLabel label="No. Transaksi">
								<h6>
									<strong>
										<mainComps.FieldDataDisplay fieldName="tx_code" />
										<span> - </span>
										<mainComps.FieldDataDisplay fieldName="description" />
									</strong>
								</h6>
							</FormLabel>
						)}

						<FormLabel label="Status Transaksi">
							<mainComps.FieldDataInput fieldName="is_blocked" />
						</FormLabel>

						<FormLabel label="Maksimal Debit">
							<mainComps.FieldDataInput fieldName="max_debit" />
						</FormLabel>
						<FormLabel label="Maksimal Debit Harian">
							<mainComps.FieldDataInput fieldName="max_debit_daily" />
						</FormLabel>
						<FormLabel label="Maksimal Frekuensi Transaksi">
							<mainComps.FieldDataInput fieldName="max_count" />
						</FormLabel>
						<div
							style={{
								display: 'flex',
								justifyContent: 'flex-end',
								paddingTop: 50,
							}}
						>
							<Button
								onClick={() =>
									appAction.frameAction.closeModal()
								}
								type="bordered"
								style={{
									marginRight: 10,
								}}
							>
								Batalkan
							</Button>
							<Button onClick={saveDataClick}>Simpan Data</Button>
						</div>
						<div
							style={{ display: state.isErr ? 'block' : 'none' }}
						>
							{state.errMessage}
						</div>
					</div>
				);
			}

			// return value may be different depending on params
			return React.memo(AppForm);
		}

		return { componentFactory };
	}

	async function initModuleF(aReact, globals) {
		return await getImports(aReact, globals);
	}

	return initModuleF;
	// })()  //--DAFReact: deploy
} //--DAFReact: development
