import React from 'react';

// import component here:
import Input from '../../app_components/Forms/Input/Input';
import Dropdown from '../../app_components/Dropdown/Dropdown';
import Button from '../../app_components/Button/Button';
import CheckboxBordered from '../../app_components/Forms/CheckboxBordered/CheckboxBordered';
import RadioGroup from '../../app_components/Forms/RadioGroup/RadioGroup';

import lookupselector from './dataselector.js';

import moment from 'moment';
import 'moment/locale/id';
moment.locale('id');

const FieldDataDisplay = p => {
	var fieldName = p.fieldName;
	var fd = p.fieldDefs[fieldName];

	if (!fd) {
		return <span></span>;
	}

	const uidField = (p.uiData && p.uiData.fields ? p.uiData.fields[p.fieldName] : {}) || {};
	const { numformatted, thousand, decimal, dateformat } = uidField;

	return (
		<span>
			{p.fields === undefined
				? ''
				: fd.type === 'date'
				? moment(p.fields[fieldName]).format(dateformat || 'llll')
				: fd.asString(p.fields[fieldName], {
						numformatted,
						thousand,
						decimal,
				  })}
		</span>
	);
};

class FieldDataInput extends React.Component {
	constructor(props) {
		super(props);

		const uidField = (props.uiData && props.uiData.fields ? this.props.uiData.fields[props.fieldName] : {}) || {};
		this.uidField = uidField;

		const lookup = uidField.lookup && typeof uidField.lookup === 'object' ? uidField.lookup : {};
		this.lookup = lookup;
		const lookupStyle = lookup.style || {};
		this.lookupStyle = lookupStyle;
		if (props.dataSelector && typeof props.dataSelector !== 'function') throw new Error('Data selector must be async function');
		if (props.dataValidator && typeof props.dataValidator !== 'function') throw new Error('Data validator must be async function');

		this.dataSelector = props.dataSelector || (props.appAction && props._moduleId && props._getToken && this.defaultDataSelector);

		this.dataValidator = props.dataValidator || (props.appAction && props._moduleId && props._getToken && this.defaultDataValidator);

		this.onInvalidData = props.onInvalidData || (props.appAction && props._moduleId && props._getToken && this.defaultInvalidData);
		this.state = { focused: false, textValue: '' };
	}

	defaultDataSelector = async (dataId, fields, apiParameters, options) => {
		return await lookupselector(this.props.appAction)({
			apiInfo: {
				moduleId: this.props._moduleId,
				dataId,
				authToken: this.props._getToken(),
			},
			arrFields: fields,
			params: apiParameters,
			availableExtraParams: [],
			options,
		});
	};

	defaultDataValidator = async (dataId, fields, keyFields, selFields, apiParameters, options) => {
		return await this.props.appAction.browseData(
			{
				moduleId: this.props._moduleId,
				dataId,
				authToken: this.props._getToken(),
			},
			fields,
			keyFields,
			selFields,
			apiParameters,
			Object.assign(options, {
				title: null,
				hideColumnTitles: true,
				minimalist: true,
				directSelection: true,
			}),
		);
	};

	defaultInvalidData = async fieldName => {
		await this.props.appAction.frameAction.showMessage(`No data match for ${fieldName}`);
	};

	onChange = e => {
		const fieldName = this.props.fieldName;

		var inputVal = e.target.value;
		switch (this.props.fieldDefs[fieldName].type) {
			case 'int':
				inputVal = parseInt(inputVal);
				break;
			case 'float':
				inputVal = parseFloat(inputVal);
				break;
			default:
				break;
		}
		this.props.setField(fieldName, inputVal);
		this.setState({ ...this.state, textValue: inputVal });
		if (this.props.onChange && typeof this.props.onChange === 'function') this.props.onChange(e);
	};

	onFocus = e => {
		e.target.select();
		this.setState({ ...this.state, focused: true });
		if (this.props.onFocus && typeof this.props.onFocus === 'function') this.props.onFocus(e);
	};

	onExit = e => {
		const fieldName = this.props.fieldName;
		const checkFieldName = '__chk_' + fieldName;
		this.setState({ ...this.state, focused: false });

		if (this.uidField.lookup && this.lookupStyle.input && this.dataValidator) {
			//  validate this field if lookup-input field
			const lookup = this.lookup;
			const fields = this.props.fields;

			if (checkFieldName in fields && fields[fieldName] !== fields[checkFieldName]) {
				var selectPromise = this.dataValidator(
					lookup.dataId,
					lookup.fields,
					lookup.keyField,
					lookup.selFields,
					lookup.apiParameterF && typeof lookup.apiParameterF === 'function' ? lookup.apiParameterF(this.props.fields, true) : {},
					this.props.validatorSettings || {},
				);
				if (!selectPromise.constructor || selectPromise.constructor.name !== 'Promise')
					throw new Error('dataValidator must be async function');

				selectPromise.then(result => {
					if (result && lookup.fieldMap && typeof lookup.fieldMap === 'object') {
						var fieldSettings = Object.fromEntries(Object.keys(lookup.fieldMap).map(k => [k, result[lookup.fieldMap[k]]]));
						fieldSettings['__chk_' + fieldName] = fieldSettings[fieldName];
						this.props.setFields(fieldSettings);
					} else if (result === null) {
						if (this.onInvalidData && typeof this.onInvalidData === 'function') {
							const pr = this.onInvalidData(fieldName);
							if (pr.constructor && pr.constructor.name === 'Promise') {
								pr.then(() => {});
							}
						}
						//
					}
				});
			}
		}

		if (this.props.onExit && typeof this.props.onExit === 'function') this.props.onExit(e);
	};

	onLookupClick = async e => {
		if (this.dataSelector) {
			const lookup = this.lookup;
			var apiparamsf = lookup.apiParameterF && typeof lookup.apiParameterF === 'function' ? lookup.apiParameterF(this.props.fields, false) : {};
			var params = lookup.params || {};

			var selectPromise = this.dataSelector(lookup.dataId, lookup.fields, { ...params, ...apiparamsf }, this.props.selectorSettings || {});

			if (!selectPromise.constructor || selectPromise.constructor.name !== 'Promise') throw new Error('dataSelector must be async function');
			selectPromise.then(result => {
				if (result && lookup.fieldMap && typeof lookup.fieldMap === 'object') {
					var fieldSettings = Object.fromEntries(Object.keys(lookup.fieldMap).map(k => [k, result[lookup.fieldMap[k]]]));
					this.props.setFields(fieldSettings);
				}
			});
		}
	};

	render() {
		const props = this.props;
		const state = this.state;
		const fieldName = props.fieldName;
		const vals = props.fields;
		const fd = props.fieldDefs[fieldName];
		const elProps = props.elProps ? props.elProps : {};
		const lookupStyle = this.lookupStyle;
		const { numformatted, thousand, decimal, dateformat } = this.uidField;

		const [key, val] = Object.keys(this.uidField.lookup?.fieldMap || []);

		const { fieldValidErrors, fieldValidStates } = props;

		// if (!fd) console.log(`FieldDataInput: field ${fieldName} not found`);

		function getValue() {
			if (!fd) return '';

			if (state.focused)
				return (fd.type === 'float' || fd.type === 'int') && parseFloat(state.textValue) === vals[fieldName]
					? state.textValue
					: fd.asString(vals[fieldName]);

			return key && val
				? fd.asString(vals[key] && vals[val] ? vals[key] + ' - ' + vals[val] : '', {
						numformatted,
						thousand,
						decimal,
						dateformat,
				  })
				: fd.asString(vals[fieldName], {
						numformatted,
						thousand,
						decimal,
						dateformat,
				  });
		}

		// check if field is select, radio, or checkbox
		const { dataSets = null, inputType = null, initialValue = null, type = null, variant = null, withborder = null } = this.uidField;

		// components for FieldDataInput
		const getInputComponent = () => {
			return (
				<Input
					value={getValue()}
					onChange={this.onChange}
					onFocus={this.onFocus}
					onBlur={this.onExit}
					errors={{
						isError: !fieldValidStates[fieldName] || false,
						errorMessage: fieldValidErrors[fieldName] || '',
					}}
					{...{
						readOnly: (this.uidField.lookup && !lookupStyle.input) || this.uidField.readOnly || false,
						maxLength: fd ? fd.length : undefined,
					}}
					{...elProps}
				/>
			);
		};

		const getLookupInput = () => {
			return (
				<div
					style={{
						display: 'flex',
					}}
				>
					<div style={{ flex: 9 }}>{getInputComponent()}</div>
					{lookupStyle.button && this.dataSelector && (
						<div style={{ flex: 1 }}>
							<Button style={{ width: '100%' }} onClick={this.onLookupClick}>
								{lookupStyle.buttonText || '...'}
							</Button>
						</div>
					)}
				</div>
			);
		};

		const getRadioComponent = ({ dataSets }) => {
			return (
				<>
					<RadioGroup
						data={Object.entries(dataSets)}
						name={fieldName}
						type={type}
						variant={variant}
						withborder={withborder}
						onChange={this.onChange}
						initialValue={initialValue}
						currentValue={vals[fieldName]}
						readOnly={this.uidField.readOnly || false}
						errors={{
							isError: !fieldValidStates[fieldName] || false,
							errorMessage: fieldValidErrors[fieldName] || '',
						}}
						{...elProps}
					/>
				</>
			);
		};

		const getCheckboxComponent = () => {
			return <CheckboxBordered value={vals[fieldName]} onChange={this.onChange} label={fd.title} {...elProps} />;
		};

		const getSelectComponent = ({ dataSets }) => {
			return (
				<Dropdown
					value={vals[fieldName]}
					onChange={this.onChange}
					item={Object.entries(dataSets).map(i => ({
						value: i[0],
						name: i[1],
					}))}
					{...elProps}
					errors={{
						isError: !fieldValidStates[fieldName] || false,
						errorMessage: fieldValidErrors[fieldName] || '',
					}}
				/>
			);
		};

		const availableDataSets = {
			radio: getRadioComponent,
			checkbox: getCheckboxComponent,
		};

		if (dataSets && typeof dataSets === 'object') {
			return Object.keys(availableDataSets).indexOf(inputType) > -1
				? availableDataSets[inputType]({ dataSets })
				: getSelectComponent({ dataSets });
		}

		if (inputType === 'checkbox') return getCheckboxComponent();

		if (this.uidField.lookup && lookupStyle.input) {
			return getLookupInput();
		}

		return getInputComponent();
	}
}

export { FieldDataDisplay, FieldDataInput };
