// 'use strict';

export function ModuleDefinition() {
	//--DAFReact: development

	// (function () { //--DAFReact: deploy

	async function getImports(React, globals) {
		const {
			_moduleId,
			StdAppAction,
			AppFrameAction,
			appAction,
			jsdset,
			dswidget,
			NDataBrowser,
			frameAction,
			staticComponents: { Button, PageTitle, SearchBox, Filter, FilterActionButtons, ReactIconSi, ReactIconFa, RadioGroup, Dropdown },
		} = globals;
		if (!_moduleId || !StdAppAction || !AppFrameAction || !appAction || !jsdset || !dswidget || !NDataBrowser) {
			throw new Error('One of required components (_moduleId, StdAppAction, AppFrameAction, appAction, jsdset, dswidget, _moduleId) not found in globals');
		}

		const { metadata, initialData, serverDataMapping, editUIData } = await appAction.fetchAndExecModule('settings.tags.settingTagsMetadata');

		const { FaPlusCircle, FaRetweet } = ReactIconFa;
		const { SiMicrosoftexcel } = ReactIconSi;

		function componentFactory(params) {
			function AppForm(props) {
				const [state, setState] = React.useState({
					popupComponent: undefined,
				});

				const dsetProvider = React.useMemo(() => {
					return jsdset.dsetEmptyProvider();
				}, []);

				React.useEffect(() => {
					(async function () {
						const popupComponent = await appAction.fetchMenu(_moduleId, 'popup_menu', props._authToken);
						setState({ popupComponent });
					})();
				}, [props._authToken]);

				const handleAddButton = React.useCallback(async () => {
					const { _authToken, componentClass } = await appAction.fetchFrameComponentWithToken(
						'settings.tags.formAddTags',
						{},
						{},
						{
							menuId: 'popup_menu',
							menuModuleId: _moduleId,
							key: 'mnuAddSettingTags',
						},
					);
					if (componentClass && _authToken) {
						const modalshow = await frameAction.showModalAsync({
							headerProps: {
								title: 'Buat Tag Baru',
								icon: 'FaUserPlus',
							},
							contentClass: componentClass,
							contentProps: {
								_authToken,
								uiMode: 'add',
							},
							size: 'large',
						});
					}
				}, []);

				const ActionButtons = React.useCallback(clearFilter => {
					return [
						{
							type: 'bordered',
							name: 'Reload',
							onClick: () => clearFilter(),
							render: () => <FaRetweet />,
						},
					];
				}, []);

				return (
					<>
						<PageTitle title="Tags" subtitle="Pengaturan" style={{ marginBottom: `1.5rem` }}>
							<Button onClick={handleAddButton}>
								<FaPlusCircle /> Buat Tag Baru
							</Button>
						</PageTitle>

						<NDataBrowser
							stdAppAction={appAction}
							moduleId={_moduleId}
							dataId="ListTag"
							dataProvider={dsetProvider}
							authToken={props._authToken}
							dsetPath="main"
							filterComponent={({ sortFields, clearFilter, changeParams, params }) => {
								return (
									<Filter>
										<Dropdown
											label="Sort and search: "
											onChange={e =>
												changeParams({
													sortField: e.target.value,
												})
											}
											item={sortFields}
											value={params.sortField}
										/>

										<SearchBox
											style={{
												marginLeft: 5,
											}}
											handleSearch={src => changeParams({ src })}
											srcText={params.src}
										/>
										<FilterActionButtons buttons={ActionButtons(clearFilter)} />
									</Filter>
								);
							}}
							popupComponent={props => <state.popupComponent {...props} />}
						/>
					</>
				);
			}

			return React.memo(AppForm);
		}

		return { componentFactory };
	}

	return async (aReact, globals) => {
		return await getImports(aReact, globals);
	};

	// })()  //--DAFReact: deploy
} //--DAFReact: development
