/*
  DAFReact framework module - Application form (hook)
  appname: cMSUserGroup
  filename: cMSUserGroupList.js
  moduleid: cMSUserGroupList
  author: IK
*/

// 'use strict';

export function ModuleDefinition() {
	//--DAFReact: development

	// (function () { //--DAFReact: deploy

	async function getImports(React, globals) {
		const {
			_moduleId,
			StdAppAction,
			AppFrameAction,
			appAction,
			jsdset,
			dswidget,
			NDataBrowser,
			frameAction,
			staticComponents: {
				Button,
				SearchBox,
				PageTitle,
				Filter,
				FilterActionButtons,
				Dropdown,
				RadioGroup,
				ReactIconFa: { FaPlusCircle, FaRetweet },
				ReactIconSi: { SiMicrosoftexcel },
				AlertComponent,
			},
		} = globals;
		if (
			!_moduleId ||
			!StdAppAction ||
			!AppFrameAction ||
			!appAction ||
			!jsdset ||
			!dswidget ||
			!NDataBrowser
		) {
			throw new Error(
				'One of required components (_moduleId, StdAppAction, AppFrameAction, appAction, jsdset, dswidget, _moduleId) not found in globals',
			);
		}

		const { editUIData } = await appAction.fetchAndExecModule(
			'settings.cMSUserGroupMetadata',
		);

		function componentFactory(params) {
			function AppForm(props) {
				const [state, setState] = React.useState({
					popupComponent: undefined,
				});

				const dsetProvider = React.useMemo(() => {
					return jsdset.dsetEmptyProvider();
				}, []);

				const initialFilter = {
					start_date: new Date(),
					end_date: new Date(),
				};
				const [filter, setFilter] = React.useState(initialFilter);

				React.useEffect(() => {
					(async function () {
						const popupComponent = await appAction.fetchMenu(
							_moduleId,
							'popup_menu',
							props._authToken,
						);
						setState({ ...state, popupComponent });
					})();
				}, [props._authToken]);

				// set variable to store reload function from table TODO: check if this approach is good
				var loadFunction;

				const handleAddButton = React.useCallback(async () => {
					const { _authToken, componentClass } =
						await appAction.fetchFrameComponentWithToken(
							'settings.cMSUserGroupEditNew',
							{ corporate_code: props.corporate_code },
							{},
							{
								menuId: 'popup_menu',
								menuModuleId: _moduleId,
								key: 'mnuAddCMSUserGroup',
							},
						);

					if (componentClass && _authToken)
						await frameAction.showModalAsync({
							headerProps: {
								title: 'Tambah Kelompok Pengguna',
								icon: 'FaUserPlus',
							},
							contentClass: componentClass,
							contentProps: {
								_authToken,
								uiMode: 'add',
								loadFunction,
								corporate_code: props.corporate_code,
							},
							size: 'small',
						});
				}, []);

				const ActionButtons = React.useCallback(
					({ clearFilter, params }) => {
					return [
						{
							type: 'bordered',
							name: 'Import Excel',
							onClick: async () => {
								try {
									await appAction.postData(
										_moduleId,
										'importList',
										props._authToken,
										{ ...params, ...filter },
									);
								} catch (err){
									AlertHandler(err.message, 'alert')
								}
							},
							render: () => <SiMicrosoftexcel />,
						},
						{
							type: 'bordered',
							name: 'Reload',
							onClick: () => {
								setFilter(initialFilter);
								clearFilter();
							},
							render: () => <FaRetweet />,
						},
					];
				});

				const AlertHandler = async (msg, type) => {
					await frameAction.showModal({
						contentClass: props => (
							<AlertComponent
								{...props}
								title={msg}
								type={type}
							/>
						),
						size: 'small',
					});
				}

				const confirmComponent = ({ message, closeModal }) => {
					return (
						<div>
							<div
								style={{
									paddingTop: '6rem',
									paddingBottom: '6rem',
									marginBottom: 15,
									fontSize: '1.2rem',
								}}
							>
								{message}
							</div>
							<div
								style={{
									display: 'flex',
									justifyContent: 'flex-end',
									marginTop: 30,
								}}
							>
								<Button
									type="bordered"
									style={{
										marginRight: 10,
									}}
									onClick={() => closeModal(false)}
								>
									Batal
								</Button>
								<Button onClick={() => closeModal(true)}>
									Proses
								</Button>
							</div>
						</div>
					);
				};

				return (
					<>
						<PageTitle
							title="Kelompok Pengguna"
							subtitle="Pengaturan"
						>
							<Button onClick={handleAddButton}>
								<FaPlusCircle /> Buat Group Baru
							</Button>
						</PageTitle>

						<NDataBrowser
							stdAppAction={appAction}
							moduleId={_moduleId}
							dataId="listSO"
							dataProvider={dsetProvider}
							authToken={props._authToken}
							dsetPath="main"
							uiData={editUIData}
							filterComponent={({
								sortFields,
								clearFilter,
								changeParams,
								params,
							}) => {
								return (
									<Filter>
										<RadioGroup
											data={Object.entries({
												'': 'Semua',
												A: 'Aktif',
												S: 'Suspend',
												D: 'Nonaktif',
											})}
											name={'status'}
											currentValue={params.status}
											initialValue={''}
											type={'icon'}
											varian={'bordered'}
											onChange={e => {
												changeParams({
													status: e.target.value,
												});
											}}
										/>
										<Dropdown
											label="Urutkan dan Cari:"
											onChange={e =>
												changeParams({
													sortField: e.target.value,
												})
											}
											item={sortFields}
											value={params.sortField}
										/>
										<SearchBox
											style={{
												marginLeft: 5,
											}}
											handleSearch={src =>
												changeParams({ src })
											}
											srcText={params.src}
										/>
										<FilterActionButtons
											buttons={ActionButtons({
												clearFilter,
												params,
											})}
										/>
									</Filter>
								);
							}}
							popupComponent={props => (
								<state.popupComponent
									{...props}
									confirmComponent={confirmComponent}
								/>
							)}
						/>
					</>
				);
			}

			return React.memo(AppForm);
		}

		return { componentFactory };
	}

	return async (aReact, globals) => {
		return await getImports(aReact, globals);
	};

	// })()  //--DAFReact: deploy
} //--DAFReact: development
