/*
  DAFReact framework module - Application form (hook)
  appname: auth_ControlHist
  filename: auth_ControlHistEdit.js
  moduleid: auth_ControlHistEdit
  author: IK
*/

import React from 'react'; //--DAFReact: development

// 'use strict'; //--DAFReact: deploy

export function ModuleDefinition() { //--DAFReact: development

  // (function () { //--DAFReact: deploy

  async function getImports(React, globals) {

    // checking required libraries, may also include asynchronously loading other remote module, using appAction.loadModule
    const { _moduleId, StdAppAction, appAction, jsdset, dswidget, frameAction, ndswidget,
      dswidgetex,
      DataBrowser,
      staticComponents: {
        RadioGroup,
        Button,
        FormLabel,
        RadioIcon,
        ReactIconFa: { FaSearch, FaCheck },
      }, } = globals
    if (!_moduleId || !StdAppAction || !appAction || !jsdset || !dswidget) {
      throw new Error('One of required components (_moduleId, StdAppAction, appAction, jsdset, dswidget) not found in globals')
    }

    /* other required modules are asynchronously loaded here */
    const { metadata, initialData, serverDataMapping, editUIData } = await appAction.fetchAndExecModule('auth_control.auth_ControlHistMetadata')

    const { PanelDataDisplay, PanelButton } = dswidget;
    const { FieldDataDisplay, FieldDataInput } = ndswidget;
    const { DateFieldDataInput } = dswidgetex;

    function componentFactory(params) {

      function AppForm(props) {

        const dataContext = React.useMemo(() => jsdset.dsetCreateContext(), [])
        const DSetProvider = React.useMemo(() => jsdset.dsetMetaProvider(dataContext, metadata, initialData, editUIData), [])
        return <DSetProvider>
          <AppFormUI dataContext={dataContext} {...props} /> {/* any other props will be passed down */}
        </DSetProvider>
      }

      function AppFormUI(props) {

        const [state, setState] = React.useState({ isErr: false, errMessage: '', isEditing: false })

        // bind controls to _moduleId and _authToken
        const vComps = React.useMemo(
          () => appAction.connect({ PanelDataDisplay, FieldDataDisplay, PanelButton, FieldDataInput }, { _moduleId, _getToken: () => props._authToken }),
          []
        )

        // bind controls to datasets
        const [mainComps] = React.useMemo(
          () => [
            jsdset.connect({ context: props.dataContext, dsetPath: 'main' }, vComps),
          ],
          [props.dataContext, vComps]
        )

        // obtain action objects from data context
        const [, dsMainAction, dsMainProxy] = jsdset.useDSetContext(props.dataContext, 'main')

        // load data function
        const loadData = React.useCallback(async () => {
          setState((state) => ({ ...state, isErr: false, errMessage: '' }))

          try {
            const response = await appAction.fetchResource(_moduleId, 'single_data', 'dataSO', props._authToken, { id_auth: props.id_auth }, true)
            dsMainAction.loadStore(response, 'std', serverDataMapping, true)
            dsMainAction.recalcFormulas()
          }
          catch (err) {
            setState((prevState) => ({ ...prevState, isErr: true, errMessage: err.message }))
            return
          }
        }, [dsMainAction, props._authToken, props.id_auth])

        // set event on component mounting
        React.useEffect(() => {
          (async function () {
            if (props.uiMode == 'edit')
              await loadData()
            else
              dsMainAction.addRow({})
          })()
        }, [dsMainAction, loadData, props.uiMode])

        // UI event handlers
        const switchEditClick = () => {
          //
          setState((prevState) => ({ ...prevState, isEditing: !prevState.isEditing }))
        }

        const saveDataClick = async () => {
          try {
            var dataUnload = dsMainProxy.unloadStore(serverDataMapping, { includeLoadedRows: false, includeDeletedRows: true })
            await appAction.postData(_moduleId, 'saveData', props._authToken, dataUnload)
            appAction.frameAction.closeModal()
          }
          catch (err) {
            await appAction.frameAction.showMessage(err.message, 'Error save data', { messageType: 'error' })
          }
        }

        // render
        return (
          <div>
            <br />
            {/* lookup behavior of customer no is automatically set based on uiData */}
            <FormLabel label="ref_number">  <mainComps.FieldDataInput fieldName="ref_number" /> </FormLabel>
            <FormLabel label="data_name"> <mainComps.FieldDataInput fieldName="data_name" /> </FormLabel>
            <FormLabel label="auth_class">  <mainComps.FieldDataInput fieldName="auth_class" /> </FormLabel>
            <FormLabel label="operation_id">  <mainComps.FieldDataInput fieldName="operation_id" /> </FormLabel>
            <FormLabel label="operation_name">  <mainComps.FieldDataInput fieldName="operation_name" /> </FormLabel>
            <FormLabel label="description">  <mainComps.FieldDataInput fieldName="description" /> </FormLabel>
            <FormLabel label="entry_user_id">  <mainComps.FieldDataInput fieldName="entry_user_id" /> </FormLabel>
            <FormLabel label="entry_terminal_id">  <mainComps.FieldDataInput fieldName="entry_terminal_id" /> </FormLabel>
            <FormLabel label="entry_datetime">  <mainComps.FieldDataInput fieldName="entry_datetime" /> </FormLabel>
            <FormLabel label="auth_user_id">  <mainComps.FieldDataInput fieldName="auth_user_id" /> </FormLabel>
            <FormLabel label="auth_terminal_id">  <mainComps.FieldDataInput fieldName="auth_terminal_id" /> </FormLabel>
            <FormLabel label="auth_datetime">  <mainComps.FieldDataInput fieldName="auth_datetime" /> </FormLabel>
            <FormLabel label="authorized_flag">  <mainComps.FieldDataInput fieldName="authorized_flag" /> </FormLabel>
            <FormLabel label="otp_ref_number">  <mainComps.FieldDataInput fieldName="otp_ref_number" /> </FormLabel>
            <br />
            <button onClick={saveDataClick}>Save</button>
            <div style={{ display: state.isErr ? "block" : "none" }}>{state.errMessage}</div>
          </div>
        )
      }

      // return value may be different depending on params
      return React.memo(AppForm)
    }

    return { componentFactory }
  }

  async function initModuleF(aReact, globals) {
    return await getImports(aReact, globals)
  }

  return initModuleF
  // })()  //--DAFReact: deploy

} //--DAFReact: development

