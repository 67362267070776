/*
  DAFReact framework module - Application form (hook)
  appname: digitalTransactionCorp
  filename: digitalTransactionCorpEdit.js
  moduleid: digitalTransactionCorpEdit
  author: IK
*/

import React from 'react'; //--DAFReact: development

// 'use strict'; //--DAFReact: deploy

export function ModuleDefinition () { //--DAFReact: development

// (function () { //--DAFReact: deploy

  async function getImports (React, globals) {

    // checking required libraries, may also include asynchronously loading other remote module, using appAction.loadModule
    const { _moduleId, StdAppAction, appAction, jsdset, dswidget,ndswidget } = globals
    if ( !_moduleId || !StdAppAction || !appAction || !jsdset || !dswidget) {
      throw new Error('One of required components (_moduleId, StdAppAction, appAction, jsdset, dswidget) not found in globals')
    }

    /* other required modules are asynchronously loaded here */
    const { metadata, initialData, serverDataMapping, editUIData } = await appAction.fetchAndExecModule('monitoring_corp.digitalTransactionCorpMetadata')

    const {PanelDataDisplay, PanelButton} = dswidget
    const {FieldDataDisplay, FieldDataInput} = ndswidget
    
    function componentFactory (params) {

      function AppForm (props) {

        const dataContext = React.useMemo(() => jsdset.dsetCreateContext(), [])
        const DSetProvider = React.useMemo(() => jsdset.dsetMetaProvider(dataContext, metadata, initialData, editUIData), [])
        return <DSetProvider>
          <AppFormUI dataContext={dataContext} {...props}/> {/* any other props will be passed down */}
        </DSetProvider>
      }

      function AppFormUI (props) {

        const [state, setState] = React.useState({isErr: false, errMessage: '', isEditing: false})

        // bind controls to _moduleId and _authToken
        const vComps = React.useMemo(
          () => appAction.connect({PanelDataDisplay, FieldDataDisplay, PanelButton, FieldDataInput}, { _moduleId, _getToken: () => props._authToken }), 
          []
        )

        // bind controls to datasets
        const [mainComps] = React.useMemo(
          () => [
            jsdset.connect({context: props.dataContext, dsetPath: 'main'}, vComps),
          ],
          [props.dataContext, vComps]
        )

        // obtain action objects from data context
        const [, dsMainAction, dsMainProxy] = jsdset.useDSetContext(props.dataContext, 'main')

        // load data function
        const loadData = React.useCallback(async () => {
          setState((state) => ({...state, isErr: false, errMessage: ''}))
      
          try {
            const response = await appAction.fetchResource(_moduleId, 'single_data', 'dataSO', props._authToken, {id_transaction: props.id_transaction}, true)
            dsMainAction.loadStore(response, 'std', serverDataMapping, true)
            dsMainAction.recalcFormulas()
          }
          catch(err) {
            setState((prevState) => ({...prevState, isErr: true, errMessage: err.message}))
            return
          }    
        }, [dsMainAction, props._authToken, props.id_transaction])

        // set event on component mounting
        React.useEffect(() => {
          (async function () {
            if (props.uiMode == 'edit')
              await loadData()
            else
              dsMainAction.addRow({})
          })()
        }, [dsMainAction, loadData, props.uiMode])

        // UI event handlers
        const switchEditClick = () => {
          //
          setState((prevState) => ({...prevState, isEditing: !prevState.isEditing}))
        }

        const saveDataClick = async () => {
          try {
            var dataUnload = dsMainProxy.unloadStore(serverDataMapping, { includeLoadedRows: false, includeDeletedRows: true })
            await appAction.postData(_moduleId, 'saveData', props._authToken, dataUnload)
            appAction.frameAction.closeModal()
          }
          catch(err) {
            await appAction.frameAction.showMessage(err.message, 'Error save data', {messageType: 'error'})
          }
        }

        // render
        return (
          <div>
            <br />
            {/* lookup behavior of customer no is automatically set based on uiData */}
            id_transaction <mainComps.FieldDataInput fieldName="id_transaction" /><br />
            corporate_code <mainComps.FieldDataInput fieldName="corporate_code" /><br />
            ref_number <mainComps.FieldDataInput fieldName="ref_number" /><br />
            inquiry_ref_number <mainComps.FieldDataInput fieldName="inquiry_ref_number" /><br />
            account_no <mainComps.FieldDataInput fieldName="account_no" /><br />
            virtual_acc_code <mainComps.FieldDataInput fieldName="virtual_acc_code" /><br />
            channel_typ <mainComps.FieldDataInput fieldName="channel_typ" /><br />
            exec_status <mainComps.FieldDataInput fieldName="exec_status" /><br />
            issuer_ref <mainComps.FieldDataInput fieldName="issuer_ref" /><br />
            issuer_status_code <mainComps.FieldDataInput fieldName="issuer_status_code" /><br />
            user_id <mainComps.FieldDataInput fieldName="user_id" /><br />
            terminal_id <mainComps.FieldDataInput fieldName="terminal_id" /><br />
            tx_date <mainComps.FieldDataInput fieldName="tx_date" /><br />
            tx_timestamp <mainComps.FieldDataInput fieldName="tx_timestamp" /><br />
            tx_type <mainComps.FieldDataInput fieldName="tx_type" /><br />
            tx_code <mainComps.FieldDataInput fieldName="tx_code" /><br />
            amount_net <mainComps.FieldDataInput fieldName="amount_net" /><br />
            amount <mainComps.FieldDataInput fieldName="amount" /><br />
            amount_ex <mainComps.FieldDataInput fieldName="amount_ex" /><br />
            amount_fee <mainComps.FieldDataInput fieldName="amount_fee" /><br />
            redeem_point_code <mainComps.FieldDataInput fieldName="redeem_point_code" /><br />
            redeem_point <mainComps.FieldDataInput fieldName="redeem_point" /><br />
            promotion_code <mainComps.FieldDataInput fieldName="promotion_code" /><br />
            service_ref <mainComps.FieldDataInput fieldName="service_ref" /><br />
            product_code <mainComps.FieldDataInput fieldName="product_code" /><br />
            credit_account_no <mainComps.FieldDataInput fieldName="credit_account_no" /><br />
            credit_account_name <mainComps.FieldDataInput fieldName="credit_account_name" /><br />
            credit_status <mainComps.FieldDataInput fieldName="credit_status" /><br />
            credit_status_code <mainComps.FieldDataInput fieldName="credit_status_code" /><br />
            balance_before <mainComps.FieldDataInput fieldName="balance_before" /><br />
            balance_after <mainComps.FieldDataInput fieldName="balance_after" /><br />
            tx_mode <mainComps.FieldDataInput fieldName="tx_mode" /><br />
            vaccount_no <mainComps.FieldDataInput fieldName="vaccount_no" /><br />
            tx_va_refnumber <mainComps.FieldDataInput fieldName="tx_va_refnumber" /><br />
            billing_id <mainComps.FieldDataInput fieldName="billing_id" /><br />
            <br />
            <button onClick={saveDataClick}>Save</button>
            <div style={{display: state.isErr ? "block" : "none"}}>{state.errMessage}</div>
          </div>
        )
      }
      
      // return value may be different depending on params
      return React.memo(AppForm)
    }

    return { componentFactory }
  }

  async function initModuleF(aReact, globals) {
    return await getImports(aReact, globals)
  }

  return initModuleF
// })()  //--DAFReact: deploy

} //--DAFReact: development

