/*
	DAFReact framework module - Application form (hook)
	appname: auth_Control
	filename: auth_ControlEdit.js
	moduleid: auth_ControlEdit
	author: IK
*/

import React from 'react'; //--DAFReact: development

// 'use strict'; //--DAFReact: deploy

export function ModuleDefinition() {
	//--DAFReact: development

	// (function () { //--DAFReact: deploy

	async function getImports(React, globals) {
		// checking required libraries, may also include asynchronously loading other remote module, using appAction.loadModule
		const {
			_moduleId,
			StdAppAction,
			appAction,
			jsdset,
			dswidget,
			frameAction,
			ndswidget,
			dswidgetex,
			DataBrowser,
			staticComponents: {
				RadioGroup,
				Button,
				FormLabel,
				RadioIcon,
				DatePicker,
				ReactIconFa: { FaSearch, FaCheck },
				AlertComponent
			},
		} = globals;
		if (!_moduleId || !StdAppAction || !appAction || !jsdset || !dswidget) {
			throw new Error(
				'One of required components (_moduleId, StdAppAction, appAction, jsdset, dswidget) not found in globals',
			);
		}

		/* other required modules are asynchronously loaded here */
		const { metadata, initialData, serverDataMapping, editUIData } =
			await appAction.fetchAndExecModule(
				'auth_control.auth_ControlMetadata',
			);

		const { PanelDataDisplay, PanelButton } = dswidget;
		const { FieldDataDisplay, FieldDataInput } = ndswidget;
		const { DateFieldDataInput } = dswidgetex;

		function componentFactory(params) {
			function AppForm(props) {
				const dataContext = React.useMemo(
					() => jsdset.dsetCreateContext(),
					[],
				);
				const DSetProvider = React.useMemo(
					() =>
						jsdset.dsetMetaProvider(
							dataContext,
							metadata,
							initialData,
							editUIData,
						),
					[],
				);
				return (
					<DSetProvider>
						<AppFormUI dataContext={dataContext} {...props} />
						{/* any other props will be passed down */}
					</DSetProvider>
				);
			}

			function AppFormUI(props) {
				const [state, setState] = React.useState({
					isErr: false,
					errMessage: '',
					isEditing: false,
					detail: [],
					op: '-'
				});

				// bind controls to _moduleId and _authToken
				const vComps = React.useMemo(
					() =>
						appAction.connect(
							{
								PanelDataDisplay,
								FieldDataDisplay,
								PanelButton,
								FieldDataInput,
							},
							{ _moduleId, _getToken: () => props._authToken },
						),
					[],
				);

				// bind controls to datasets
				const [mainComps] = React.useMemo(
					() => [
						jsdset.connect(
							{ context: props.dataContext, dsetPath: 'main' },
							vComps,
						),
					],
					[props.dataContext, vComps],
				);

				// obtain action objects from data context
				const [, dsMainAction, dsMainProxy] = jsdset.useDSetContext(
					props.dataContext,
					'main',
				);

				// load data function
				const loadData = React.useCallback(async () => {
					setState(state => ({
						...state,
						isErr: false,
						errMessage: '',
					}));

					try {
						const response = await appAction.fetchResource(
							_moduleId,
							'method',
							'getAuthDet',
							props._authToken,
							{ id_auth: props.id_auth },
							true,
						);

						if (response?.data[0].details.length > 0) {
							setState({
								...state,
								detail: response?.data[0].details,
								op: response?.data[0].operation_name
							})
						} else {
							appAction.frameAction.closeModal()
							throw new Error(
								'Data Tidak Dapat Diproses',
							);
						}


						dsMainAction.loadStore(
							response,
							'std',
							serverDataMapping,
							true,
						);

						dsMainAction.recalcFormulas();
					} catch (err) {
						setState(prevState => ({
							...prevState,
							isErr: true,
							errMessage: err.message,
						}));
						appAction.frameAction.closeModal();
						AlertHandler(
							err.message,
							'error',
							''
						);
						return;
					}
				}, [dsMainAction, props._authToken, props.id_auth]);

				// set event on component mounting
				React.useEffect(() => {
					(async function () {
						if (props.uiMode == 'edit') await loadData();
						else dsMainAction.addRow({});
					})();
				}, [dsMainAction, loadData, props.uiMode]);


				const Konfirmasi = async process => {
					await frameAction.showModal({
						contentClass: p => (
							<div>
								<div
									style={{
										paddingTop: '6rem',
										paddingBottom: '6rem',
										marginBottom: 15,
										fontSize: '1.2rem',
									}}
								>
									{p.message}
								</div>
								<div
									style={{
										display: 'flex',
										justifyContent: 'flex-end',
										marginTop: 30,
									}}
								>
									<Button
										type="bordered"
										style={{
											marginRight: 10,
										}}
										onClick={() => {
											p.closeModal();
										}}
									>
										Batal
									</Button>
									<Button onClick={() => {
										p.closeModal();
										p.saveData(p.process)
									}}>
										Proses
									</Button>
								</div>
							</div>
						),
						headerProps: {
							title: 'Konfirmasi Otorisasi',
							icon: 'FaCheck',
						},
						contentProps: {
							message: 'Apakah anda yakin akan ' + (process ? 'meng-otorisasi' : 'menolak otorisasi') + ' transaksi ini?',
							saveData: saveData,
							process: process
						},
						size: 'small',
					});


				}

				const saveData = async process => {

					try {
						var response = await appAction.postData(
							_moduleId,
							'authorize',
							props._authToken,
							{
								id_auth: props.id_auth,
								process,
							},
						);
						if (response) {
							props.closeModal(true);
							AlertHandler(
								'Data berhasil disimpan',
								'success',
								'Silahkan Refresh data',
							);
						}
					} catch (e) {
						AlertHandler(e.message, 'alert');
					}
					// } else props.closeModal();
				};

				const AlertHandler = async (msg, type, body) => {
					await frameAction.showModal({
						contentClass: props => (
							<AlertComponent
								{...props}
								title={msg}
								type={type}
								body={body}
							/>
						),
						size: 'small',
					});
				};


				const Capital = (text) => {
					text = text.replace('_', ' ')
					if (text) {
						return text
							.toString()
							.toLowerCase()
							.replace(/\b[a-z]/g, function (letter) {
								return letter.toUpperCase();
							});
					} else {
						return text;
					}
				}

				const HandleDetail = ({ data }) => {
					let datas = (Object.entries(data))

					console.log(datas)

					return (
						<div>
							{
								datas.map((item, index) => {
									// console.log(item[1])
									if (item[0] !== 'id_auth') {
										return (
											<FormLabel key={index} label={Capital(item[0]).replace('_', ' ')}>
												<b>
													{item[1] !== '' && item[1] !== null ? item[1] : '-'}
												</b>
											</FormLabel>
										)
									} else {
										return null
									}
								})
							}
						</div>
					)
				}

				// render
				return (
					<div>
						<div
							style={{
								display: 'flex',
								width: '-webkit-fill-available',
							}}
						>
							<div
								style={{
									marginRight: 10,
									width: '50%',
									wordWrap: 'break-word',
								}}
							>
								<FormLabel label="ID otorisasi">
									<b>
										{dsMainProxy.fields.id_auth && dsMainProxy.fields.id_auth !== '' ? <mainComps.FieldDataDisplay fieldName="id_auth" /> : '-'}
									</b>
								</FormLabel>
								{/* <FormLabel label="Nama Data">
									<b>
										{dsMainProxy.fields.data_name && dsMainProxy.fields.data_name !== '' ? <mainComps.FieldDataDisplay fieldName="data_name" /> : '-'}
									</b>
								</FormLabel> */}
								<FormLabel label="Nama Operasi">
									<b>
										{dsMainProxy.fields.operation_name && dsMainProxy.fields.operation_name !== '' ? <mainComps.FieldDataDisplay fieldName="operation_name" /> : state.op}
									</b>
								</FormLabel>
								<FormLabel label="Deskripsi">
									<b>
										{dsMainProxy.fields.description && dsMainProxy.fields.description !== '' ? <mainComps.FieldDataDisplay fieldName="description" /> : '-'}
									</b>
								</FormLabel>
								<FormLabel label="ID User Entry">
									<b>
										{dsMainProxy.fields.entry_user_id && dsMainProxy.fields.entry_user_id !== '' ? <mainComps.FieldDataDisplay fieldName="entry_user_id" /> : '-'}
									</b>
								</FormLabel>
								{/* <FormLabel label="User Entry">
									<b>
										<mainComps.FieldDataDisplay fieldName="entry_user_name" />
									</b>
								</FormLabel> */}
								<FormLabel label="Tanggal Entry">
									<b>
										{dsMainProxy.fields.entry_datetime && dsMainProxy.fields.entry_datetime !== '' ? <mainComps.FieldDataDisplay fieldName="entry_datetime" /> : '-'}
									</b>
								</FormLabel>

							</div>
							<div
								style={{
									// marginRight: 10,
									width: '50%',
									wordWrap: 'break-word',
								}}
							>
								{state?.detail?.length > 0 ?
									<HandleDetail data={state?.detail[0]} />
									: null}

							</div>
						</div>
						<br />
						{/* <button onClick={saveDataClick}>Save</button> */}

						<div
							style={{
								display: 'flex',
								justifyContent: 'flex-end',
							}}
						>
							<Button
								onClick={() =>
									appAction.frameAction.closeModal()
								}
								type="bordered"
								style={{
									marginRight: 10,
								}}
							>
								Tutup
							</Button>
							<Button
								onClick={() => Konfirmasi(false)}
								style={{
									marginRight: 10,
								}}
							>
								Tolak Otorisasi
							</Button>
							<Button onClick={() => Konfirmasi(true)}>
								Otorisasi
							</Button>
						</div>

						<div
							style={{ display: state.isErr ? 'block' : 'none' }}
						>
							{state.errMessage}
						</div>
					</div>
				);
			}

			// return value may be different depending on params
			return React.memo(AppForm);
		}

		return { componentFactory };
	}

	async function initModuleF(aReact, globals) {
		return await getImports(aReact, globals);
	}

	return initModuleF;
	// })()  //--DAFReact: deploy
} //--DAFReact: development
