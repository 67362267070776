import React from 'react';
import Button from '../Button/Button';

import {
	FaCheckCircle,
	FaTimesCircle,
	FaExclamationCircle,
	FaExclamationTriangle,
} from 'react-icons/fa';

import './AlertComponent.scss';

const AlertComponent = ({
	title,
	type = 'success',
	closeModal,
	body,
	confirm = false,
}) => {
	const fontSize = 80;

	return (
		<div className="alert-container">
			<h4 className="alert-title">{title}</h4>
			<div className="alert-icon-container">
				{type === 'success' && (
					<FaCheckCircle className={'success-icon'} size={fontSize} />
				)}
				{type === 'alert' && (
					<FaTimesCircle className={'alert-icon'} size={fontSize} />
				)}
				{type === 'info' && (
					<FaExclamationCircle
						className={'info-icon'}
						size={fontSize}
					/>
				)}
				{type === 'warning' && (
					<FaExclamationTriangle
						className={'warning-icon'}
						size={fontSize}
					/>
				)}
			</div>
			<h6 className="alert-body">{body}</h6>
			<div className="alert-button">
				{confirm === true && (
					<Button onClick={() => closeModal(true)}>OK</Button>
				)}
				<Button onClick={() => closeModal()}>Kembali</Button>
			</div>
		</div>
	);
};

export default AlertComponent;
