import React from 'react';
// import './semantic-dist/semantic.min.css';

// internal framework libraries
import { AppFrameProvider, AppModal } from './modules/com.ihsan/appframe_minimal.js';

// application-logic libraries
import { StdAppProvider } from './modules/com.ihsan/appdata.js';
import 'bootstrap/dist/css/bootstrap.css';
import './app_styles/app.scss';
import './rc-menu.css';

// application-UI-pages goes here
import { MainPage } from './app_pages/mainPage.js';
import AppPage from './app_pages/appPage.js';

/**
 * In this FrameProvider, it has it own action reducers,
 * All method list is in appframe_minimal.js at AppFrameAction_Base section
 * context is in appframe_minimal.js
 *
 * In this StdAppProvider, actionObject(processed AppFrameAction_Base) are collected
 */
const FrameProvider = AppFrameProvider({ initialClasses: { AppPage } });
class App extends React.Component {
	render() {
		return (
			<FrameProvider>
				<StdAppProvider>
					<MainPage />
					<AppModal />
				</StdAppProvider>
			</FrameProvider>
		);
	}
}

export default App;
