import React from 'react'; //--DAFReact: development

// 'use strict'; //--DAFReact: deploy

export function ModuleDefinition() {
	//--DAFReact: development

	// (function () { //--DAFReact: deploy

	async function getImports(React, globals) {
		// checking required libraries, may also include asynchronously loading other remote module, using appAction.loadModule
		const {
			_moduleId,
			StdAppAction,
			AppFrameAction,
			appAction,
			jsdset,
			dswidget,
			DataBrowser,
		} = globals;
		if (
			!StdAppAction ||
			!AppFrameAction ||
			!appAction ||
			!jsdset ||
			!dswidget ||
			!DataBrowser
		) {
			throw new Error(
				'One of required components (StdAppAction, AppFrameAction, appAction, jsdset, dswidget) not found in globals',
			);
		}

		if (!_moduleId) {
			throw new Error('_moduleId not defined');
		}

		function componentFactory(params) {
			const metadata = {
				auth_ControlHistCorp: {
					fields: [
						{ name: 'id_auth', type: 'string', title: 'ref_number', length: 10 },
						{ name: 'ref_number', type: 'string', title: 'ref_number', length: 10 },
						{ name: 'data_name', type: 'string', title: 'data_name', length: 20 },
						{ name: 'auth_class', type: 'string', title: 'auth_class', length: 30 },
						{ name: 'operation_id', type: 'string', title: 'operation_id', length: 1 },
						{ name: 'operation_name', type: 'string', title: 'operation_name', length: 50 },
						{ name: 'description', type: 'string', title: 'description', length: 100 },
						{ name: 'entry_user_id', type: 'string', title: 'entry_user_id', length: 20 },
						{ name: 'entry_terminal_id', type: 'string', title: 'entry_terminal_id', length: 32 },
						{ name: 'entry_datetime', type: 'date', title: 'entry_datetime' },
						{ name: 'auth_user_id', type: 'string', title: 'auth_user_id', length: 20 },
						{ name: 'auth_terminal_id', type: 'string', title: 'auth_terminal_id', length: 32 },
						{ name: 'auth_datetime', type: 'date', title: 'auth_datetime' },
						{ name: 'authorized_flag', type: 'string', title: 'authorized_flag', length: 10 },
						{ name: 'otp_ref_number', type: 'string', title: 'otp_ref_number', length: 12 }
					],

					indexes: ['id_auth'],
				},
			};

			const initialData = {
				'main:auth_ControlHistCorp': [],
			};

			const serverDataMapping = {
				data: {
					dset: 'main',
					type: 'auth_ControlHistCorp',
					fieldMapping: {
						'*': true, // allow default fields
					},
				},
			};

			const editUIData = {
				auth_ControlHistCorp: {
					fields: {},
				},
			};

			// return value may be different depending on params
			return { metadata, initialData, serverDataMapping, editUIData };
		}

		return { componentFactory };
	}

	async function initModuleF(aReact, globals) {
		return await getImports(aReact, globals);
	}

	return initModuleF;
	// })()  //--DAFReact: deploy
} //--DAFReact: development
