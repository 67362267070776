import React from 'react'; //--DAFReact: development

// 'use strict'; //--DAFReact: deploy

export function ModuleDefinition() {
	//--DAFReact: development

	// (function () { //--DAFReact: deploy

	async function getImports(React, globals) {
		// checking required libraries, may also include asynchronously loading other remote module, using appAction.loadModule
		const {
			_moduleId,
			StdAppAction,
			AppFrameAction,
			appAction,
			jsdset,
			dswidget,
			DataBrowser,
			staticComponents: {
				Validator: { isExist, isEmail, isPasword, isPhone },
			},
		} = globals;
		if (
			!StdAppAction ||
			!AppFrameAction ||
			!appAction ||
			!jsdset ||
			!dswidget ||
			!DataBrowser
		) {
			throw new Error(
				'One of required components (StdAppAction, AppFrameAction, appAction, jsdset, dswidget) not found in globals',
			);
		}

		if (!_moduleId) {
			throw new Error('_moduleId not defined');
		}

		function componentFactory(params) {
			const metadata = {
				settingTags: {
					fields: [
						{
							name: 'corporate_code',
							type: 'string',
							title: 'Code Corporate',
							length: 10,
						},
						{
							name: 'tag_code',
							type: 'string',
							title: 'Tag Code',
							length: 20,
							validator: (value, fieldName) => {
								let check = isExist(value, 'Tag Code');
								return check;
							},
						},
						{
							name: 'description',
							type: 'string',
							title: 'Description',
							length: 350,
						},
						{
							name: 'tag_class',
							type: 'string',
							title: 'Tag Class',
							validator: (value, fieldName) => {
								return isExist(value, 'Tag Class');;
							},
						},
						
					],

					indexes: ['corporate_code'],
				},
			};

			const initialData = {
				'main:settingTags': [],
			};

			const serverDataMapping = {
				data: {
					dset: 'main',
					type: 'settingTags',
					fieldMapping: {
						'*': true, // allow default fields
					},
				},
			};

			const editUIData = {
				settingTags: {
					fields: {
						tag_class:{
							type: 'icon',
							inputType: 'radio',
							variant: 'bordered',
							dataSets: {
								1: '1',
								2: '2',
								3: '3',
								4: '4',
								5: '5'
							}
						}
					},
				},
			};

			// return value may be different depending on params
			return { metadata, initialData, serverDataMapping, editUIData };
		}

		return { componentFactory };
	}

	async function initModuleF(aReact, globals) {
		return await getImports(aReact, globals);
	}

	return initModuleF;
	// })()  //--DAFReact: deploy
} //--DAFReact: development
