export function ModuleDefinition() {
	async function getImports(React, globals) {
		const {
			_moduleId,
			StdAppAction,
			AppFrameAction,
			appAction,
			jsdset,
			dswidget,
			DataBrowser,
			staticComponents: {
				Validator: { isExist, isEmail, isPasword, isPhone },
			},
		} = globals;
		if (!StdAppAction || !AppFrameAction || !appAction || !jsdset || !dswidget || !DataBrowser) {
			throw new Error('One of required components (StdAppAction, AppFrameAction, appAction, jsdset, dswidget) not found in globals');
		}

		if (!_moduleId) {
			throw new Error('_moduleId not defined');
		}

		function componentFactory(params) {
			const metadata = {
				main: {
					fields: [
						{
							name: 'id',
							type: 'string',},
						{
							name: 'main_account',
							type: 'string',
							validator: (value, fieldName) => {
								return isExist(value, 'Nomor Akun');;
							},
						},
						{
							name: 'main_acc_name',
							type: 'string',
						},
						{
							name: 'second_account',
							type: 'string',
							validator: (value, fieldName) => {
								return isExist(value, 'Akun tujuan / sumber');;
							},
						},
						{
							name: 'second_acc_name',
							type: 'string',
						},
						{
							name: 'thresh_max',
							type: 'int',
							validator: (value, fieldName) => {
								return isExist(value, 'Batas Maksimum');;
							},
						},
						{
							name: 'thresh_min',
							type: 'int',
							validator: (value, fieldName) => {
								return isExist(value, 'Batas Minimun');;
							},
						},
						{
							name: 'sweep_type',
							type: 'string',
							validator: (value, fieldName) => {
								return isExist(value, 'Jenis Autosweep');;
							},
						},
						{
							name: 'type',
							type: 'string',
							validator: (value, fieldName) => {
								return isExist(value, 'Type Autosweep');;
							},
						},
					],

					indexes: ['main_account'],
				},
			};

			const initialData = {
				'main:main': [],
			};

			const serverDataMapping = {
				data: {
					dset: 'main',
					type: 'main',
					fieldMapping: {
						'*': true,
					},
				},
			};

			const editUIData = {
				main: {
					fields: {
						main_account: {
							readOnly: true,
							lookup: {
								style: {
									idleCheck: false,
									input: true,
									button: true,
								},
								dataId: 'getRekeningSumber',
								selFields: ['account_no', 'account_name', 'currency'],
								fieldMap: {
									main_account: 'account_no',
									main_acc_name: 'account_name',
									currency: 'currency',
								},
								apiParameterF: (fields, isValidation = false) => {
									return {};
								},
							},
						},
						second_account: {
							readOnly: true,
							lookup: {
								style: {
									idleCheck: false,
									input: true,
									button: true,
								},
								dataId: 'getRekeningTujuan',
								selFields: ['account_no', 'account_name', 'currency'],
								fieldMap: {
									second_account: 'account_no',
									second_acc_name: 'account_name',
									currency: 'currency',
								},
								apiParameterF: (fields, isValidation = false) => {
									return {
										searchKey: fields.second_account,
										notaccount: fields.main_account,
									};
								},
							},
						},
						thresh_max: {
							numformatted: true,
							decimal: 2,
						},
						thresh_min: {
							numformatted: true,
							decimal: 2,
						},
						sweep_type: {
							type: 'icon',
							inputType: 'radio',
							variant: 'bordered',
							dataSets: {
								'MIN': 'MIN',
								'MAX': 'MAX',
							},
						},
						type: {
							type: 'icon',
							inputType: 'radio',
							variant: 'bordered',
							dataSets: {
								'AUTOFILL': 'AUTOFILL',
								'AUTOSWEEP': 'AUTOSWEEP',
							},
						},
					},
				},
			};

			return { metadata, initialData, serverDataMapping, editUIData };
		}

		return { componentFactory };
	}

	async function initModuleF(aReact, globals) {
		return await getImports(aReact, globals);
	}

	return initModuleF;
}
