import React from 'react'; //--DAFReact: development

// 'use strict'; //--DAFReact: deploy

export function ModuleDefinition() {
	//--DAFReact: development

	// (function () { //--DAFReact: deploy

	async function getImports(React, globals) {
		// checking required libraries, may also include asynchronously loading other remote module, using appAction.loadModule
		const {
			_moduleId,
			StdAppAction,
			AppFrameAction,
			appAction,
			jsdset,
			dswidget,
			DataBrowser,
			staticComponents: {
				Validator: { isExist },
			},
		} = globals;
		if (
			!StdAppAction ||
			!AppFrameAction ||
			!appAction ||
			!jsdset ||
			!dswidget ||
			!DataBrowser
		) {
			throw new Error(
				'One of required components (StdAppAction, AppFrameAction, appAction, jsdset, dswidget) not found in globals',
			);
		}

		if (!_moduleId) {
			throw new Error('_moduleId not defined');
		}

		function componentFactory(params) {
			const metadata = {
				cMSAccountAccess: {
					fields: [
						{
							name: 'corporate_code',
							type: 'string',
							title: 'corporate_code',
							length: 10,
						},
						{
							name: 'group_id',
							type: 'string',
							title: 'group_id',
							length: 15,
							validator: (value, fieldName) => {
								if (!value || value === "") {
									return [false, 'Group ID tidak boleh kosong'];
								} else {
									var passRegx = /^\S*$/;
									return value.match(passRegx) ? [true, ''] : [false, 'Group ID tidak boleh menggunakan spasi'];
								}
							},
						},
						{
							name: 'group_name',
							type: 'string',
							title: 'group_name',
							length: 20,
						},
						{
							name: 'account_no',
							type: 'string',
							title: 'account_no',
							length: 20,
							validator: (value, fieldName) => {
								let check = isExist(
									value,
									'No. Rekening',
									3,
									20,
								);
								return check;
							},
						},
						{
							name: 'account_name',
							type: 'string',
							title: 'account_name',
							length: 20,
						},
						{
							name: 'service_type',
							type: 'string',
							title: 'service_type',
							length: 1,
						},
						{
							name: 'is_transaction',
							type: 'string',
							title: 'is_transaction',
							length: 1,
						},
					],

					indexes: ['corporate_code'],
				},
			};

			const initialData = {
				'main:cMSAccountAccess': [],
			};

			const serverDataMapping = {
				data: {
					dset: 'main',
					type: 'cMSAccountAccess',
					fieldMapping: {
						'*': true, // allow default fields
					},
				},
			};

			const editUIData = {
				cMSAccountAccess: {
					fields: {
						account_no: {
							lookup: {
								style: {
									idleCheck: false,
									input: true,
									button: true,
								},
								dataId: 'dataRekeningKorporat',
								selFields: [
									'account_no',
									'account_name',
									'currency_code',
									'service_type',
								],
								fieldMap: {
									account_no: 'account_no',
									account_name: 'account_name',
									currency_code: 'currency_code',
									service_type: 'service_type',
								},
							},
						},
						is_transaction: {
							inputType: 'radio',
							type: 'icon',
							variant: 'block',
							withborder: true,
							dataSets: {
								T: 'Diizinkan',
								F: 'Tidak Diizinkan',
							},
							initialValue: 'T',
						},
					},
				},
			};

			// return value may be different depending on params
			return { metadata, initialData, serverDataMapping, editUIData };
		}

		return { componentFactory };
	}

	async function initModuleF(aReact, globals) {
		return await getImports(aReact, globals);
	}

	return initModuleF;
	// })()  //--DAFReact: deploy
} //--DAFReact: development
