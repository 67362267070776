export function ModuleDefinition() {
	async function getImports(React, globals) {
		const {
			_moduleId,
			StdAppAction,
			appAction,
			jsdset,
			ndswidget,
			DataBrowser,
			staticComponents: {
				Button,
				FormLabel,
				ReactIconFa: { FaSearch },
				AlertComponent,
				PageTitle,
				Input,
			},
		} = globals;
		if (!StdAppAction || !appAction || !jsdset || !ndswidget || !DataBrowser) {
			throw new Error('One of required components (StdAppAction, AppFrameAction, appAction, jsdset, dswidget) not found in globals');
		}

		if (!_moduleId) {
			throw new Error('_moduleId not defined');
		}

		const { metadata, initialData, serverDataMapping, editUIData } = await appAction.fetchAndExecModule('application.changePasswordMetadata');

		const { FieldDataDisplay, FieldDataInput } = ndswidget;

		function componentFactory(params) {
			function AppForm(props) {
				const dataContext = React.useMemo(() => jsdset.dsetCreateContext(), []);
				const DSetProvider = React.useMemo(() => {
					return jsdset.dsetMetaProvider(dataContext, metadata, initialData, editUIData);
				}, []);
				return (
					<DSetProvider>
						<AppFormUI dataContext={dataContext} {...props} />
					</DSetProvider>
				);
			}

			function AppFormUI(props) {
				const [state, setState] = React.useState({});

				React.useEffect(() => {
					(async function () {
						dsMainAction.addRow({});
					})();
				}, []);

				const vComps = React.useMemo(
					() =>
						appAction.connect(
							{
								FieldDataDisplay,
								FieldDataInput,
							},
							{ _moduleId, _getToken: () => props._authToken },
						),
					[],
				);

				const [mainComps] = React.useMemo(() => [jsdset.connect({ context: props.dataContext, dsetPath: 'main' }, vComps)], [props.dataContext, vComps]);

				const [, dsMainAction, dsMainProxy] = jsdset.useDSetContext(props.dataContext, 'main');

				const handleSubmit = async () => {
					try {
						if (!(await handleValidEntry())) return;

						if (window.confirm('Apakah anda yakin akan mengganti password?')) {
							try {
								await appAction.fetchResource(_moduleId, 'method', 'changePassword', props._authToken, dsMainProxy.fields, true);
								AlertHandler('Proses perubahan password berhasil...', 'success');
							} catch (err) {
								throw new Error('Proses perubahan password gagal...');
							}
						}
					} catch (error) {
						AlertHandler('Proses perubahan password gagal...', 'error');
					}
				};

				const handleValidEntry = async () => {
					try {
						var cstate = await appAction.getState();

						const { fieldValidStates, fieldValidErrors } = dsMainProxy;
						const isInValid = Object.entries(fieldValidStates).filter(([i, v]) => {
							return !v;
						});

						if (isInValid.length > 0) throw new Error(fieldValidErrors[isInValid[0][0]]);

						if (dsMainProxy.fields.old_password !== cstate.globals.password) {
							throw new Error('Kata Sandi Sebelumnya yang Anda masukkan salah ');
						}
						return true;
					} catch (error) {
						AlertHandler(error.message, 'error');
						return false;
					}
				};

				const AlertHandler = async (msg, type) => {
					await appAction.frameAction.showModal({
						contentClass: props => <AlertComponent {...props} title={msg} type={type} />,
						size: 'small',
					});
				};

				return (
					<div className="row">
						<PageTitle subtitle="Application" title="Ubah Password"></PageTitle>
						<div className="col-lg-6">
							<FormLabel label="Kata Sandi Sebelumnya">
								<mainComps.FieldDataInput elProps={{ type: 'password' }} fieldName="old_password" />
							</FormLabel>
							<FormLabel label="Kata Sandi Baru">
								<mainComps.FieldDataInput elProps={{ type: 'password' }} fieldName="new_password" />
							</FormLabel>
							<FormLabel label="Ulangi Kata Sandi Baru">
								<mainComps.FieldDataInput elProps={{ type: 'password' }} fieldName="conf_new_password" />
							</FormLabel>
						</div>

						<br />
						<div
							style={{
								display: 'flex',
								justifyContent: 'flex-start',
							}}
							onClick={() => {
								handleSubmit();
							}}
						>
							<Button>Simpan Perubahan</Button>
						</div>

						<div
							style={{
								display: state.isErr ? 'block' : 'none',
							}}
						>
							{state.errMessage}
						</div>
					</div>
				);
			}
			return React.memo(AppForm);
		}

		return { componentFactory };
	}

	async function initModuleF(aReact, globals) {
		return await getImports(aReact, globals);
	}

	return initModuleF;
}
