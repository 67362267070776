import React from 'react'; //--DAFReact: development

// 'use strict'; //--DAFReact: deploy

export function ModuleDefinition() {
	//--DAFReact: development

	// (function () { //--DAFReact: deploy

	async function getImports(React, globals) {
		// checking required libraries, may also include asynchronously loading other remote module, using appAction.loadModule
		const {
			_moduleId,
			StdAppAction,
			appAction,
			jsdset,
			dswidget,
			ndswidget,
			frameAction,
			staticComponents: { FormLabel, Button, AlertComponent },
		} = globals;
		if (!_moduleId || !StdAppAction || !appAction || !jsdset || !dswidget) {
			throw new Error('One of required components (_moduleId, StdAppAction, appAction, jsdset, dswidget) not found in globals');
		}

		/* other required modules are asynchronously loaded here */
		const { metadata, initialData, serverDataMapping, editUIData } = await appAction.fetchAndExecModule('settings.notifikasi.settingNotificationMetadata');

		const { FieldDataDisplay, FieldDataInput } = ndswidget;

		function componentFactory(params) {
			function AppForm(props) {
				const dataContext = React.useMemo(() => jsdset.dsetCreateContext(), []);
				const DSetProvider = React.useMemo(() => {
					return jsdset.dsetMetaProvider(dataContext, metadata, initialData, editUIData);
				}, []);
				return (
					<DSetProvider>
						<AppFormUI dataContext={dataContext} {...props} />
						{/* any other props will be passed down */}
					</DSetProvider>
				);
			}

			function AppFormUI(props) {
				const [state, setState] = React.useState({
					isErr: false,
					errMessage: '',
					isEditing: false,
				});

				// bind controls to _moduleId and _authToken
				const vComps = React.useMemo(
					() =>
						appAction.connect(
							{
								FieldDataDisplay,
								FieldDataInput,
							},
							{ _moduleId, _getToken: () => props._authToken },
						),
					[],
				);

				// bind controls to datasets
				const [mainComps] = React.useMemo(() => [jsdset.connect({ context: props.dataContext, dsetPath: 'main' }, vComps)], [props.dataContext, vComps]);

				// obtain action objects from data context
				const [, dsMainAction, dsMainProxy] = jsdset.useDSetContext(props.dataContext, 'main');

				// load data function
				const loadData = React.useCallback(async () => {
					setState(state => ({
						...state,
						isErr: false,
						errMessage: '',
					}));

					try {
						const response = await appAction.fetchResource(
							_moduleId,
							'method',
							'dataSO',
							props._authToken,
							{
								id_notif: props.id_notif,
							},
							true,
						);

						console.log(response)
						if (response) {
							await dsMainAction.setFields({
								...response,
								account_no: props.account_no,
								account_name: props.account_name,
							});
						} else {
							appAction.frameAction.closeModal();
							AlertHandler('Data Tidak Bisa di Ubah', 'warning');
						}
					} catch (err) {
						setState(prevState => ({
							...prevState,
							isErr: true,
							errMessage: err.message,
						}));
						return;
					}
				}, [dsMainAction, props._authToken, props.id_notif]);

				// set event on component mounting
				React.useEffect(() => {
					(async function () {
						dsMainAction.addRow({
							account_no: props.account_no,
							account_name: props.account_name,
						});
						if (props.uiMode == 'edit') {
							await loadData();
						}
					})();
				}, [dsMainAction, loadData, props.uiMode]);

				const saveDataClick = async () => {
					try {
						const { fieldValidStates, fieldValidErrors } = dsMainProxy;
						const isInValid = Object.entries(fieldValidStates).filter(([i, v]) => {
							return !v;
						});

						if (isInValid.length > 0) throw new Error(fieldValidErrors[isInValid[0][0]]);

						if ((!dsMainProxy.fields.ch_sms || dsMainProxy.fields.ch_sms === '') && (!dsMainProxy.fields.ch_email || dsMainProxy.fields.ch_email === '')) {
							throw new Error('Alamat Email dan Nomor HP Tidak boleh kosong');
						}

						await appAction.postData(_moduleId, 'saveData', props._authToken, dsMainProxy.fields);
						appAction.frameAction.closeModal();
						AlertHandler('Data berhasil disimpan', 'success');
					} catch (err) {
						AlertHandler(err.message, 'alert');
					}
				};

				const AlertHandler = async (msg, type) => {
					await frameAction.showModal({
						contentClass: props => <AlertComponent {...props} title={msg} type={type} />,
						size: 'small',
					});
				};

				// render
				return (
					<div style={{ padding: '1rem' }}>
						<FormLabel label="No. Rekening">
							<h6>
								<strong>
									{props.account_no}
									<span> - </span>
									{props.account_name}
								</strong>
							</h6>
						</FormLabel>

						<FormLabel label="Kode Transaksi">
							<mainComps.FieldDataInput fieldName="tx_code" />
						</FormLabel>

						<FormLabel label="Nomor Handphone">
							<mainComps.FieldDataInput fieldName="ch_sms" elProps={{ readOnly: true }} />
						</FormLabel>

						{/* <FormLabel label="Alamat Email">
							<mainComps.FieldDataInput fieldName="ch_email" elProps={{ readOnly: true }} />
						</FormLabel> */}

						<FormLabel label="Keterangan Notifiaksi">
							<mainComps.FieldDataInput fieldName="name" />
						</FormLabel>

						<FormLabel label="Minimum Kredit">
							<mainComps.FieldDataInput fieldName="cr_min" />
						</FormLabel>

						<FormLabel label="Minimum Debit">
							<mainComps.FieldDataInput fieldName="db_min" />
						</FormLabel>

						<FormLabel label="Status">
							<mainComps.FieldDataInput fieldName="is_active" />
						</FormLabel>

						<div
							style={{
								display: 'flex',
								justifyContent: 'flex-end',
								paddingTop: 50,
							}}
						>
							<Button
								onClick={() => appAction.frameAction.closeModal()}
								type="bordered"
								style={{
									marginRight: 10,
								}}
							>
								Batalkan
							</Button>
							<Button onClick={saveDataClick}>Simpan Data</Button>
						</div>
						<div style={{ display: state.isErr ? 'block' : 'none' }}>{state.errMessage}</div>
					</div>
				);
			}

			// return value may be different depending on params
			return React.memo(AppForm);
		}

		return { componentFactory };
	}

	async function initModuleF(aReact, globals) {
		return await getImports(aReact, globals);
	}

	return initModuleF;
	// })()  //--DAFReact: deploy
} //--DAFReact: development
