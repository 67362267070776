/*
  DAFReact framework module - Application form (hook)
  appname: corporateAccount
  filename: corporateAccountList.js
  moduleid: corporateAccountList
  author: IK
*/

// 'use strict';

export function ModuleDefinition() {
  //--DAFReact: development

  // (function () { //--DAFReact: deploy

  async function getImports(React, globals) {
    const {
      _moduleId,
      StdAppAction,
      AppFrameAction,
      appAction,
      jsdset,
      dswidget,
      NDataBrowser,
      frameAction,
      staticComponents: {
        FilterActionButtons,
        Button,
        PageTitle,
        SearchBox,
        Filter,
        ReactIconFa,
        ReactIconSi,
        Dropdown,
        RadioGroup, AlertComponent

      },
    } = globals;
    if (
      !_moduleId ||
      !StdAppAction ||
      !AppFrameAction ||
      !appAction ||
      !jsdset ||
      !dswidget ||
      !NDataBrowser
    ) {
      throw new Error(
        'One of required components (_moduleId, StdAppAction, AppFrameAction, appAction, jsdset, dswidget, _moduleId) not found in globals',
      );
    }

    const {
      FaPlusCircle,
      FaRetweet
    } = ReactIconFa;
    const { SiMicrosoftexcel } = ReactIconSi;

    const { metadata, initialData, serverDataMapping, editUIData } =
      await appAction.fetchAndExecModule(
        'corporate.corporateAccountMetadata',
      );

    function componentFactory(params) {
      function AppForm(props) {
        const [state, setState] = React.useState({
          popupComponent: undefined,
          maxRow: 20,
        });

        const dsetProvider = React.useMemo(
          () => jsdset.dsetEmptyProvider(),
          [],
        );

        React.useEffect(() => {
          (async function () {
            const popupComponent = await appAction.fetchMenu(
              _moduleId,
              'popup_menu',
              props._authToken,
            );
            setState({ popupComponent });
          })();
        }, [props._authToken]);


        var loadFunction;

        const handleAddButton = React.useCallback(async () => {
          const { _authToken, componentClass } =
            await appAction.fetchFrameComponentWithToken(
              'corporate.corporateAccountEditNew',
              { corporate_code: props.corporate_code },
              {},
              {
                menuId: 'popup_menu',
                menuModuleId: _moduleId,
                key: 'mnuAddCorporateAccount',
              },
            );

          if (componentClass && _authToken) {
            await frameAction.showModalAsync({
              headerProps: {
                title: 'Buat Rekening Korporat',
                icon: 'FaUserPlus',
              },
              contentClass: componentClass,
              contentProps: {
                _authToken,
                uiMode: 'add',
                corporate_code: props.corporate_code,
                corporate_name: props.corporate_name,
                issuer_id: props.issuer_id,
                primary_cif_no: props.primary_cif_no,
              },
              size: 'large',
            });
            // if (!modalshow) {
            //   loadFunction();
            // }
          }
        }, []);



        const setLoadFunction = React.useCallback(
          e => {
            console.log(e);
            loadFunction = e
          },
          [],
        );

        const AlertHandler = async (msg, type, body) => {
          await frameAction.showModal({
            contentClass: props => (
              <AlertComponent
                {...props}
                title={msg}
                type={type}
                body={body}
              />
            ),
            size: 'small',
          });
        };

        const ACTION_BUTTONS = (clearFilter, params) => {
          return [
            {
              type: 'bordered',
              name: 'Export Excel',
              onClick: async () => {
                try {
                  await appAction.postData(
                    _moduleId,
                    'exportList',
                    props._authToken,
                    {
                      ...params,
                      // ...filter 
                    },
                    {}
                  );

                } catch (error) {
                  AlertHandler(error.message, 'alert');
                }
              },
              render: () => <SiMicrosoftexcel />,
            },
            {
              type: 'bordered',
              name: 'refresh',
              onClick: () => clearFilter(),
              render: () => <FaRetweet />,
            },
          ];
        };

        return (
          <>
            <PageTitle
              subtitle={'Kode Korporat : ' + props.corporate_code}
              title={props.corporate_name}
            >
              <Button
                onClick={handleAddButton}
                style={{
                  margin: 20,
                }}
              >
                <FaPlusCircle /> Buat Rekening Korporat Baru
              </Button>
            </PageTitle>

            <NDataBrowser
              stdAppAction={appAction}
              moduleId={_moduleId}
              dataId='listSO'
              dataProvider={dsetProvider}
              dsetPath="main"
              popupComponent={state.popupComponent}
              authToken={props._authToken}
              params={{ corporate_code: props.corporate_code }}
              uiData={editUIData}
              filterComponent={({
                sortFields,
                changeParams,
                params,
                clearFilter
              }) => {
                // setLoadFunction(loadDataClick)

                return (
                  <Filter>
                    <Dropdown
                      label="Sort and search: "
                      onChange={e =>
                        changeParams({
                          sortField: e.target.value,
                        })
                      }
                      item={sortFields}
                      value={params.sortField}
                    />
                    <SearchBox
                      style={{
                        marginLeft: 5,
                      }}
                      label="Tampilkan"
                      handleSearch={src =>
                        changeParams({ src })
                      }
                      srcText={params.src}
                    />
                    <div style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}>
                      <RadioGroup
                        data={Object.entries({
                          '': 'Semua',
                          T: 'Diizinkan',
                          F: 'Tidak Diizinkan',
                        })}
                        name={'status'}
                        currentValue={params.status}
                        initialValue={''}
                        type={'icon'}
                        varian={'bordered'}
                        onChange={e => {
                          changeParams({
                            status: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <FilterActionButtons
                      buttons={ACTION_BUTTONS(clearFilter, params)}
                    />

                  </Filter>
                )
              }

              }


            />
          </>
        );
      }

      return React.memo(AppForm);
    }

    return { componentFactory };
  }

  return async (aReact, globals) => {
    return await getImports(aReact, globals);
  };

  // })()  //--DAFReact: deploy
} //--DAFReact: development
