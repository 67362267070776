import React, { useEffect } from 'react';
import './Login.scss';
import background from '../../_assets/png/Aksen.png';
import logo from '../../_assets/png/logo-putih.png';
import Button from '../../Button/Button';
import FormLabel from '../../Forms/FormLabel/FormLabel';
import ForgotPass from './ForgoPassword';
import ResetPass from './ResetPassword';
import InputGroup from '../../InputGroupIcon/InputGroupIcon';
import { FaUser, FaUsers, FaLock, FaEnvelope } from 'react-icons/fa';

import { StdAppAction } from '../../../modules/com.ihsan/appdata.js';

import Captcha from 'demos-react-captcha';

const Login = props => {
	const refUserIDInput = React.useRef(null);
	const refPasswordInput = React.useRef(null);
	const refAppInput = React.useRef({ value: props.appInfo || 'admin' });
	const refCorporateInput = React.useRef(null);
	const refStdAppAction = React.useRef(null);
	const refBtnLogin = React.useRef(null);

	const [isLoading, setLoading] = React.useState(process.env.REACT_APP_TEST_PASSWORD || props.appInfo === 'admin' ? false : true);
	const [isForgot, setForgot] = React.useState(false);
	const [isReset, setReset] = React.useState(false);
	const [token, setToken] = React.useState(false);

	useEffect(() => {
		refBtnLogin.current.focus();
		const queryParams = new URLSearchParams(window.location.search);
		if (queryParams.get('redirect_to') && queryParams.get('redirect_to') == 'resetPassword' && queryParams.get('session_id')) {
			setReset(true);
			setToken(queryParams.get('session_id'));
		}
	}, []);

	const loginFunction = React.useCallback(async (geoLocationInfo = {}, captcha = null) => {
		const { longitude = '', latitude = '' } = geoLocationInfo;

		var uid = refUserIDInput.current.value.toLowerCase() + '/' + refCorporateInput?.current?.value?.toUpperCase();

		var loginRsp = await refStdAppAction.current.login(uid, refPasswordInput.current.value, refAppInput.current.value, { longitude, latitude });
		if (loginRsp.isError) setLoading(false);
	}, []);

	const getPosition = () =>
		new Promise(async (res, rej) => {
			navigator.geolocation.getCurrentPosition(
				r => {
					res(r);
				},
				err => {
					rej(err);
				},
			);
		});

	const onButtonClick = async () => {
		setLoading(true);
		try {
			var position = {};
			// if (navigator.geolocation) position = await getPosition();
			// if (!position) throw new Error("Can't get position...");
			loginFunction(position?.coords);
			refStdAppAction.current.setGlobal('password', refPasswordInput.current.value,)
		} catch (error) {
			setLoading(false);
		}
	};

	return (
		<div className="main-wrapper login-container">
			<StdAppAction ref={refStdAppAction} />

			<img src={background} alt="background" className="bg-image" />

			<div className="title-container">
				<img src={logo} alt="logo-bjb" />
				<h2>
					<b>Layanan iB C@sh Maslahah</b>
				</h2>
				<h4>{props.appInfo === 'customer' ? null : <b>Back Office Administrator</b>}</h4>
				<h6>BANK JABAR BANTEN SYARIAH</h6>
			</div>
			<div className="card-container">
				<div className="card">
					{isReset ? (
						<ResetPass {...props} token={token} app_id={refAppInput.current.value} />
					) : !isForgot ? (
						<>
							<center className="title">Login</center>
							<form
								onKeyUp={e => {
									if (e.key == 13) {
										onButtonClick();
									}
								}}
								style={{
									paddingBottom: 10,
								}}
							>
								{props.appInfo !== 'admin' && (
									<center>
										<Captcha
											onChange={e => {
												if (e == true) {
													setLoading(false);
												} else {
													setLoading(true);
												}
											}}
											placeholder="Masukan captcha"
											onRefresh={x => {
												setLoading(true);
											}}
										/>
										<br />
										{isLoading && <p>Captcha belum tepat...</p>}
									</center>
								)}

								{props.appInfo !== 'admin' && (
									<FormLabel label="Kode Korporat">
										<InputGroup
											icon={<FaUsers />}
											onChange={() => {}}
											name="corporate"
											disabled={isLoading}
											id="corporate"
											reference={refCorporateInput}
											onKeyUp={e => {
												if (e.key === 'Enter') refUserIDInput.current.focus();
											}}
											defaultValue={process.env.REACT_APP_TEST_CORPORATE}
										></InputGroup>
									</FormLabel>
								)}
								<FormLabel label="User ID">
									<InputGroup
										icon={<FaUser />}
										onChange={() => {}}
										name="user"
										disabled={isLoading}
										id="user"
										reference={refUserIDInput}
										onKeyUp={e => {
											if (e.key === 'Enter') refPasswordInput.current.focus();
										}}
										defaultValue={process.env.REACT_APP_TEST_USERNAME}
									></InputGroup>
								</FormLabel>
								<FormLabel label="Kata Sandi">
									<InputGroup
										icon={<FaLock />}
										reference={refPasswordInput}
										onChange={() => {}}
										name="psw"
										disabled={isLoading}
										id="psw"
										type="password"
										onKeyUp={e => {
											if (e.key === 'Enter') onButtonClick();
										}}
										defaultValue={process.env.REACT_APP_TEST_PASSWORD}
									></InputGroup>
								</FormLabel>
							</form>

							<Button style={{ width: '100%' }} reference={refBtnLogin} onClick={onButtonClick} disabled={isLoading}>
								Login
							</Button>
							<br />
							<center>
								<a
									style={{ cursor: 'pointer' }}
									href="#"
									onClick={() => {
										setForgot(true);
									}}
								>
									Lupa Password?
								</a>
							</center>
						</>
					) : (
						<ForgotPass {...props} app_id={refAppInput.current.value} />
					)}
					<span
						style={{
							display: props.loginError ? 'block' : 'none',
							color: '#b7282d',
						}}
					>
						{props.loginErrMessage}
					</span>
				</div>
				<span style={{ color: 'white' }}>©2021. Bank Jabar Banten Syariah.</span>
			</div>
		</div>
	);
};

export default Login;
