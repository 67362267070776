import React from 'react'; //--DAFReact: development

// 'use strict'; //--DAFReact: deploy

export function ModuleDefinition() {
	//--DAFReact: development

	// (function () { //--DAFReact: deploy

	async function getImports(React, globals) {
		// checking required libraries, may also include asynchronously loading other remote module, using appAction.loadModule
		const {
			_moduleId,
			StdAppAction,
			AppFrameAction,
			appAction,
			jsdset,
			dswidget,
			DataBrowser,
			staticComponents: {
				Validator: { isExist, isEmail, isPasword, isPhone },
			},
		} = globals;
		if (
			!StdAppAction ||
			!AppFrameAction ||
			!appAction ||
			!jsdset ||
			!dswidget ||
			!DataBrowser
		) {
			throw new Error(
				'One of required components (StdAppAction, AppFrameAction, appAction, jsdset, dswidget) not found in globals',
			);
		}

		if (!_moduleId) {
			throw new Error('_moduleId not defined');
		}

		function componentFactory(params) {
			const metadata = {
				corporateAccount: {
					fields: [
						{
							name: 'corporate_code',
							type: 'string',
							title: 'Kode Korporat',
							length: 6,
						},
						{
							name: 'account_no',
							type: 'string',
							title: 'Nomor Rekening',
							length: 20,
						},
						{
							name: 'account_name',
							type: 'string',
							title: 'Nama Rekening',
							length: 20,
						},
						{
							name: 'is_member',
							type: 'string',
							title: 'Nasabah/Member',
							length: 20,
						},
						{
							name: 'currency_code',
							type: 'string',
							title: 'Mata Uang',
							length: 20,
						},
						{
							name: 'service_type',
							type: 'string',
							title: 'Type Rekening',
							length: 20,
						},
						{
							name: 'service_types',
							type: 'string',
							title: 'Type Rekening',
							length: 20,
						},
						{
							name: 'is_transaction',
							type: 'string',
							title: 'Transaksi Diizinkan',
							length: 1,
						},
						{
							name: 'issuer_account_no',
							type: 'string',
							title: 'Nomor Account Issuer',
							length: 20,
							// validator: (value, fieldName) => {
							// 	let check = isExist(
							// 		value,
							// 		'Nomor Account Issuer',
							// 		2,
							// 	);
							// 	return check;
							// },
						},
						{
							name: 'issuer_product_code',
							type: 'string',
							title: 'Kode Produk Issuer',
							length: 20,
							// validator: (value, fieldName) => {
							// 	let check = isExist(
							// 		value,
							// 		'Kode Produk Issuer',
							// 		2,
							// 	);
							// 	return check;
							// },
						},
						{
							name: 'issuer_id',
							type: 'string',
							title: 'Issuer ID',
							length: 20,
						},
					],

					indexes: ['corporate_code'],
				},
			};

			const initialData = {
				'main:corporateAccount': [],
			};

			const serverDataMapping = {
				data: {
					dset: 'main',
					type: 'corporateAccount',
					fieldMapping: {
						'*': true, // allow default fields
					},
				},
			};

			const editUIData = {
				corporateAccount: {
					fields: {
						is_transaction: {
							inputType: 'radio',
							type: 'icon',
							variant: 'block',
							withborder: true,
							dataSets: {
								T: 'Diizinkan',
								F: 'Tidak Diizinkan',
							},
							initialValue: 'T',
						},
						is_member: {
							inputType: 'radio',
							type: 'icon',
							variant: 'block',
							withborder: true,
							dataSets: {
								F: 'Nasabah',
								T: 'Member',
							},
							initialValue: 'F',
						},
					},
				},
			};

			// return value may be different depending on params
			return { metadata, initialData, serverDataMapping, editUIData };
		}

		return { componentFactory };
	}

	async function initModuleF(aReact, globals) {
		return await getImports(aReact, globals);
	}

	return initModuleF;
	// })()  //--DAFReact: deploy
} //--DAFReact: development
