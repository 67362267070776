// 'use strict';

export function ModuleDefinition() {
	//--DAFReact: development

	// (function () { //--DAFReact: deploy

	async function getImports(React, globals) {
		const {
			_moduleId,
			StdAppAction,
			AppFrameAction,
			appAction,
			jsdset,
			dswidget,
			DataBrowser,
			NDataBrowser,
			frameAction,
			staticComponents: {
				Button,
				PageTitle,
				SearchBox,
				Filter,
				FilterActionButtons,
				ReactIconSi,
				ReactIconFa,
				RadioGroup,
				Dropdown,
				AlertComponent

			},
		} = globals;
		if (
			!_moduleId ||
			!StdAppAction ||
			!AppFrameAction ||
			!appAction ||
			!jsdset ||
			!dswidget ||
			!DataBrowser
		) {
			throw new Error(
				'One of required components (_moduleId, StdAppAction, AppFrameAction, appAction, jsdset, dswidget, _moduleId) not found in globals',
			);
		}

		const { FaPlusCircle, FaRetweet } = ReactIconFa;
		const { SiMicrosoftexcel } = ReactIconSi;

		const { metadata, initialData, serverDataMapping, editUIData } =
			await appAction.fetchAndExecModule(
				'corporate.corporateMetadata',
			);

		function componentFactory(params) {
			function AppForm(props) {
				const [state, setState] = React.useState({
					popupComponent: undefined,
				});

				const dsetProvider = React.useMemo(() => {
					return jsdset.dsetEmptyProvider();
				}, []);

				React.useEffect(() => {
					(async function () {
						const popupComponent = await appAction.fetchMenu(
							_moduleId,
							'popup_menu',
							props._authToken,
						);

						setState({
							...state,
							popupComponent,
						});
					})();
				}, [props._authToken]);

				const handleAddButton = React.useCallback(async () => {
					const { _authToken, componentClass } =
						await appAction.fetchFrameComponentWithToken(
							'corporate.corporateEditNew',
							{},
							{},
							{
								menuId: 'popup_menu',
								menuModuleId: _moduleId,
								key: 'mnuAddCorporate',
							},
						);

					if (componentClass && _authToken) {
						await frameAction.showModalAsync({
							headerProps: {
								title: 'Registrasi Korporat',
								icon: 'FaUserPlus',
							},
							contentClass: componentClass,
							contentProps: {
								_authToken,
								uiMode: 'add',
							},
							size: 'fullscreen',
						});
					}
				}, []);

				const AlertHandler = async (msg, type, body) => {
					await frameAction.showModal({
						contentClass: props => (
							<AlertComponent
								{...props}
								title={msg}
								type={type}
								body={body}
							/>
						),
						size: 'small',
					});
				};

				const ActionButtons = React.useCallback(
					({ clearFilter, params }) => {
					return [
						{
							type: 'bordered',
							name: 'Export Excel',
							onClick: async () => {
								try {

									await appAction.postData(
										_moduleId,
										'exportList',
										props._authToken,
										{
											...params,
											// ...filter 
										},
										{}
									);
								} catch (error) {
									AlertHandler(error.message, 'alert');
								}

							},
							render: () => <SiMicrosoftexcel />,
						},
						{
							type: 'bordered',
							name: 'Reload',
							onClick: () => clearFilter(),
							render: () => <FaRetweet />,
						},
					];
				}, []);

				return (
					<>
						<PageTitle
							subtitle="Management Korporat"
							title="Data Korporat"
						>
							<Button onClick={handleAddButton}>
								<FaPlusCircle /> Registrasi Korporat
							</Button>
						</PageTitle>

						<NDataBrowser
							stdAppAction={appAction}
							moduleId={_moduleId}
							dataId="listSO"
							dataProvider={dsetProvider}
							authToken={props._authToken}
							dsetPath="main"
							uiData={editUIData}
							filterComponent={({
								sortFields,
								loadDataClick,
								clearFilter,
								changeParams,
								params,
							}) => {
								// setLoadFunction(loadDataClick);

								return (
									<Filter>
										<div
											style={{
												display: 'flex',
												alignItems: 'center',
											}}
										>
											<RadioGroup
												data={Object.entries({
													'': 'Semua',
													A: 'Active',
													// S: 'Suspend',
													D: 'Deactive',
												})}
												name={'status'}
												currentValue={params.status}
												initialValue={''}
												type={'icon'}
												varian={'bordered'}
												onChange={e => {
													changeParams({
														status: e.target.value,
													});
												}}
											/>
										</div>
										<Dropdown
											label="Sort and search: "
											onChange={e =>
												changeParams({
													sortField: e.target.value,
												})
											}
											item={sortFields}
											value={params.sortField}
										/>

										<SearchBox
											style={{
												marginLeft: 5,
											}}
											handleSearch={src =>
												changeParams({ src })
											}
											srcText={params.src}
										/>
										<FilterActionButtons
											buttons={ActionButtons({
												clearFilter,
												params,
											})}
										/>
									</Filter>
								);
							}}
							popupComponent={(props) => <state.popupComponent {...props} />}
						/>
					</>
				);
			}

			return React.memo(AppForm);
		}

		return { componentFactory };
	}

	return async (aReact, globals) => {
		return await getImports(aReact, globals);
	};

	// })()  //--DAFReact: deploy
} //--DAFReact: development
