/*
	DAFReact framework module - Application form (hook)
	appname: cMSUser
	filename: cMSUserEdit.js
	moduleid: cMSUserEdit
	author: IK
*/

import React from 'react'; //--DAFReact: development

// 'use strict'; //--DAFReact: deploy

export function ModuleDefinition() {
	//--DAFReact: development

	// (function () { //--DAFReact: deploy

	async function getImports(React, globals) {
		// checking required libraries, may also include asynchronously loading other remote module, using appAction.loadModule
		const {
			_moduleId,
			StdAppAction,
			appAction,
			jsdset,
			dswidget,
			ndswidget,
			frameAction,
			staticComponents: { FormLabel, Button, UploadWithPreview, ReactIconFa, AlertComponent },
		} = globals;

		if (!_moduleId || !StdAppAction || !appAction || !jsdset || !dswidget) {
			throw new Error('One of required components (_moduleId, StdAppAction, appAction, jsdset, dswidget) not found in globals');
		}

		const { FaImages } = ReactIconFa;

		/* other required modules are asynchronously loaded here */
		const { metadata, initialData, serverDataMapping, editUIData } = await appAction.fetchAndExecModule('settings.cMSUserMetadata');

		const {
			PanelDataDisplay,
			FieldDataDisplay,
			PanelButton,
			//  FieldDataInput
		} = dswidget;

		const { FieldDataInput } = ndswidget;

		function componentFactory(params) {
			function AppForm(props) {
				const dataContext = React.useMemo(() => jsdset.dsetCreateContext(), []);
				const DSetProvider = React.useMemo(() => jsdset.dsetMetaProvider(dataContext, metadata, initialData, editUIData), []);
				return (
					<DSetProvider>
						<AppFormUI dataContext={dataContext} {...props} /> {/* any other props will be passed down */}
					</DSetProvider>
				);
			}

			function AppFormUI(props) {
				const [state, setState] = React.useState({
					isErr: false,
					errMessage: '',
					isEditing: false,
					url: null,
					groupName: '',
				});
				// bind controls to _moduleId and _authToken
				const vComps = React.useMemo(
					() =>
						appAction.connect(
							{
								PanelDataDisplay,
								FieldDataDisplay,
								PanelButton,
								FieldDataInput,
							},
							{ _moduleId, _getToken: () => props._authToken },
						),
					[],
				);

				// bind controls to datasets
				const [mainComps] = React.useMemo(
					() => [jsdset.connect({ context: props.dataContext, dsetPath: 'main' }, vComps)],
					[props.dataContext, vComps],
				);

				// obtain action objects from data context
				const [, dsMainAction, dsMainProxy] = jsdset.useDSetContext(props.dataContext, 'main');

				// load data function
				const loadData = React.useCallback(async () => {
					setState(state => ({
						...state,
						isErr: false,
						errMessage: '',
					}));

					try {
						const response = await appAction.fetchResource(
							_moduleId,
							'single_data',
							'dataSO',
							props._authToken,
							{ user_id: props.user_id },
							true,
						);

						if (response?.data?.length > 0) {
							dsMainAction.loadStore(response, 'std', serverDataMapping, true);

							if (response?.data[0]?.image_ref) {
								setState(state => ({
									...state,
									url: response.data[0].image_ref,
								}));
							}
							if (response?.data[0]?.group_name) {
								setState(state => ({
									...state,
									groupName: response.data[0].group_name,
								}));
							}
							// else {
							// 	dsMainAction.setField('image_ref', null)
							// }

							dsMainAction.recalcFormulas();
						} else {
							appAction.frameAction.closeModal();
							AlertHandler('Data Tidak Bisa di Ubah', 'warning');
						}
					} catch (err) {
						setState(prevState => ({
							...prevState,
							isErr: true,
							errMessage: err.message,
						}));
						return;
					}
				}, [dsMainAction, props._authToken, props.user_id]);

				// set event on component mounting
				React.useEffect(() => {
					(async function () {
						if (props.uiMode == 'edit') {
							await loadData();
						} else {
							dsMainAction.addRow({});
						}
					})();
				}, [dsMainAction, loadData, props.uiMode]);

				// UI event handlers
				const switchEditClick = () => {
					//
					setState(prevState => ({
						...prevState,
						isEditing: !prevState.isEditing,
					}));
				};

				const saveDataClick = async () => {
					try {
						const { fieldValidStates, fieldValidErrors } = dsMainProxy;

						const isInValid = Object.entries(fieldValidStates).filter(([i, v]) => {
							// if (
							// 	props.uiMode === 'edit' &&
							// 	(i == 'enc_password' || i == 'confirm_password')
							// ) {
							// 	return v;
							// } else {
							return !v;
							// }
						});

						if (isInValid.length > 0) throw new Error(fieldValidErrors[isInValid[0][0]]);

						var dataUnload = dsMainProxy.unloadStore(serverDataMapping, {
							includeLoadedRows: false,
							includeDeletedRows: true,
						});

						if (dataUnload?.data?.length > 0) {
							// if (props.uiMode !== 'edit') {
							// 	if (dataUnload.data[0].enc_password !== dataUnload.data[0].confirm_password) {
							// 		throw new Error('Konfirmasi kata sandi salah')
							// 	}
							// }

							await appAction.postData(_moduleId, 'saveData', props._authToken, dataUnload);
							appAction.frameAction.closeModal();
							if (props.uiMode !== 'Edit') {
								AlertHandler('Data berhasil disimpan', 'success', 'Data perlu diotorisasi');
							} else {
								AlertHandler('Data berhasil disimpan', 'success', 'Data perlu diotorisasi');
							}
						} else {
							appAction.frameAction.closeModal();
						}
					} catch (err) {
						AlertHandler(err.message, 'alert');
					}
				};

				const AlertHandler = async (msg, type, body) => {
					await frameAction.showModal({
						contentClass: props => <AlertComponent {...props} title={msg} type={type} body={body} />,
						size: 'small',
					});
				};

				const setBase64 = e => {
					let files = e;
					let reader = new FileReader();
					reader.onload = r => {
						const limitSize = 1500000; // Max. 2MB
						if ((r.target.result.length * 3) / 4 > limitSize) {
							setState(state => ({
								...state,
								url: null,
							}));
							return AlertHandler('File yang anda upload melebihi 1,5 MB', 'alert');
						} else {
							dsMainAction.setField('image_ref', r.target.result);
						}
					};
					let res = reader.readAsDataURL(files[0]);
				};

				// render
				return (
					<div>
						{/* lookup behavior of customer no is automatically set based on uiData */}
						<FormLabel label="User ID">
							{props.uiMode !== 'edit' ? (
								<mainComps.FieldDataInput fieldName="user_id" />
							) : (
								<b>
									<mainComps.FieldDataDisplay fieldName="user_id" />
								</b>
							)}
						</FormLabel>
						<FormLabel label="Nama User">
							<mainComps.FieldDataInput fieldName="user_name" />
						</FormLabel>
						<FormLabel label="Nomor Telepon">
							<mainComps.FieldDataInput fieldName="handphone_no" />
						</FormLabel>
						<FormLabel label="Email">
							<mainComps.FieldDataInput fieldName="email_address" />
						</FormLabel>
						{/* <FormLabel label="Foto">
							<UploadWithPreview
								icon={FaImages}
								id="image_ref"
								name="image_ref"
								onChange={e => {
									setBase64(e.target.files);
								}}
								description="Ukuran File Maksimal 1,5 MB"
								url={state.url}
								accept="image/jpg, image/jpeg, image/png"
								dataMaxFileSize="1,5M"
							/>
						</FormLabel> */}
						<FormLabel label="ID Group User">
							{props.uiMode !== 'edit' ? (
								<mainComps.FieldDataInput fieldName="group_id" />
							) : (
								<b>
									<mainComps.FieldDataDisplay fieldName="group_id" /> - {state.groupName}
								</b>
							)}
						</FormLabel>
						{/* <FormLabel
							label="Status Blokir"
							style={{ marginBottom: `1rem` }}
						>
							<mainComps.FieldDataInput
								elProps={{ readOnly: true }}
								fieldName="cms_user_status"
							/>
						</FormLabel> */}
						{/* {props.uiMode !== 'edit' ? (
							<>
								<FormLabel label="Password">
									<mainComps.FieldDataInput
										elProps={{ type: 'password' }}
										fieldName="enc_password"
									/>
								</FormLabel>
								<FormLabel label="Konfirmasi Password">
									<mainComps.FieldDataInput
										elProps={{ type: 'password' }}
										fieldName="confirm_password"
									/>
								</FormLabel>
							</>
						) : null} */}

						<br />

						<div
							style={{
								display: `flex`,
								justifyContent: `flex-end`,
							}}
						>
							<Button onClick={() => appAction.frameAction.closeModal()} type="bordered" style={{ marginRight: 10 }}>
								Batalkan
							</Button>
							<Button onClick={saveDataClick}>Simpan</Button>
							<div
								style={{
									display: state.isErr ? 'block' : 'none',
								}}
							>
								{state.errMessage}
							</div>
						</div>
					</div>
				);
			}

			// return value may be different depending on params
			return React.memo(AppForm);
		}

		return { componentFactory };
	}

	async function initModuleF(aReact, globals) {
		return await getImports(aReact, globals);
	}

	return initModuleF;
	// })()  //--DAFReact: deploy
} //--DAFReact: development
