/*
  DAFReact framework module - Application form (hook)
  appname: cMSAccountAccess
  filename: cMSAccountAccessList.js
  moduleid: cMSAccountAccessList
  author: IK
*/

// 'use strict';

export function ModuleDefinition() {
	//--DAFReact: development

	// (function () { //--DAFReact: deploy

	async function getImports(React, globals) {
		const {
			_moduleId,
			StdAppAction,
			AppFrameAction,
			appAction,
			jsdset,
			dswidget,
			NDataBrowser,
			frameAction,
			staticComponents: {
				Filter,
				PageTitle,
				FormLabel,
				Dropdown,
				SearchBox,
				ReactIconFa: { FaRetweet, FaPlusCircle },
				Button,
				FilterActionButtons,
			},
		} = globals;
		if (
			!_moduleId ||
			!StdAppAction ||
			!AppFrameAction ||
			!appAction ||
			!jsdset ||
			!dswidget ||
			!NDataBrowser
		) {
			throw new Error(
				'One of required components (_moduleId, StdAppAction, AppFrameAction, appAction, jsdset, dswidget, _moduleId) not found in globals',
			);
		}

		const { editUIData } = await appAction.fetchAndExecModule(
			'settings.cMSAccountAccessMetadata',
		);

		function componentFactory() {
			function AppForm(props) {
				const [state, setState] = React.useState({
					popupComponent: undefined,
				});

				const dsetProvider = React.useMemo(() => {
					return jsdset.dsetEmptyProvider();
				}, []);

				React.useEffect(() => {
					(async function () {
						const popupComponent = await appAction.fetchMenu(
							_moduleId,
							'popup_menu',
							props._authToken,
						);
						setState({
							...state,
							popupComponent,
						});
					})();
				}, [props._authToken]);

				// set variable to store reload function from table TODO: check if this approach is good
				var loadFunction;

				const handleAddButton = React.useCallback(async () => {
					const { _authToken, componentClass } =
						await appAction.fetchFrameComponentWithToken(
							'settings.cMSAccountAccessEditNew',
							{
								corporate_code: props.corporate_code,
								group_id: props.group_id,
							},
							{},
							{
								menuId: 'popup_menu',
								menuModuleId: _moduleId,
								key: 'mnuAddCMSAccountAccess',
							},
						);

					if (componentClass && _authToken)
						await frameAction.showModalAsync({
							headerProps: {
								title: 'Tambah Akses Rekening',
								icon: 'FaUserPlus',
							},
							contentClass: componentClass,
							contentProps: {
								_authToken,
								uiMode: 'add',
								loadFunction,
								corporate_code: props.corporate_code,
								group_id: props.group_id,
							},
							size: 'small',
						});
				}, []);

				const ActionButtons = React.useCallback(clearFilter => {
					return [
						{
							type: 'bordered',
							name: 'Reload',
							onClick: () => clearFilter(),
							render: () => <FaRetweet />,
						},
					];
				});

				return (
					<>
						<PageTitle
							subtitle={'Group ID : ' + props.group_id}
							title={props.group_name}
						>
							<Button onClick={handleAddButton}>
								<FaPlusCircle /> Buat Akses Rekening Baru
							</Button>
						</PageTitle>

						<div
							style={{
								display: 'flex',
								justifyContent: 'flex-start',
								alignItems: 'center',
							}}
						>
							<FormLabel label="Peranan">
								<h6>
									<strong>{props.peranan}</strong>
								</h6>
							</FormLabel>
						</div>

						<NDataBrowser
							stdAppAction={appAction}
							moduleId={_moduleId}
							dataId="listSO"
							dataProvider={dsetProvider}
							authToken={props._authToken}
							dsetPath="main"
							uiData={editUIData}
							params={{ group_id: props.group_id }}
							filterComponent={({
								sortFields,
								clearFilter,
								changeParams,
								params,
							}) => {
								return (
									<Filter>
										<Dropdown
											label="Urutkan dan Cari : "
											onChange={e =>
												changeParams({
													sortField: e.target.value,
												})
											}
											item={sortFields}
											value={params.sortField}
										/>
										<SearchBox
											style={{
												marginLeft: 5,
											}}
											handleSearch={src =>
												changeParams({ src })
											}
											srcText={params.src}
										/>
										<FilterActionButtons
											buttons={ActionButtons(clearFilter)}
										/>
									</Filter>
								);
							}}
							popupComponent={state.popupComponent}
						/>
					</>
				);
			}

			return React.memo(AppForm);
		}

		return { componentFactory };
	}

	return async (aReact, globals) => {
		return await getImports(aReact, globals);
	};

	// })()  //--DAFReact: deploy
} //--DAFReact: development
