/*
  DAFReact framework module - Application form (hook)
  appname: digitalTransactionCorp
  filename: digitalTransactionCorpList.js
  moduleid: digitalTransactionCorpList
  author: IK
*/

// 'use strict';

export function ModuleDefinition() {
	//--DAFReact: development

	// (function () { //--DAFReact: deploy

	async function getImports(React, globals) {
		const {
			_moduleId,
			StdAppAction,
			AppFrameAction,
			appAction,
			jsdset,
			dswidget,
			DataBrowser,
			NDataBrowser,
			frameAction,
			staticComponents: {
				FormLabel,
				PageTitle,
				SearchBox,
				Filter,
				FilterActionButtons,
				AlertComponent,
				ReactIconSi:{SiMicrosoftexcel},
				ReactIconFa: {FaRetweet},
				Dropdown,
				DatePicker,
			},
		} = globals;
		if (
			!_moduleId ||
			!StdAppAction ||
			!AppFrameAction ||
			!appAction ||
			!jsdset ||
			!dswidget ||
			!DataBrowser
		) {
			throw new Error(
				'One of required components (_moduleId, StdAppAction, AppFrameAction, appAction, jsdset, dswidget, _moduleId) not found in globals',
			);
		}

		const { editUIData } = await appAction.fetchAndExecModule(
			'monitoring_corp.batchTransactionCorpMetadata',
		);

		function componentFactory(params) {
			function AppForm(props) {
				const [state, setState] = React.useState({
					popupComponent: undefined,
					conv_status: [],
					is_closed: [],
					account_no: [],
				});

				const initFilter = {
					start_date: new Date(),
					end_date: new Date(),
					conv_status: null,
					is_closed: null,
					account_no: '',
					account_name: '',
				};
				const [filter, setFilter] = React.useState(initFilter);

				const dsetProvider = React.useMemo(
					() => jsdset.dsetEmptyProvider(),
					[],
				);

				// load data function
				const loadData = React.useCallback(async () => {
					setState(state => ({
						...state,
						isErr: false,
						errMessage: '',
					}));

					try {
						const getStatusEksekusi = await appAction.fetchResource(
							_moduleId,
							'method',
							'getConvStatus',
							props._authToken,
							{},
							true,
						);

						// const getStatusAkhir = await appAction.fetchResource(
						// 	_moduleId,
						// 	'method',
						// 	'getStatusAkhir',
						// 	props._authToken,
						// 	{},
						// 	true,
						// );

						// const getRekening = await appAction.fetchResource(
						// 	_moduleId,
						// 	'single_data',
						// 	'getRekening',
						// 	props._authToken,
						// 	{},
						// 	true,
						// );

						let statusEksekusi = [
							{ value: null, name: 'Semua' },
						];
						let statusAkhir = [
							{ value: null, name: 'Pilih Status Transaksi' },
						];

						if (getStatusEksekusi?.rows) {
							for (var x of getStatusEksekusi?.rows) {
								statusEksekusi.push({
									value: x.id,
									name: x.description,
								});
							}

							// for (var x of getStatusAkhir?.rows) {
							// 	statusAkhir.push({
							// 		value: x.id,
							// 		name: x.description,
							// 	});
							// }

							// for (var x of getRekening?.data) {
							// 	rek.push({
							// 		value: x.account_no,
							// 		name: x.account_no + ' - ' + x.account_name,
							// 	});
							// }
						}

						setState(prevState => ({
							...prevState,
							conv_status: statusEksekusi,
						}));

						// dsMainAction.recalcFormulas();
					} catch (err) {
						setState(prevState => ({
							...prevState,
							isErr: true,
							errMessage: err.message,
						}));
						return;
					}
				}, [props._authToken]);

				React.useEffect(() => {
					(async function () {
						const popupComponent = await appAction.fetchMenu(
							_moduleId,
							'popup_menu',
							props._authToken,
						);
						setState({ popupComponent });
						loadData();
					})();
				}, [props._authToken]);

				const ActionButtons = React.useCallback(clearFilter => {
					return [
						{
							type: 'bordered',
							name: 'Print Excel',
							onClick: async () => {
								try {
									await appAction.postData(
										_moduleId,
										'importList',
										props._authToken,
										{ ...params, ...filter }
									);
								} catch (error) {
									AlertHandler(error.message, 'alert');
								}
							},
							render: () => <SiMicrosoftexcel />,
						},
						{
							type: 'bordered',
							name: 'Reload',
							onClick: () => {
								setFilter(initFilter);
								clearFilter();
							},
							render: () => <FaRetweet />,
						},
					];
				}, [filter]);

				const AlertHandler = async (msg, type) => {
					await frameAction.showModal({
						contentClass: props => (
							<AlertComponent
								{...props}
								title={msg}
								type={type}
							/>
						),
						size: 'small',
					});
				}

				const handleFilter = React.useCallback(
					e => {
						setFilter(
							e
								? {
										...filter,
										...e,
								  }
								: initFilter,
						);
					},
					[filter],
				);

				return (
					<>
						<PageTitle
							title="Histori Transaksi (Multi)"
							subtitle="Monitoring"
							style={{ marginBottom: `1.5rem` }}
						/>

						<NDataBrowser
							stdAppAction={appAction}
							moduleId={_moduleId}
							dataId="listSO"
							dataProvider={dsetProvider}
							authToken={props._authToken}
							dsetPath="main"
							uiData={editUIData}
							params={filter}
							popupComponent={state.popupComponent}
							filterComponent={({
								sortFields,
								clearFilter,
								changeParams,
								params,
							}) => {
								return (
									<div
										style={{
											border: `1px solid rgba(0,0,0,0.1)`,
											backgroundColor: `#eeeeee`,
											padding: `1rem`,
											borderRadius: `5px`,
											margin: `1rem 0`,
										}}
									>
										<h5>Cari Histori Transaksi</h5>
										<Filter>
											{/* <FormLabel
												label="Rekening Sumber"
												style={{ maxWidth: '250px',marginRight:'1rem' }}
											>
												{state.account_no ? (
													<Dropdown
														onChange={e => {
															setFilter(
																prevState => ({
																	...prevState,
																	account_no: e
																		.target
																		.value,
																}),
															);
														}}
														value={
															filter.account_no ||
															null
														}
														item={state.account_no}
														width="-webkit-fill-available"
													/>
												) : null}
											</FormLabel> */}
											<FormLabel
												label="Status Eksekusi"
												style={{
													maxWidth: '200px',
													marginRight: '1rem',
												}}
											>
												{state.conv_status ? (
													<Dropdown
														item={state.conv_status}
														width="-webkit-fill-available"
														onChange={e => {
															setFilter(
																prevState => ({
																	...prevState,
																	conv_status:
																		e.target
																			.value,
																}),
															);
														}}
														value={
															filter.conv_status ||
															null
														}
													/>
												) : null}
											</FormLabel>
											{/* <FormLabel
												label="Status Akhir"
												style={{ maxWidth: '200px' }}
											>
												{state.is_closed ? (
													<Dropdown
														item={state.is_closed}
														width="-webkit-fill-available"
														onChange={e => {
															setFilter(
																prevState => ({
																	...prevState,
																	is_closed:
																		e.target
																			.value,
																}),
															);
														}}
														value={
															filter.is_closed ||
															null
														}
													/>
												) : null}
											</FormLabel> */}
										</Filter>

										<Filter>
											{/* Tanggal */}
											<FormLabel
												label="Tanggal Transaksi"
												style={{
													maxWidth: '250px',
													marginRight: '1rem',
												}}
											>
												<div
													style={{
														display: 'flex',
													}}
												>
													<DatePicker
														value={
															filter.start_date
														}
														onSelect={e => {
															handleFilter({
																start_date: e,
															});
														}}
													/>
													<div
														style={{ padding: 10 }}
													>
														S/D
													</div>
													<DatePicker
														value={filter.end_date}
														onSelect={e => {
															handleFilter({
																end_date: e,
															});
														}}
													/>
												</div>
											</FormLabel>

											{/* Sort & Search */}
											<FormLabel
												label="Urutkan & Cari"
												style={{
													maxWidth: '200px',
													marginRight: '1rem',
												}}
											>
												<Dropdown
													item={sortFields}
													width="-webkit-fill-available"
													value={params.sortField}
													onChange={e =>
														changeParams({
															sortField:
																e.target.value,
														})
													}
												/>
											</FormLabel>
											<FormLabel label="&nbsp;">
												<SearchBox
													label="Tampilkan"
													style={{
														marginLeft: 5,
														width: '300px',
													}}
													handleSearch={src => {
														changeParams({
															start_date:
																filter.start_date,
															end_date:
																filter.end_date,
															src: src,
															conv_status:
																filter.conv_status,
															is_closed:
																filter.is_closed,
															account_no:
																filter.account_no,
														});
													}}
													srcText={params.src}
												/>
											</FormLabel>
											<FormLabel label="&nbsp;">
												<FilterActionButtons
													buttons={ActionButtons({
														clearFilter,
														params,
													})}
												/>
											</FormLabel>
										</Filter>
									</div>
								);
							}}
						/>
					</>
				);
			}

			return React.memo(AppForm);
		}

		return { componentFactory };
	}

	return async (aReact, globals) => {
		return await getImports(aReact, globals);
	};

	// })()  //--DAFReact: deploy
} //--DAFReact: development
