/*
  DAFReact framework module - Application form (hook)
  appname: cMSUserGroup
  filename: cMSUserGroupEdit.js
  moduleid: cMSUserGroupEdit
  author: IK
*/

import React from 'react'; //--DAFReact: development

// 'use strict'; //--DAFReact: deploy

export function ModuleDefinition() {
	//--DAFReact: development

	// (function () { //--DAFReact: deploy

	async function getImports(React, globals) {
		// checking required libraries, may also include asynchronously loading other remote module, using appAction.loadModule
		const {
			_moduleId,
			StdAppAction,
			appAction,
			jsdset,
			dswidget,
			frameAction,
			ndswidget,
			staticComponents: { FormLabel, Button, AlertComponent, Checkbox },
		} = globals;
		if (!_moduleId || !StdAppAction || !appAction || !jsdset || !dswidget) {
			throw new Error(
				'One of required components (_moduleId, StdAppAction, appAction, jsdset, dswidget) not found in globals',
			);
		}

		/* other required modules are asynchronously loaded here */
		const { metadata, initialData, serverDataMapping, editUIData } =
			await appAction.fetchAndExecModule('settings.cMSUserGroupMetadata');

		const { FieldDataDisplay, FieldDataInput } = ndswidget;

		function componentFactory() {
			function AppForm(props) {
				const dataContext = React.useMemo(
					() => jsdset.dsetCreateContext(),
					[],
				);
				const DSetProvider = React.useMemo(() => {
					return jsdset.dsetMetaProvider(
						dataContext,
						metadata,
						initialData,
						editUIData,
					);
				}, []);
				return (
					<DSetProvider>
						<AppFormUI dataContext={dataContext} {...props} />
						{/* any other props will be passed down */}
					</DSetProvider>
				);
			}

			function AppFormUI(props) {
				const [state, setState] = React.useState({
					isErr: false,
					errMessage: '',
					isEditing: false,
				});

				const initialRole = {
					is_operator: 'F',
					is_supervisor: 'F',
					is_treasurer: 'F',
				};

				// bind controls to _moduleId and _authToken
				const vComps = React.useMemo(
					() =>
						appAction.connect(
							{
								FieldDataDisplay,
								FieldDataInput,
							},
							{ _moduleId, _getToken: () => props._authToken },
						),
					[],
				);

				// bind controls to datasets
				const [mainComps] = React.useMemo(
					() => [
						jsdset.connect(
							{ context: props.dataContext, dsetPath: 'main' },
							vComps,
						),
					],
					[props.dataContext, vComps],
				);

				// obtain action objects from data context
				const [, dsMainAction, dsMainProxy] = jsdset.useDSetContext(
					props.dataContext,
					'main',
				);

				// load data function
				const loadData = React.useCallback(async () => {
					setState(state => ({
						...state,
						isErr: false,
						errMessage: '',
					}));

					try {
						const response = await appAction.fetchResource(
							_moduleId,
							'single_data',
							'dataSO',
							props._authToken,
							{
								corporate_code: props.corporate_code,
								group_id: props.group_id,
							},
							true,
						);
						if (response?.data?.length > 0) {
							dsMainAction.loadStore(
								response,
								'std',
								serverDataMapping,
								true,
							);
							if (response.data[0]) {
								dsMainAction.setFields(response.data[0]);
							}
						} else {
							appAction.frameAction.closeModal();
							AlertHandler('Data Tidak Bisa di Ubah', 'warning');
						}
					} catch (err) {
						setState(prevState => ({
							...prevState,
							isErr: true,
							errMessage: err.message,
						}));
						return;
					}
				}, [
					dsMainAction,
					props._authToken,
					props.corporate_code,
					props.group_id,
				]);

				// set event on component mounting
				React.useEffect(() => {
					(async function () {
						if (props.uiMode == 'edit') {
							await loadData();
						} else {
							dsMainAction.addRow(initialRole);
						}
					})();
				}, [dsMainAction, loadData, props.uiMode]);

				var dataUnload = dsMainProxy.unloadStore(serverDataMapping, {
					includeLoadedRows: false,
					includeDeletedRows: true,
				});

				const { is_operator, is_supervisor, is_treasurer } =
					dataUnload.data[0] || initialRole;

				const saveDataClick = async () => {
					try {
						if (dataUnload.data[0]) {
							const { fieldValidStates, fieldValidErrors } =
								dsMainProxy;

							const isInValid = Object.entries(
								fieldValidStates,
							).filter(([i, v]) => {
								return !v;
							});

							if (isInValid.length > 0)
								throw new Error(
									fieldValidErrors[isInValid[0][0]],
								);

							if (
								is_operator === 'F' &&
								is_supervisor === 'F' &&
								is_treasurer === 'F'
							)
								throw new Error('Role tidak boleh kosong.');

							if (
								is_operator === 'T' &&
								(is_supervisor === 'T' || is_treasurer === 'T')
							)
								throw new Error(
									'Operator tidak boleh memimiliki lebih dari satu peran.',
								);

							await appAction.postData(
								_moduleId,
								'saveData',
								props._authToken,
								dataUnload,
							);
							appAction.frameAction.closeModal();
							AlertHandler('Data berhasil disimpan', 'success');
						} else {
							appAction.frameAction.closeModal();
						}
					} catch (err) {
						AlertHandler(err.message, 'alert');
					}
				};

				const AlertHandler = async (msg, type) => {
					await frameAction.showModal({
						contentClass: props => (
							<AlertComponent
								{...props}
								title={msg}
								type={type}
							/>
						),
						size: 'small',
					});
				};

				// render
				return (
					<div>
						{/* lookup behavior of customer no is automatically set based on uiData */}
						{props.uiMode === 'add' ? (
							<FormLabel label="Group ID">
								<mainComps.FieldDataInput fieldName="group_id" />
							</FormLabel>
						) : (
							<FormLabel
								label="Group ID"
								style={{ marginTop: 20 }}
							>
								<strong>
									<mainComps.FieldDataDisplay fieldName="group_id" />
								</strong>
							</FormLabel>
						)}
						<FormLabel label="Nama Grup">
							<mainComps.FieldDataInput fieldName="group_name" />
						</FormLabel>
						{/* <FormLabel
							label="Status Group"
							className="form-horizontal"
						>
							<div style={{ marginTop: 10 }}>
								<mainComps.FieldDataInput fieldName="cms_group_status" />
							</div>
						</FormLabel> */}
						{/* <br /> */}
						<FormLabel label="Peranan" className="form-horizontal">
							<div
								style={{
									display: 'flex',
									justifyContent: ' left',
								}}
							>
								<div style={{ marginRight: 10 }}>
									<mainComps.FieldDataInput
										fieldName="is_operator"
										elProps={{
											readOnly:
												is_treasurer === 'T' ||
												is_supervisor === 'T',
										}}
									/>
								</div>
								<div style={{ marginRight: 10 }}>
									<mainComps.FieldDataInput
										fieldName="is_supervisor"
										elProps={{
											readOnly: is_operator === 'T',
										}}
									/>
								</div>
								<div style={{ marginRight: 10 }}>
									<mainComps.FieldDataInput
										fieldName="is_treasurer"
										elProps={{
											readOnly: is_operator === 'T',
										}}
									/>
								</div>
							</div>
						</FormLabel>
						<div
							style={{
								display: 'flex',
								justifyContent: 'flex-end',
								marginTop: 20,
							}}
						>
							<Button
								onClick={() =>
									appAction.frameAction.closeModal()
								}
								type="bordered"
								style={{ marginRight: 10 }}
							>
								Batalkan
							</Button>
							<Button onClick={saveDataClick}>Simpan Data</Button>
						</div>
						<div
							style={{ display: state.isErr ? 'block' : 'none' }}
						>
							{state.errMessage}
						</div>
					</div>
				);
			}

			// return value may be different depending on params
			return React.memo(AppForm);
		}

		return { componentFactory };
	}

	async function initModuleF(aReact, globals) {
		return await getImports(aReact, globals);
	}

	return initModuleF;
	// })()  //--DAFReact: deploy
} //--DAFReact: development
