import React from 'react';

import { AppFrame } from '../modules/com.ihsan/appframe_minimal.js';

import { StdAppInterfaces } from '../modules/com.ihsan/appdata.js';

import LoginPages from '../app_components/_modules/Login/Login';
import RequestLoading from '../app_components/RequestLoading/RequestLoading.js';

const CustomFrameHeader = props => {
	return <></>;
};

const LoginPage = React.memo(function (props) {
	return <LoginPages {...props} appInfo={'corporate'} />;
});

function MainPage_(props) {
	return (
		<div>
			{props.requesting === true && <RequestLoading />}

			{props.isLogin !== true ? <LoginPage {...props} /> : <AppFrame treeName="/" headerComponent={CustomFrameHeader} sender="main" />}
		</div>
	);
}

const MainPage = StdAppInterfaces.loginAndRequest(MainPage_);

export { MainPage };
