import React, { useEffect } from 'react';

import { AppFrame } from '../modules/com.ihsan/appframe_minimal.js';
import { initVisualLib, setStaticComponents, initGlobalModules, useStdAppContext, FRAME_CONTAINER_PATH } from '../modules/com.ihsan/appdata.js';

import Menu, { SubMenu, Item as MenuItem, Divider } from 'rc-menu';
import { DateFieldDataInput } from '../modules/com.ihsan/dsetwidgetex';
import NDataBrowser from '../modules/com.ihsan/new.databrowser';
import * as NDswidget from '../modules/com.ihsan/new.dsetwidget';

// register static modules here
import { ModuleDefinition as miscSupportUI } from '../app_frames/apps_local/admin/miscSupportUI';
import { ModuleDefinition as appGlobal } from '../app_frames/apps_local/admin/appGlobal';
import { ModuleDefinition as main } from '../app_frames/apps_local/admin/main';
import { ModuleDefinition as corporateMetadata } from '../app_frames/apps_local/admin/corporate/corporateMetadata.js';
import { ModuleDefinition as corporateList } from '../app_frames/apps_local/admin/corporate/corporateList.js';
import { ModuleDefinition as corporateView } from '../app_frames/apps_local/admin/corporate/corporateView.js';
import { ModuleDefinition as corporateEditLimit } from '../app_frames/apps_local/admin/corporate/corporateEditLimit';
import { ModuleDefinition as corporateEditNew } from '../app_frames/apps_local/admin/corporate/corporateEditNew.js';
import { ModuleDefinition as corporateAccountMetadata } from '../app_frames/apps_local/admin/corporate/corporateAccountMetadata.js';
import { ModuleDefinition as corporateAccountList } from '../app_frames/apps_local/admin/corporate/corporateAccountList.js';
import { ModuleDefinition as corporateAccountView } from '../app_frames/apps_local/admin/corporate/corporateAccountView.js';
import { ModuleDefinition as corporateAccountEditNew } from '../app_frames/apps_local/admin/corporate/corporateAccountEditNew.js';
import { ModuleDefinition as transactionCodeMetadata } from '../app_frames/apps_local/admin/parameter/transactionCodeMetadata.js';
import { ModuleDefinition as transactionCodeList } from '../app_frames/apps_local/admin/parameter/transactionCodeList.js';
import { ModuleDefinition as transactionCodeView } from '../app_frames/apps_local/admin/parameter/transactionCodeView.js';
import { ModuleDefinition as transactionCodeEditNew } from '../app_frames/apps_local/admin/parameter/transactionCodeEditNew.js';
import { ModuleDefinition as finProductCodeMetadata } from '../app_frames/apps_local/admin/parameter/finProductCodeMetadata.js';
import { ModuleDefinition as finProductCodeList } from '../app_frames/apps_local/admin/parameter/finProductCodeList.js';
import { ModuleDefinition as finProductCodeView } from '../app_frames/apps_local/admin/parameter/finProductCodeView.js';
import { ModuleDefinition as finProductCodeEditNew } from '../app_frames/apps_local/admin/parameter/finProductCodeEditNew.js';
import { ModuleDefinition as auth_ControlMetadata } from '../app_frames/apps_local/admin/auth_control/auth_ControlMetadata.js';
import { ModuleDefinition as auth_ControlList } from '../app_frames/apps_local/admin/auth_control/auth_ControlList.js';
import { ModuleDefinition as auth_ControlView } from '../app_frames/apps_local/admin/auth_control/auth_ControlView.js';
import { ModuleDefinition as auth_ControlEditNew } from '../app_frames/apps_local/admin/auth_control/auth_ControlEditNew.js';
import { ModuleDefinition as auth_ControlHistMetadata } from '../app_frames/apps_local/admin/auth_control/auth_ControlHistMetadata.js';
import { ModuleDefinition as auth_ControlHistList } from '../app_frames/apps_local/admin/auth_control/auth_ControlHistList.js';
import { ModuleDefinition as auth_ControlHistView } from '../app_frames/apps_local/admin/auth_control/auth_ControlHistView.js';
import { ModuleDefinition as auth_ControlHistEditNew } from '../app_frames/apps_local/admin/auth_control/auth_ControlHistEditNew.js';
import { ModuleDefinition as loginLogMetadata } from '../app_frames/apps_local/admin/log/loginLogMetadata.js';
import { ModuleDefinition as loginLogList } from '../app_frames/apps_local/admin/log/loginLogList.js';
import { ModuleDefinition as loginLogView } from '../app_frames/apps_local/admin/log/loginLogView.js';
import { ModuleDefinition as loginLogEditNew } from '../app_frames/apps_local/admin/log/loginLogEditNew.js';
import { ModuleDefinition as digitalTransactionMetadata } from '../app_frames/apps_local/admin/monitoring/digitalTransactionMetadata.js';
import { ModuleDefinition as digitalTransactionList } from '../app_frames/apps_local/admin/monitoring/digitalTransactionList.js';
import { ModuleDefinition as digitalTransactionView } from '../app_frames/apps_local/admin/monitoring/digitalTransactionView.js';
import { ModuleDefinition as digitalTransactionEditNew } from '../app_frames/apps_local/admin/monitoring/digitalTransactionEditNew.js';
import { ModuleDefinition as batchTransactionMetadata } from '../app_frames/apps_local/admin/monitoring/batchTransactionMetadata.js';
import { ModuleDefinition as batchTransactionList } from '../app_frames/apps_local/admin/monitoring/batchTransactionList.js';
import { ModuleDefinition as batchTransactionView } from '../app_frames/apps_local/admin/monitoring/batchTransactionView.js';
import { ModuleDefinition as batchTransactionEditNew } from '../app_frames/apps_local/admin/monitoring/batchTransactionEditNew.js';
import { ModuleDefinition as cMSWorkflowMetadata } from '../app_frames/apps_local/admin/settings/cMSWorkflowMetadata.js';
import { ModuleDefinition as cMSWorkflowList } from '../app_frames/apps_local/admin/settings/cMSWorkflowList.js';
import { ModuleDefinition as cMSWorkflowView } from '../app_frames/apps_local/admin/settings/cMSWorkflowView.js';
import { ModuleDefinition as cMSWorkflowEditNew } from '../app_frames/apps_local/admin/settings/cMSWorkflowEditNew.js';
import { ModuleDefinition as cMSWorkflowTxTypeMetadata } from '../app_frames/apps_local/admin/settings/cMSWorkflowTxTypeMetadata.js';
import { ModuleDefinition as cMSWorkflowTxTypeList } from '../app_frames/apps_local/admin/settings/cMSWorkflowTxTypeList.js';
import { ModuleDefinition as cMSWorkflowTxTypeView } from '../app_frames/apps_local/admin/settings/cMSWorkflowTxTypeView.js';
import { ModuleDefinition as cMSWorkflowTxTypeEditNew } from '../app_frames/apps_local/admin/settings/cMSWorkflowTxTypeEditNew.js';
import { ModuleDefinition as cMSUserGroupMetadata } from '../app_frames/apps_local/admin/settings/cMSUserGroupMetadata.js';
import { ModuleDefinition as cMSUserGroupList } from '../app_frames/apps_local/admin/settings/cMSUserGroupList.js';
import { ModuleDefinition as cMSUserGroupView } from '../app_frames/apps_local/admin/settings/cMSUserGroupView.js';
import { ModuleDefinition as cMSUserGroupEditNew } from '../app_frames/apps_local/admin/settings/cMSUserGroupEditNew.js';
import { ModuleDefinition as cMSUserGroupEditStatus } from '../app_frames/apps_local/admin/settings/cMSUserGroupEditStatus.js';
import { ModuleDefinition as cMSAccountAccessMetadata } from '../app_frames/apps_local/admin/settings/cMSAccountAccessMetadata.js';
import { ModuleDefinition as cMSAccountAccessList } from '../app_frames/apps_local/admin/settings/cMSAccountAccessList.js';
import { ModuleDefinition as cMSAccountAccessView } from '../app_frames/apps_local/admin/settings/cMSAccountAccessView.js';
import { ModuleDefinition as cMSAccountAccessEditNew } from '../app_frames/apps_local/admin/settings/cMSAccountAccessEditNew.js';
import { ModuleDefinition as cMSUserGroupLimitMetadata } from '../app_frames/apps_local/admin/settings/cMSUserGroupLimitMetadata.js';
import { ModuleDefinition as cMSUserGroupLimitList } from '../app_frames/apps_local/admin/settings/cMSUserGroupLimitList.js';
import { ModuleDefinition as cMSUserGroupLimitView } from '../app_frames/apps_local/admin/settings/cMSUserGroupLimitView.js';
import { ModuleDefinition as cMSUserGroupLimitEditNew } from '../app_frames/apps_local/admin/settings/cMSUserGroupLimitEditNew.js';
import { ModuleDefinition as cMSUserMetadata } from '../app_frames/apps_local/admin/settings/cMSUserMetadata.js';
import { ModuleDefinition as cMSUserList } from '../app_frames/apps_local/admin/settings/cMSUserList.js';
import { ModuleDefinition as cMSUserView } from '../app_frames/apps_local/admin/settings/cMSUserView.js';
import { ModuleDefinition as cMSUserEditNew } from '../app_frames/apps_local/admin/settings/cMSUserEditNew.js';
import { ModuleDefinition as cMSUserEditStatus } from '../app_frames/apps_local/admin/settings/cMSUserEditStatus.js';
import { ModuleDefinition as settingNotification } from '../app_frames/apps_local/admin/settings/notifikasi/settingNotification.js';
import { ModuleDefinition as settingNotificationList } from '../app_frames/apps_local/admin/settings/notifikasi/settingNotificationList.js';
import { ModuleDefinition as settingNotificationEditNew } from '../app_frames/apps_local/admin/settings/notifikasi/settingNotificationEditNew.js';
import { ModuleDefinition as settingNotificationMetadata } from '../app_frames/apps_local/admin/settings/notifikasi/settingNotificationMetadata.js';
import { ModuleDefinition as auth_ControlCorpMetadata } from '../app_frames/apps_local/admin/auth_control_corp/auth_ControlCorpMetadata.js';
import { ModuleDefinition as auth_ControlCorpList } from '../app_frames/apps_local/admin/auth_control_corp/auth_ControlCorpList.js';
import { ModuleDefinition as auth_ControlCorpView } from '../app_frames/apps_local/admin/auth_control_corp/auth_ControlCorpView.js';
import { ModuleDefinition as auth_ControlCorpEditNew } from '../app_frames/apps_local/admin/auth_control_corp/auth_ControlCorpEditNew.js';
import { ModuleDefinition as auth_ControlHistCorpMetadata } from '../app_frames/apps_local/admin/auth_control_corp/auth_ControlHistCorpMetadata.js';
import { ModuleDefinition as auth_ControlHistCorpList } from '../app_frames/apps_local/admin/auth_control_corp/auth_ControlHistCorpList.js';
import { ModuleDefinition as auth_ControlHistCorpView } from '../app_frames/apps_local/admin/auth_control_corp/auth_ControlHistCorpView.js';
import { ModuleDefinition as auth_ControlHistCorpEditNew } from '../app_frames/apps_local/admin/auth_control_corp/auth_ControlHistCorpEditNew.js';
import { ModuleDefinition as loginLogCorpMetadata } from '../app_frames/apps_local/admin/log_corp/loginLogCorpMetadata.js';
import { ModuleDefinition as loginLogCorpList } from '../app_frames/apps_local/admin/log_corp/loginLogCorpList.js';
import { ModuleDefinition as loginLogCorpView } from '../app_frames/apps_local/admin/log_corp/loginLogCorpView.js';
import { ModuleDefinition as loginLogCorpEditNew } from '../app_frames/apps_local/admin/log_corp/loginLogCorpEditNew.js';
import { ModuleDefinition as digitalTransactionCorpMetadata } from '../app_frames/apps_local/admin/monitoring_corp/digitalTransactionCorpMetadata.js';
import { ModuleDefinition as digitalTransactionCorpList } from '../app_frames/apps_local/admin/monitoring_corp/digitalTransactionCorpList.js';
import { ModuleDefinition as digitalTransactionCorpView } from '../app_frames/apps_local/admin/monitoring_corp/digitalTransactionCorpView.js';
import { ModuleDefinition as digitalTransactionCorpEditNew } from '../app_frames/apps_local/admin/monitoring_corp/digitalTransactionCorpEditNew.js';
import { ModuleDefinition as batchTransactionCorpMetadata } from '../app_frames/apps_local/admin/monitoring_corp/batchTransactionCorpMetadata.js';
import { ModuleDefinition as batchTransactionCorpList } from '../app_frames/apps_local/admin/monitoring_corp/batchTransactionCorpList.js';
import { ModuleDefinition as batchTransactionCorpView } from '../app_frames/apps_local/admin/monitoring_corp/batchTransactionCorpView.js';
import { ModuleDefinition as detailBatchTransactionCorpView } from '../app_frames/apps_local/admin/monitoring_corp/detailBatchTransactionCorpView.js';
import { ModuleDefinition as batchTransactionCorpEditNew } from '../app_frames/apps_local/admin/monitoring_corp/batchTransactionCorpEditNew.js';

import { ModuleDefinition as settingTagsMetadata } from '../app_frames/apps_local/admin/settings/tags/settingTagsMetadata';
import { ModuleDefinition as settingTagsView } from '../app_frames/apps_local/admin/settings/tags/settingTagsView';
import { ModuleDefinition as settingTags } from '../app_frames/apps_local/admin/settings/tags/settingTags';
import { ModuleDefinition as formAddTags } from '../app_frames/apps_local/admin/settings/tags/formAddTags';

import { ModuleDefinition as autosweepMetadata } from '../app_frames/apps_local/admin/settings/autosweep/autosweep-metadata.js';
import { ModuleDefinition as autosweepForm } from '../app_frames/apps_local/admin/settings/autosweep/autosweep-form.js';
import { ModuleDefinition as autosweepList } from '../app_frames/apps_local/admin/settings/autosweep/autosweep-list.js';

// import hoc here
import { ModuleDefinition as MainMenu } from '../app_components/_hoc/MainMenu';

// import static components here
import Button from '../app_components/Button/Button';
import DatePicker from '../app_components/Forms/DatePicker/DatePicker';
import SearchBox from '../app_components/SearchBox/SearchBox';
import Table from '../app_components/Table/Table';
import Pagination from '../app_components/Pagination/Pagination';
import Filter from '../app_components/Filter/Filter';
import FormLabel from '../app_components/Forms/FormLabel/FormLabel';
import RadioIcon from '../app_components/RadioButtonBordered/Radiobordered';
import Radio from '../app_components/Radio/Radio';
import InputGroupButton from '../app_components/InputGroupButton/InputGroupButton';
import PageTitle from '../app_components/PageTitle/PageTitle';
import FilterActionButtons from '../app_components/Filter/FilterActionButtons';
import ModalHeader from '../app_components/Modal/ModalHeader/ModalHeader';
import ModalBody from '../app_components/Modal/ModalBody/ModalBody';
import Input from '../app_components/Forms/Input/Input';
import Upload from '../app_components/Upload/Upload';
import UploadWithPreview from '../app_components/Upload/UploadWithPreview';
import Dropdown from '../app_components/Dropdown/Dropdown';
import RadioGroup from '../app_components/Forms/RadioGroup/RadioGroup';
import Checkbox from '../app_components/Forms/Checkbox/Checkbox';
import * as Validator from '../app_components/Validator/validatorInput';
import AlertComponent from '../app_components/AlertComponent/AlertComponent';

import PageLoadingIndicator from '../app_components/PageLoadingIndicator/PageLoadingIndicator';

import * as ReactIconFa from 'react-icons/fa';
import * as ReactIconSi from 'react-icons/si';
import * as ReactIconGr from 'react-icons/gr';

//New module
import { ModuleDefinition as corporateAccountEditPermission } from '../app_frames/apps_local/admin/corporate/corporateAccountEditPermission.js';
import { ModuleDefinition as changePasswordMetadata } from '../app_frames/apps_local/admin/application/changePasswordMetadata.js';
import { ModuleDefinition as changePassword } from '../app_frames/apps_local/admin/application/changePassword.js';


// list all static modules
var staticModules = {
	// HOC
	MainMenu,
	appGlobal,
	miscSupportUI,
	main,
	'corporate.corporateMetadata': corporateMetadata,
	'corporate.corporateList': corporateList,
	'corporate.corporateView': corporateView,
	'corporate.corporateEditLimit': corporateEditLimit,
	'corporate.corporateEditNew': corporateEditNew,
	'corporate.corporateAccountMetadata': corporateAccountMetadata,
	'corporate.corporateAccountList': corporateAccountList,
	'corporate.corporateAccountView': corporateAccountView,
	'corporate.corporateAccountEditNew': corporateAccountEditNew,
	'parameter.transactionCodeMetadata': transactionCodeMetadata,
	'parameter.transactionCodeList': transactionCodeList,
	'parameter.transactionCodeView': transactionCodeView,
	'parameter.transactionCodeEditNew': transactionCodeEditNew,
	'parameter.finProductCodeMetadata': finProductCodeMetadata,
	'parameter.finProductCodeList': finProductCodeList,
	'parameter.finProductCodeView': finProductCodeView,
	'parameter.finProductCodeEditNew': finProductCodeEditNew,
	'auth_control.auth_ControlMetadata': auth_ControlMetadata,
	'auth_control.auth_ControlList': auth_ControlList,
	'auth_control.auth_ControlView': auth_ControlView,
	'auth_control.auth_ControlEditNew': auth_ControlEditNew,
	'auth_control.auth_ControlHistMetadata': auth_ControlHistMetadata,
	'auth_control.auth_ControlHistList': auth_ControlHistList,
	'auth_control.auth_ControlHistView': auth_ControlHistView,
	'auth_control.auth_ControlHistEditNew': auth_ControlHistEditNew,
	'log.loginLogMetadata': loginLogMetadata,
	'log.loginLogList': loginLogList,
	'log.loginLogView': loginLogView,
	'log.loginLogEditNew': loginLogEditNew,
	'monitoring.digitalTransactionMetadata': digitalTransactionMetadata,
	'monitoring.digitalTransactionList': digitalTransactionList,
	'monitoring.digitalTransactionView': digitalTransactionView,
	'monitoring.digitalTransactionEditNew': digitalTransactionEditNew,
	'monitoring.batchTransactionMetadata': batchTransactionMetadata,
	'monitoring.batchTransactionList': batchTransactionList,
	'monitoring.batchTransactionView': batchTransactionView,
	'monitoring.batchTransactionEditNew': batchTransactionEditNew,
	'settings.cMSWorkflowMetadata': cMSWorkflowMetadata,
	'settings.cMSWorkflowList': cMSWorkflowList,
	'settings.cMSWorkflowView': cMSWorkflowView,
	'settings.cMSWorkflowEditNew': cMSWorkflowEditNew,
	'settings.cMSWorkflowTxTypeMetadata': cMSWorkflowTxTypeMetadata,
	'settings.cMSWorkflowTxTypeList': cMSWorkflowTxTypeList,
	'settings.cMSWorkflowTxTypeView': cMSWorkflowTxTypeView,
	'settings.cMSWorkflowTxTypeEditNew': cMSWorkflowTxTypeEditNew,
	'settings.cMSUserGroupMetadata': cMSUserGroupMetadata,
	'settings.cMSUserGroupList': cMSUserGroupList,
	'settings.cMSUserGroupView': cMSUserGroupView,
	'settings.cMSUserGroupEditNew': cMSUserGroupEditNew,
	'settings.cMSUserGroupEditStatus': cMSUserGroupEditStatus,
	'settings.cMSAccountAccessMetadata': cMSAccountAccessMetadata,
	'settings.cMSAccountAccessList': cMSAccountAccessList,
	'settings.cMSAccountAccessView': cMSAccountAccessView,
	'settings.cMSAccountAccessEditNew': cMSAccountAccessEditNew,
	'settings.cMSUserGroupLimitMetadata': cMSUserGroupLimitMetadata,
	'settings.cMSUserGroupLimitList': cMSUserGroupLimitList,
	'settings.cMSUserGroupLimitView': cMSUserGroupLimitView,
	'settings.cMSUserGroupLimitEditNew': cMSUserGroupLimitEditNew,
	'settings.cMSUserMetadata': cMSUserMetadata,
	'settings.cMSUserList': cMSUserList,
	'settings.cMSUserView': cMSUserView,
	'settings.cMSUserEditNew': cMSUserEditNew,
	'settings.cMSUserEditStatus': cMSUserEditStatus,
	'settings.notifikasi.settingNotificationMetadata': settingNotificationMetadata,
	'settings.notifikasi.settingNotification': settingNotification,
	'settings.notifikasi.settingNotificationList': settingNotificationList,
	'settings.notifikasi.settingNotificationEditNew': settingNotificationEditNew,
	'auth_control_corp.auth_ControlCorpMetadata': auth_ControlCorpMetadata,
	'auth_control_corp.auth_ControlCorpList': auth_ControlCorpList,
	'auth_control_corp.auth_ControlCorpView': auth_ControlCorpView,
	'auth_control_corp.auth_ControlCorpEditNew': auth_ControlCorpEditNew,
	'auth_control_corp.auth_ControlHistCorpMetadata': auth_ControlHistCorpMetadata,
	'auth_control_corp.auth_ControlHistCorpList': auth_ControlHistCorpList,
	'auth_control_corp.auth_ControlHistCorpView': auth_ControlHistCorpView,
	'auth_control_corp.auth_ControlHistCorpEditNew': auth_ControlHistCorpEditNew,
	'log_corp.loginLogCorpMetadata': loginLogCorpMetadata,
	'log_corp.loginLogCorpList': loginLogCorpList,
	'log_corp.loginLogCorpView': loginLogCorpView,
	'log_corp.loginLogCorpEditNew': loginLogCorpEditNew,
	'monitoring_corp.digitalTransactionCorpMetadata': digitalTransactionCorpMetadata,
	'monitoring_corp.digitalTransactionCorpList': digitalTransactionCorpList,
	'monitoring_corp.digitalTransactionCorpView': digitalTransactionCorpView,
	'monitoring_corp.digitalTransactionCorpEditNew': digitalTransactionCorpEditNew,
	'monitoring_corp.batchTransactionCorpMetadata': batchTransactionCorpMetadata,
	'monitoring_corp.batchTransactionCorpList': batchTransactionCorpList,
	'monitoring_corp.batchTransactionCorpView': batchTransactionCorpView,
	'monitoring_corp.batchTransactionCorpEditNew': batchTransactionCorpEditNew,
	'monitoring_corp.detailBatchTransactionCorpView': detailBatchTransactionCorpView,

	// new module
	'corporate.corporateAccountEditPermission': corporateAccountEditPermission,
	'application.changePasswordMetadata': changePasswordMetadata,
	'application.changePassword': changePassword,

	//tags
	'settings.tags.settingTagsMetadata': settingTagsMetadata,
	'settings.tags.settingTagsView': settingTagsView,
	'settings.tags.settingTags': settingTags,
	'settings.tags.formAddTags': formAddTags,

	'settings.autosweep.autosweep-metadata': autosweepMetadata,
	'settings.autosweep.autosweep-form': autosweepForm,
	'settings.autosweep.autosweep-list': autosweepList
};

const staticComponents = {
	Button,
	DatePicker,
	SearchBox,
	Table,
	Pagination,
	Filter,
	FormLabel,
	RadioIcon,
	Radio,
	InputGroupButton,
	PageTitle,
	FilterActionButtons,
	ModalHeader,
	ModalBody,
	Input,
	Dropdown,
	ReactIconFa,
	ReactIconSi,
	Upload,
	UploadWithPreview,
	ReactIconGr,
	RadioGroup,
	Checkbox,
	Validator,
	AlertComponent,
};
setStaticComponents(staticComponents);

initVisualLib({
	Menu,
	SubMenu,
	MenuItem,
	Divider,
});

initGlobalModules({
	dswidgetex: { DateFieldDataInput },
	ndswidget: NDswidget,
	NDataBrowser,
});

function AppFormContainer(props) {
	return (
		<div>
			{/* <FrameSelButtons treeName={FRAME_CONTAINER_PATH} /> */}
			<AppFrame treeName={FRAME_CONTAINER_PATH} headerComponent={() => ''} sender="menu" />
		</div>
	);
}

function AppPage(props) {
	const [state, setState] = React.useState({
		isError: false,
		isLoading: true,
		errMsg: '',
		mainAppForm: undefined,
	});

	const [, appAction] = useStdAppContext();

	useEffect(() => {
		(async function () {
			// register static pages / modules
			await appAction.registersStaticModules(staticModules);

			// loading main module
			setState({ isError: false, isLoading: true });
			try {
				const mainAppForm = await appAction.fetchAndExecModule('main');

				setState({ isError: false, isLoading: false, mainAppForm });
			} catch (err) {
				setState({
					isError: true,
					isLoading: false,
					errMsg: err.message,
				});
			}
		})();
	}, []);

	return (
		<div>
			{state.isError ? (
				<span>{state.errMsg}</span>
			) : state.mainAppForm ? (
				<state.mainAppForm frameInstanceName={props.frameInstanceName} AppFormContainer={AppFormContainer} />
			) : (
				<PageLoadingIndicator />
			)}
		</div>
	);
}

export default AppPage;
