/*
  DAFReact framework module - Application form (hook)
  appname: finProductCode
  filename: finProductCodeEdit.js
  moduleid: finProductCodeEdit
  author: IK
*/

import React from 'react'; //--DAFReact: development

// 'use strict'; //--DAFReact: deploy

export function ModuleDefinition () { //--DAFReact: development

// (function () { //--DAFReact: deploy

  async function getImports (React, globals) {

    // checking required libraries, may also include asynchronously loading other remote module, using appAction.loadModule
    const { _moduleId, StdAppAction, appAction, jsdset, dswidget } = globals
    if ( !_moduleId || !StdAppAction || !appAction || !jsdset || !dswidget) {
      throw new Error('One of required components (_moduleId, StdAppAction, appAction, jsdset, dswidget) not found in globals')
    }

    /* other required modules are asynchronously loaded here */
    const { metadata, initialData, serverDataMapping, editUIData } = await appAction.fetchAndExecModule('parameter.finProductCodeMetadata')

    const {PanelDataDisplay, FieldDataDisplay, PanelButton, FieldDataInput} = dswidget
    
    function componentFactory (params) {

      function AppForm (props) {

        const dataContext = React.useMemo(() => jsdset.dsetCreateContext(), [])
        const DSetProvider = React.useMemo(() => jsdset.dsetMetaProvider(dataContext, metadata, initialData, editUIData), [])
        return <DSetProvider>
          <AppFormUI dataContext={dataContext} {...props}/> {/* any other props will be passed down */}
        </DSetProvider>
      }

      function AppFormUI (props) {

        const [state, setState] = React.useState({isErr: false, errMessage: '', isEditing: false})

        // bind controls to _moduleId and _authToken
        const vComps = React.useMemo(
          () => appAction.connect({PanelDataDisplay, FieldDataDisplay, PanelButton, FieldDataInput}, { _moduleId, _getToken: () => props._authToken }), 
          []
        )

        // bind controls to datasets
        const [mainComps] = React.useMemo(
          () => [
            jsdset.connect({context: props.dataContext, dsetPath: 'main'}, vComps),
          ],
          [props.dataContext, vComps]
        )

        // obtain action objects from data context
        const [, dsMainAction, dsMainProxy] = jsdset.useDSetContext(props.dataContext, 'main')

        // load data function
        const loadData = React.useCallback(async () => {
          setState((state) => ({...state, isErr: false, errMessage: ''}))
      
          try {
            const response = await appAction.fetchResource(_moduleId, 'single_data', 'dataSO', props._authToken, {product_code: props.product_code}, true)
            dsMainAction.loadStore(response, 'std', serverDataMapping, true)
            dsMainAction.recalcFormulas()
          }
          catch(err) {
            setState((prevState) => ({...prevState, isErr: true, errMessage: err.message}))
            return
          }    
        }, [dsMainAction, props._authToken, props.product_code])

        // set event on component mounting
        React.useEffect(() => {
          (async function () {
            if (props.uiMode == 'edit')
              await loadData()
            else
              dsMainAction.addRow({})
          })()
        }, [dsMainAction, loadData, props.uiMode])

        // UI event handlers
        const switchEditClick = () => {
          //
          setState((prevState) => ({...prevState, isEditing: !prevState.isEditing}))
        }

        const saveDataClick = async () => {
          try {
            var dataUnload = dsMainProxy.unloadStore(serverDataMapping, { includeLoadedRows: false, includeDeletedRows: true })
            await appAction.postData(_moduleId, 'saveData', props._authToken, dataUnload)
            appAction.frameAction.closeModal()
          }
          catch(err) {
            await appAction.frameAction.showMessage(err.message, 'Error save data', {messageType: 'error'})
          }
        }

        // render
        return (
          <div>
            <br />
            {/* lookup behavior of customer no is automatically set based on uiData */}
            product_code <mainComps.FieldDataInput fieldName="product_code" /><br />
            description <mainComps.FieldDataInput fieldName="description" /><br />
            <br />
            <button onClick={saveDataClick}>Save</button>
            <div style={{display: state.isErr ? "block" : "none"}}>{state.errMessage}</div>
          </div>
        )
      }
      
      // return value may be different depending on params
      return React.memo(AppForm)
    }

    return { componentFactory }
  }

  async function initModuleF(aReact, globals) {
    return await getImports(aReact, globals)
  }

  return initModuleF
// })()  //--DAFReact: deploy

} //--DAFReact: development

