import React from 'react'; //--DAFReact: development

// 'use strict'; //--DAFReact: deploy

export function ModuleDefinition() {
	//--DAFReact: development

	// (function () { //--DAFReact: deploy

	async function getImports(React, globals) {
		// checking required libraries, may also include asynchronously loading other remote module, using appAction.loadModule
		const {
			_moduleId,
			StdAppAction,
			AppFrameAction,
			appAction,
			jsdset,
			dswidget,
			DataBrowser,
			staticComponents: {
				Validator: { isExist },
			},
		} = globals;
		if (
			!StdAppAction ||
			!AppFrameAction ||
			!appAction ||
			!jsdset ||
			!dswidget ||
			!DataBrowser
		) {
			throw new Error(
				'One of required components (StdAppAction, AppFrameAction, appAction, jsdset, dswidget) not found in globals',
			);
		}

		if (!_moduleId) {
			throw new Error('_moduleId not defined');
		}

		function componentFactory(params) {
			const metadata = {
				cMSUserGroupLimit: {
					fields: [
						{
							name: 'corporate_code',
							type: 'string',
							title: 'corporate_code',
							length: 10,
						},
						{
							name: 'group_name',
							type: 'string',
							title: 'group_name',
							length: 20,
						},
						{
							name: 'group_id',
							type: 'string',
							title: 'group_id',
							length: 20,
						},
						{
							name: 'tx_code',
							type: 'string',
							title: 'tx_code',
							length: 10,
							validator: (value, fieldName) => {
								let check = isExist(value, 'Transaksi', 1, 10);
								return check;
							},
						},
						{
							name: 'tx_desc',
							type: 'string',
							title: 'tx_desc',
						},
						{
							name: 'is_blocked',
							type: 'string',
							title: 'is_blocked',
						},
						{
							name: 'description',
							type: 'string',
							title: 'description',
							length: 20,
						},
						{
							name: 'max_debit',
							type: 'int',
							title: 'max_debit',
						},
						{
							name: 'max_debit_daily',
							type: 'int',
							title: 'max_debit_daily',
						},
						{
							name: 'max_count',
							type: 'int',
							title: 'max_count',
						},
					],

					indexes: ['corporate_code'],
				},
			};

			const initialData = {
				'main:cMSUserGroupLimit': [],
			};

			const serverDataMapping = {
				data: {
					dset: 'main',
					type: 'cMSUserGroupLimit',
					fieldMapping: {
						'*': true, // allow default fields
					},
				},
			};

			const editUIData = {
				cMSUserGroupLimit: {
					fields: {
						tx_code: {
							lookup: {
								style: {
									input: true,
									button: true,
								},
								dataId: 'listTxCode',
								selFields: ['tx_code', 'description'],
								fieldMap: {
									tx_code: 'tx_code',
									description: 'description',
								},
							},
						},
						is_blocked: {
							inputType: 'radio',
							type: 'icon',
							variant: 'bordered',
							dataSets: {
								F: 'Diizinkan',
								T: 'Tidak Diizinkan',
							},
							initialValue: 'F',
						},
						max_debit: { thousand: true, decimal: 2 },
						max_debit_daily: { thousand: true, decimal: 2 },
						max_count: { thousand: true, decimal: 0 },
					},
				},
			};

			// return value may be different depending on params
			return { metadata, initialData, serverDataMapping, editUIData };
		}

		return { componentFactory };
	}

	async function initModuleF(aReact, globals) {
		return await getImports(aReact, globals);
	}

	return initModuleF;
	// })()  //--DAFReact: deploy
} //--DAFReact: development
