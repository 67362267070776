/*
  DAFReact framework module - Application form (hook)
  appname: cMSUser
  filename: cMSUserList.js
  moduleid: cMSUserList
  author: IK
*/

// 'use strict';

export function ModuleDefinition() {
	//--DAFReact: development

	// (function () { //--DAFReact: deploy

	async function getImports(React, globals) {
		const {
			_moduleId,
			StdAppAction,
			AppFrameAction,
			appAction,
			jsdset,
			dswidget,
			NDataBrowser,
			frameAction,
			staticComponents: { Button, PageTitle, SearchBox, Filter, ReactIconFa, ReactIconSi, Dropdown, FilterActionButtons, AlertComponent },
		} = globals;
		if (!_moduleId || !StdAppAction || !AppFrameAction || !appAction || !jsdset || !dswidget || !NDataBrowser) {
			throw new Error(
				'One of required components (_moduleId, StdAppAction, AppFrameAction, appAction, jsdset, dswidget, _moduleId) not found in globals',
			);
		}

		const { FaPlusCircle, FaRetweet } = ReactIconFa;
		const { SiMicrosoftexcel } = ReactIconSi;

		const { metadata, initialData, serverDataMapping, editUIData } = await appAction.fetchAndExecModule('settings.cMSUserMetadata');

		function componentFactory(params) {
			function AppForm(props) {
				const [state, setState] = React.useState({
					popupComponent: undefined,
				});

				const dsetProvider = React.useMemo(() => jsdset.dsetEmptyProvider(), []);

				const initialFilter = {
					start_date: new Date(),
					end_date: new Date(),
				};
				const [filter, setFilter] = React.useState(initialFilter);

				React.useEffect(() => {
					(async function () {
						const popupComponent = await appAction.fetchMenu(_moduleId, 'popup_menu', props._authToken);
						setState({ popupComponent });
					})();
				}, [props._authToken]);

				const handleAddButton = React.useCallback(async () => {
					const { _authToken, componentClass } = await appAction.fetchFrameComponentWithToken(
						'settings.cMSUserEditNew',
						{},
						{},
						{
							menuId: 'popup_menu',
							menuModuleId: _moduleId,
							key: 'mnuAddCMSUser',
						},
					);

					if (componentClass && _authToken) {
						const modalshow = await frameAction.showModalAsync({
							headerProps: {
								title: 'Registrasi User Baru',
								icon: 'FaUserPlus',
							},
							contentClass: componentClass,
							contentProps: {
								_authToken,
								uiMode: 'add',
							},
							size: 'large',
						});
					}
				}, []);

				const AlertHandler = async (msg, type, body) => {
					await frameAction.showModal({
						contentClass: props => <AlertComponent {...props} title={msg} type={type} body={body} />,
						size: 'small',
					});
				};

				const confirmComponent = ({ message, closeModal }) => {
					return (
						<div>
							<div
								style={{
									paddingTop: '6rem',
									paddingBottom: '6rem',
									marginBottom: 15,
									fontSize: '1.2rem',
								}}
							>
								{message}
							</div>
							<div
								style={{
									display: 'flex',
									justifyContent: 'flex-end',
									marginTop: 30,
								}}
							>
								<Button
									type="bordered"
									style={{
										marginRight: 10,
									}}
									onClick={() => closeModal(false)}
								>
									Batal
								</Button>
								<Button onClick={() => closeModal(true)}>
									Proses
								</Button>
							</div>
						</div>
					);
				};


				const ActionButtons = React.useCallback(({ clearFilter, params }) => {
					return [
						{
							type: 'bordered',
							name: 'Export Excel',
							onClick: async () => {
								try {
									await appAction.postData(
										_moduleId,
										'exportList',
										props._authToken,
										{
											...params,
											...filter,
										},
										{},
									);
								} catch (error) {
									AlertHandler(error.message, 'alert');
								}
							},
							render: () => <SiMicrosoftexcel />,
						},
						{
							type: 'bordered',
							name: 'Reload',
							onClick: () => {
								setFilter(initialFilter);
								clearFilter();
							},
							render: () => <FaRetweet />,
						},
					];
				}, []);

				return (
					<>
						<PageTitle title="Pengguna" subtitle="Pengaturan" style={{ marginBottom: `1.5rem` }}>
							<Button onClick={handleAddButton}>
								<FaPlusCircle /> Buat Data Baru
							</Button>
						</PageTitle>
						<NDataBrowser
							stdAppAction={appAction}
							moduleId={_moduleId}
							dataId="listSO"
							dataProvider={dsetProvider}
							dsetPath="main"
							popupComponent={props => (<state.popupComponent {...props} confirmComponent={confirmComponent} />)}
							authToken={props._authToken}
							uiData={editUIData}
							filterComponent={({ sortFields, changeParams, params, clearFilter }) => {
								return (
									<Filter>
										<Dropdown
											label="Sort and search: "
											onChange={e =>
												changeParams({
													sortField: e.target.value,
												})
											}
											item={sortFields}
											value={params.sortField}
										/>

										<SearchBox
											style={{
												marginLeft: 5,
											}}
											handleSearch={src => changeParams({ src })}
											srcText={params.src}
										/>
										<div
											style={{
												height: `100%`,
												backgroundColor: `#e6e6e6`,
												width: `1px`,
												margin: `0 1rem`,
											}}
										></div>

										<FilterActionButtons
											buttons={ActionButtons({
												clearFilter,
												params,
											})}
										/>
									</Filter>
								);
							}}
						/>
					</>
				);
			}

			return React.memo(AppForm);
		}

		return { componentFactory };
	}

	return async (aReact, globals) => {
		return await getImports(aReact, globals);
	};

	// })()  //--DAFReact: deploy
} //--DAFReact: development
