/*
  DAFReact framework module - Application form (class)
  appname: finProductCode
  filename: finProductCodeView.js
  moduleid: finProductCodeView
  author: IK
*/

import React from 'react'; //--DAFReact: development

// 'use strict'; //--DAFReact: deploy

export function ModuleDefinition() {
	//--DAFReact: development

	// (function () { //--DAFReact: deploy

	async function getImports(React, globals) {
		// checking required libraries, may also include asynchronously loading other remote module, using appAction.loadModule
		const {
			_moduleId,
			StdAppAction,
			AppFrameAction,
			appAction,
			jsdset,
			dswidget,
			staticComponents: { FormLabel },
		} = globals;
		if (
			!StdAppAction ||
			!AppFrameAction ||
			!appAction ||
			!jsdset ||
			!dswidget
		) {
			throw new Error(
				'One of required components (StdAppAction, AppFrameAction, appAction, jsdset, dswidget) not found in globals',
			);
		}

		/* other required modules are asynchronously loaded here */
		const { metadata, initialData, serverDataMapping } =
			await appAction.fetchAndExecModule(
				'parameter.finProductCodeMetadata',
			);

		const {
			PanelDataDisplay,
			FieldDataDisplay,
			PanelButton,
			FieldDataInput,
			GridDataDisplay,
		} = dswidget;

		function componentFactory(params) {
			class FinProductCodeView extends React.PureComponent {
				constructor(props) {
					super(props);
					this.dsMainAction = null;
					this.appAction = appAction;
					this.dataContext = jsdset.dsetCreateContext();
					this.DSetProvider = jsdset.dsetMetaProvider(
						this.dataContext,
						metadata,
						initialData,
					);
					this.state = { isErr: false, errMessage: '' };
				}

				async loadData(e) {
					var prevState = this.state;
					this.setState({
						...prevState,
						isErr: false,
						errMessage: '',
					});
					console.log(
						'Loading FinProductCode data ' + params.product_code,
					);

					try {
						var response = await this.appAction.fetchResource(
							_moduleId,
							'single_data',
							'dataSO',
							this.props._authToken,
							{ product_code: params.product_code },
						);
						this.dsMainAction.loadStore(
							response,
							'std',
							serverDataMapping,
							true,
						);
					} catch (err) {
						this.setState({
							...prevState,
							isErr: true,
							errMessage: err.message,
						});
						return;
					}
				}

				async componentDidMount() {
					/* load menu components or data from server here */
					await this.loadData();
				}

				render() {
					var vcomps = {
						PanelDataDisplay,
						FieldDataDisplay,
						PanelButton,
						FieldDataInput,
					}; // object of unconnected visual components

					var mainComps = jsdset.connectComponents(
						{ context: this.dataContext, dsetPath: 'main' },
						vcomps,
					);
					const DSetAction = jsdset.DSetAction;
					const DSetProvider = this.DSetProvider;

					return (
						<DSetProvider>
							<DSetAction
								ref={val => {
									this.dsMainAction = val;
								}}
								context={this.dataContext}
								dsetPath="main"
							/>
							<div>
								<h5
									style={{
										color: '#b7282d',
									}}
								>
									Data Jenis Produk
								</h5>
								<br />
								{/* <mainComps.PanelDataDisplay delFields={[]} /> */}
								<FormLabel label="Kode Produk :">
									<b>
										<mainComps.FieldDataDisplay fieldName="product_code" />
									</b>
								</FormLabel>
								<FormLabel label="Kode Transaksi :">
									<b>
										<mainComps.FieldDataDisplay fieldName="tx_code" />
									</b>
								</FormLabel>
								<FormLabel label="Nama Transaksi :">
									<b>
										<mainComps.FieldDataDisplay fieldName="tx_desc" />
									</b>
								</FormLabel>
								<FormLabel label="Deskripsi :">
									<b>
										<mainComps.FieldDataDisplay fieldName="product_desc" />
									</b>
								</FormLabel>
								<br />
								<div
									style={{
										display: this.state.isErr
											? 'block'
											: 'none',
									}}
								>
									{this.state.errMessage}
								</div>
							</div>
						</DSetProvider>
					);
				}
			}

			// return value may be different depending on params
			return FinProductCodeView;
		}

		return { componentFactory };
	}

	async function initModuleF(aReact, globals) {
		return await getImports(aReact, globals);
	}

	return initModuleF;
	// })()  //--DAFReact: deploy
} //--DAFReact: development
