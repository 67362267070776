import React, { useEffect } from 'react';
import './Login.scss';
import Button from '../../Button/Button';
import FormLabel from '../../Forms/FormLabel/FormLabel';
import InputGroup from '../../InputGroupIcon/InputGroupIcon';
import { FaTimes, FaCheck, FaLock, FaEnvelope } from 'react-icons/fa';
import { StdAppAction } from '../../../modules/com.ihsan/appdata.js';
import AlertComponent from '../../AlertComponent/AlertComponent';
import { isPasword } from '../../Validator/validatorInput';

const Reset = props => {
    const refStdAppAction = React.useRef(null);
    const refNewPasswordInput = React.useRef(null);
    const refConfPasswordInput = React.useRef(null);

    useEffect(() => {
    }, []);

    const resetPassword = async () => {
        try {
            if (refNewPasswordInput.current.value == '' || refConfPasswordInput.current.value == '') {
                throw "Silahkan masukan password & konfirmasi password"
            }
            if (refNewPasswordInput.current.value !== refConfPasswordInput.current.value) {
                throw "Konfirmasi password salah"
            }
            let checkPwd = isPasword(refNewPasswordInput.current.value)
            if (!(checkPwd[0])) {
                throw checkPwd[1]
            }
            var [response, isError, errMessage] = await refStdAppAction.current.apiRequest(
                'app/forgotpassword?request=save_reset_password',
                {
                    'app_id': props.app_id,
                    'session_id': props.token,
                    'new_password': refNewPasswordInput.current.value,
                    'confirm_new_password': refConfPasswordInput.current.value
                },
                false,
                'POST',
            );
            if (response.status !== '000') {
                throw response.description || response.err_info
            } else {
                window.location.href = "/";
                alert(response.description)
                await AlertHandler(response.description, 'success')
            }
        } catch (error) {
            // alert(error)
            AlertHandler(error, 'warning')
        }
    }

    const AlertHandler = async (msg, type) => {
		await refStdAppAction.current.frameAction.showModal({
			contentClass: props => <AlertComponent {...props} title={msg} type={type} />,
			size: 'small',
		});
    }

    return (
        <>
            <StdAppAction ref={refStdAppAction} />
            <center className="title">Reset Password</center>
            <form

                style={{
                    paddingBottom: 10,
                }}
            >
                <FormLabel label="Kata Sandi Baru">
                    <InputGroup
                        icon={<FaLock />}
                        reference={refNewPasswordInput}
                        onChange={() => { }}
                        name="pswbr"
                        // disabled={isLoading}
                        id="pswbr"
                        type="password"
                    ></InputGroup>
                </FormLabel>
                <FormLabel label="Konfirmasi Kata Sandi">
                    <InputGroup
                        icon={<FaLock />}
                        reference={refConfPasswordInput}
                        onChange={() => { }}
                        name="cnfrmpsw"
                        // disabled={isLoading}
                        id="cnfrmpsw"
                        type="password"
                    ></InputGroup>
                </FormLabel>
            </form>
            <Button
                style={{ width: '100%' }}
                // reference={refBtnLogin}
                onClick={resetPassword}
            // disabled={isLoading}
            >
                Reset
            </Button>
			<br />
			<center>
				<a href="/">Kembali</a>
			</center>
        </>
    );
};

export default Reset;
