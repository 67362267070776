import React from 'react'; //--DAFReact: development

// 'use strict'; //--DAFReact: deploy

export function ModuleDefinition () { //--DAFReact: development

// (function () { //--DAFReact: deploy

  async function getImports (React, globals) {

    // checking required libraries, may also include asynchronously loading other remote module, using appAction.loadModule
    const { _moduleId, StdAppAction, AppFrameAction, appAction, jsdset, dswidget, DataBrowser } = globals
    if (!StdAppAction || !AppFrameAction || !appAction || !jsdset || !dswidget || !DataBrowser) {
      throw new Error('One of required components (StdAppAction, AppFrameAction, appAction, jsdset, dswidget) not found in globals')
    }

    if (!_moduleId) {
      throw new Error('_moduleId not defined')
    }

    function componentFactory (params) {

      const metadata = {
        loginLogCorp: {
          fields: [
            {name: 'id_login', type: 'int', title: 'id_login'},
            {name: 'ref_number', type: 'string', title: 'ref_number', length:12},
            {name: 'global_uid', type: 'string', title: 'global_uid', length:20},
            {name: 'remote_address', type: 'string', title: 'remote_address', length:32},
            {name: 'login_datetime', type: 'date', title: 'login_datetime'},
            {name: 'logout_datetime', type: 'date', title: 'logout_datetime'},
            {name: 'geolocation_info', type: 'string', title: 'geolocation_info', length:50},
            {name: 'otp_ref', type: 'string', title: 'otp_ref', length:12},
            {name: 'access_menu', type: 'string', title: 'access_menu', length:50},
            {name: 'authorized_flag', type: 'string', title: 'authorized_flag', length:1},
            {name: 'corporate_code', type: 'string', title: 'corporate_code', length:20},
            {name: 'user_id', type: 'string', title: 'user_id', length:20}
          ],
  
          indexes: ['id_login']
        },
      
      }
      
      const initialData = {
        'main:loginLogCorp': []
      }
  
      const serverDataMapping = {
        data: {
          dset: 'main',
          type: 'loginLogCorp',
          fieldMapping: {
            '*': true, // allow default fields
          }
        }
      }

      const editUIData = {
        loginLogCorp: {
          fields: {
          },
        },
      }
  
      // return value may be different depending on params
      return { metadata, initialData, serverDataMapping, editUIData }
    }

    return { componentFactory }
  }

  async function initModuleF(aReact, globals) {
    return await getImports(aReact, globals)
  }

  return initModuleF
// })()  //--DAFReact: deploy

} //--DAFReact: development

