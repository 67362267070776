/*
  DAFReact framework module - Application form (hook)
  appname: cMSUserGroupLimit
  filename: cMSUserGroupLimitList.js
  moduleid: cMSUserGroupLimitList
  author: IK
*/

// 'use strict';

export function ModuleDefinition() {
	//--DAFReact: development

	// (function () { //--DAFReact: deploy

	async function getImports(React, globals) {
		const {
			_moduleId,
			StdAppAction,
			AppFrameAction,
			appAction,
			jsdset,
			dswidget,
			NDataBrowser,
			frameAction,
			staticComponents: {
				FilterActionButtons,
				Filter,
				ReactIconFa: { FaRetweet, FaPlusCircle },
				Button,
				PageTitle,
				Dropdown,
				SearchBox,
			},
		} = globals;
		if (
			!_moduleId ||
			!StdAppAction ||
			!AppFrameAction ||
			!appAction ||
			!jsdset ||
			!dswidget ||
			!NDataBrowser
		) {
			throw new Error(
				'One of required components (_moduleId, StdAppAction, AppFrameAction, appAction, jsdset, dswidget, _moduleId) not found in globals',
			);
		}

		const { editUIData } = await appAction.fetchAndExecModule(
			'settings.cMSUserGroupLimitMetadata',
		);

		function componentFactory(params) {
			function AppForm(props) {
				const [state, setState] = React.useState({
					popupComponent: undefined,
				});

				const dsetProvider = React.useMemo(() => {
					return jsdset.dsetEmptyProvider();
				}, []);

				React.useEffect(() => {
					(async function () {
						const popupComponent = await appAction.fetchMenu(
							_moduleId,
							'popup_menu',
							props._authToken,
						);
						setState({ ...state, popupComponent });
					})();
				}, [props._authToken]);

				const handleAddButton = async () => {
					const { _authToken, componentClass } =
						await appAction.fetchFrameComponentWithToken(
							'settings.cMSUserGroupLimitEditNew',
							{
								corporate_code: props.corporate_code,
								group_id: props.group_id,
							},
							{},
							{
								menuId: 'popup_menu',
								menuModuleId: _moduleId,
								key: 'mnuAddCMSUserGroupLimit',
							},
						);

					if (componentClass && _authToken)
						await frameAction.showModalAsync({
							headerProps: {
								title: 'Tambah User Group Limit',
								icon: 'FaUserPlus',
							},
							contentClass: componentClass,
							contentProps: {
								_authToken,
								uiMode: 'add',
								corporate_code: props.corporate_code,
								group_id: props.group_id,
							},
							size: 'large',
						});
				};

				const ActionButtons = React.useCallback(clearFilter => {
					return [
						{
							type: 'bordered',
							name: 'Reload',
							onClick: () => clearFilter(),
							render: () => <FaRetweet />,
						},
					];
				});

				return (
					<>
						<PageTitle title={props.corporate_code + ' - ' + props.corporate_name}>
							<Button onClick={handleAddButton}>
								<FaPlusCircle /> Buat Limit Baru
							</Button>
						</PageTitle>

						<NDataBrowser
							stdAppAction={appAction}
							moduleId={_moduleId}
							dataId="listSO"
							dataProvider={dsetProvider}
							authToken={props._authToken}
							dsetPath="main"
							params={{ corporate_code: props.corporate_code }}
							uiData={editUIData}
							filterComponent={({
								sortFields,
								clearFilter,
								changeParams,
								params,
							}) => {
								return (
									<Filter>
										<Dropdown
											label="Urutkan dan Cari:"
											onChange={e =>
												changeParams({
													sortField: e.target.value,
												})
											}
											item={sortFields}
											value={params.sortField}
										/>
										<SearchBox
											style={{
												marginLeft: 5,
											}}
											handleSearch={src =>
												changeParams({ src })
											}
											srcText={params.src}
										/>
										<FilterActionButtons
											buttons={ActionButtons(clearFilter)}
										/>
									</Filter>
								);
							}}
							popupComponent={state.popupComponent}
						/>
					</>
				);
			}

			return React.memo(AppForm);
		}

		return { componentFactory };
	}

	return async (aReact, globals) => {
		return await getImports(aReact, globals);
	};

	// })()  //--DAFReact: deploy
} //--DAFReact: development
