// 'use strict';

export function ModuleDefinition() {
	//--DAFReact: development

	// (function () { //--DAFReact: deploy

	async function getImports(React, globals) {
		const {
			_moduleId,
			StdAppAction,
			AppFrameAction,
			appAction,
			jsdset,
			dswidget,
			NDataBrowser,
			frameAction,
			staticComponents: {
				Button,
				PageTitle,
				SearchBox,
				Filter,
				FilterActionButtons,
				ReactIconSi,
				ReactIconFa,
				RadioGroup,
				Dropdown,
			},
		} = globals;
		if (
			!_moduleId ||
			!StdAppAction ||
			!AppFrameAction ||
			!appAction ||
			!jsdset ||
			!dswidget ||
			!NDataBrowser
		) {
			throw new Error(
				'One of required components (_moduleId, StdAppAction, AppFrameAction, appAction, jsdset, dswidget, _moduleId) not found in globals',
			);
		}

		const { FaRetweet } = ReactIconFa;
		const { SiMicrosoftexcel } = ReactIconSi;

		function componentFactory(params) {
			function AppForm(props) {
				const [state, setState] = React.useState({
					popupComponent: undefined,
				});

				const dsetProvider = React.useMemo(() => {
					return jsdset.dsetEmptyProvider();
				}, []);

				React.useEffect(() => {
					(async function () {
						const popupComponent = await appAction.fetchMenu(
							_moduleId,
							'popup_menu',
							props._authToken,
						);

						setState({
							...state,
							popupComponent,
						});
					})();
				}, [props._authToken]);

				const ActionButtons = React.useCallback(clearFilter => {
					return [
						{
							type: 'bordered',
							name: 'Reload',
							onClick: () => clearFilter(),
							render: () => <FaRetweet />,
						},
					];
				}, []);

				return (
					<>
						<PageTitle
							subtitle="Setting"
							title="Setting Notifikasi"
						/>

						<NDataBrowser
							stdAppAction={appAction}
							moduleId={_moduleId}
							dataId="listSO"
							dataProvider={dsetProvider}
							authToken={props._authToken}
							dsetPath="main"
							filterComponent={({
								sortFields,
								clearFilter,
								changeParams,
								params,
							}) => {
								return (
									<Filter>
										<Dropdown
											label="Sort and search: "
											onChange={e =>
												changeParams({
													sortField: e.target.value,
												})
											}
											item={sortFields}
											value={params.sortField}
										/>

										<SearchBox
											style={{
												marginLeft: 5,
											}}
											handleSearch={src =>
												changeParams({ src })
											}
											srcText={params.src}
										/>
										<FilterActionButtons
											buttons={ActionButtons(clearFilter)}
										/>
									</Filter>
								);
							}}
							popupComponent={props => (
								<state.popupComponent {...props} />
							)}
						/>
					</>
				);
			}

			return React.memo(AppForm);
		}

		return { componentFactory };
	}

	return async (aReact, globals) => {
		return await getImports(aReact, globals);
	};

	// })()  //--DAFReact: deploy
} //--DAFReact: development
