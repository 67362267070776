/*
	DAFReact framework module - Application form (hook)
	appname: corporate
	filename: corporateEdit.js
	moduleid: corporateEdit
	author: IK
*/

import React from 'react'; //--DAFReact: development
// 'use strict'; //--DAFReact: deploy

export function ModuleDefinition() {
	//--DAFReact: development

	// (function () { //--DAFReact: deploy

	async function getImports(React, globals) {
		// checking required libraries, may also include asynchronously loading other remote module, using appAction.loadModule
		const {
			_moduleId,
			StdAppAction,
			appAction,
			jsdset,
			dswidget,
			ndswidget,
			dswidgetex,
			frameAction,
			staticComponents: {
				Button,
				FormLabel,
				ReactIconFa: { FaSearch },
				AlertComponent,
				Input,
			},
		} = globals;
		if (!_moduleId || !StdAppAction || !appAction || !jsdset || !dswidget) {
			throw new Error(
				'One of required components (_moduleId, StdAppAction, appAction, jsdset, dswidget) not found in globals',
			);
		}

		/* other required modules are asynchronously loaded here */
		const { metadata, initialData, serverDataMapping, editUIData } =
			await appAction.fetchAndExecModule('corporate.corporateMetadata');

		const { PanelDataDisplay, FieldDataDisplay, PanelButton } = dswidget;
		const { FieldDataInput } = ndswidget;
		const { DateFieldDataInput } = dswidgetex;

		function componentFactory(params) {
			function AppForm(props) {
				const dataContext = React.useMemo(
					() => jsdset.dsetCreateContext(),
					[],
				);
				const DSetProvider = React.useMemo(() => {
					return jsdset.dsetMetaProvider(
						dataContext,
						metadata,
						initialData,
						editUIData,
					);
				}, []);
				return (
					<DSetProvider>
						<AppFormUI dataContext={dataContext} {...props} />
						{/* any other props will be passed down */}
					</DSetProvider>
				);
			}

			function AppFormUI(props) {
				const [state, setState] = React.useState({
					isErr: false,
					errMessage: '',
					isEditing: false,
				});

				const ref_primary_cif_no = React.useRef(null);

				// bind controls to _moduleId and _authToken
				const vComps = React.useMemo(
					() =>
						appAction.connect(
							{
								PanelDataDisplay,
								FieldDataDisplay,
								PanelButton,
								FieldDataInput,
								DateFieldDataInput,
							},
							{ _moduleId, _getToken: () => props._authToken },
						),
					[],
				);

				// bind controls to datasets
				const [mainComps] = React.useMemo(
					() => [
						jsdset.connect(
							{ context: props.dataContext, dsetPath: 'main' },
							vComps,
						),
					],
					[props.dataContext, vComps],
				);

				// obtain action objects from data context
				const [, dsMainAction, dsMainProxy] = jsdset.useDSetContext(
					props.dataContext,
					'main',
				);

				// load data function
				const loadData = React.useCallback(async () => {
					setState(state => ({
						...state,
						isErr: false,
						errMessage: '',
					}));

					try {
						const response = await appAction.fetchResource(
							_moduleId,
							'single_data',
							'dataSO',
							props._authToken,
							{ corporate_code: params.corporate_code },
							true,
						);


						if (response?.data?.length > 0) {

							dsMainAction.loadStore(
								response,
								'std',
								serverDataMapping,
								true,
							);

							for (var x in response?.data) {
								dsMainAction.setFields({
									email_address:
										response?.data[0].corpemail_address,
									['user_name' + (parseFloat(x) + 1)]:
										response?.data[x]?.user_id,
									['user_id' + (parseFloat(x) + 1)]:
										response?.data[x]?.user_id,
									['handphone_no' + (parseFloat(x) + 1)]:
										response?.data[x]?.handphone_no,
									['cms_user_status' + (parseFloat(x) + 1)]:
										response?.data[x]?.cms_user_status,
									['email_address_user' + (parseFloat(x) + 1)]:
										response?.data[x]?.email_address,
									// ['password' + (parseFloat(x) + 1)]:
									// 	response?.data[x]?.enc_password,
									// ['confirmpassword' + (parseFloat(x) + 1)]:
									// 	response?.data[x]?.enc_password,
								});
							}

							dsMainAction.recalcFormulas();
						}
					} catch (err) {
						setState(prevState => ({
							...prevState,
							isErr: true,
							errMessage: err.message,
						}));
						return;
					}
				}, [dsMainAction, props._authToken, props.corporate_code]);

				// set event on component mounting
				React.useEffect(() => {
					(async function () {

						await loadData();

					})();
				}, [dsMainAction, loadData, props.uiMode]);



				const AlertHandler = async (msg, type, body) => {
					await frameAction.showModal({
						contentClass: props => (
							<AlertComponent
								{...props}
								title={msg}
								type={type}
								body={body}
							/>
						),
						size: 'small',
					});
				};

				const handleChangeInput = text => {
					dsMainAction.setFields({
						primary_cif_no: text,
						corporate_name: '',
						address: '',
						description: '',
					});
				};

				return (
					<div>
						<div
							style={{
								display: 'flex',
								width: '-webkit-fill-available',
							}}
						>
							<div
								style={{
									marginRight: 10,
									width: '-webkit-fill-available',
								}}
							>
								<FormLabel label="Type Rekening Korporat :">
									<b>
										{dsMainProxy.fields.is_va && dsMainProxy.fields.is_va !== '' ? <mainComps.FieldDataDisplay fieldName="is_va" /> : '-'}
									</b>
								</FormLabel>
								<FormLabel label={dsMainProxy.fields.is_va && dsMainProxy.fields.is_va == 'T' ? "Korporat" : "Nomor CIF"}>
									<b>
										{dsMainProxy.fields.primary_cif_no && dsMainProxy.fields.primary_cif_no !== '' ? <mainComps.FieldDataDisplay fieldName="primary_cif_no" /> : '-'}
									</b>
								</FormLabel>
								<FormLabel label="Kode Korporat :">
									<b>
										{dsMainProxy.fields.corporate_code && dsMainProxy.fields.corporate_code !== '' ? <mainComps.FieldDataDisplay fieldName="corporate_code" /> : '-'}
									</b>
								</FormLabel>
								<FormLabel label="Nama Korporat :">
									<b>
										{dsMainProxy.fields.corporate_name && dsMainProxy.fields.corporate_name !== '' ? <mainComps.FieldDataDisplay fieldName="corporate_name" /> : '-'}
									</b>
								</FormLabel>
								{/* <FormLabel label="Issuer ID :">
									<b>
										{dsMainProxy.fields.issuer_id && dsMainProxy.fields.issuer_id !== '' ? <mainComps.FieldDataDisplay fieldName="issuer_id" /> : '-'}
									</b>
								</FormLabel> */}
								<FormLabel label="Status Kunci :">
									<b>
										{dsMainProxy.fields.is_lock && dsMainProxy.fields.is_lock !== '' ? <mainComps.FieldDataDisplay fieldName="is_lock" /> : '-'}
									</b>
								</FormLabel>
							</div>
							<div
								style={{
									// marginRight: 10,
									width: '-webkit-fill-available',
								}}
							>
								<FormLabel label="Alamat :">
									<b>
										{dsMainProxy.fields.address && dsMainProxy.fields.address !== '' ? <mainComps.FieldDataDisplay fieldName="address" /> : '-'}

									</b>
								</FormLabel>
								<FormLabel label="Email :">
									<b>
										{dsMainProxy.fields.email_address && dsMainProxy.fields.email_address !== '' ? <mainComps.FieldDataDisplay fieldName="email_address" /> : '-'}
									</b>
								</FormLabel>
								<FormLabel label="Keterangan :">
									<b>
										{dsMainProxy.fields.description && dsMainProxy.fields.description !== '' ? <mainComps.FieldDataDisplay fieldName="description" /> : '-'}
									</b>
								</FormLabel>
								<FormLabel label="Status Korporat :">
									<b>
										{dsMainProxy.fields.corporate_status && dsMainProxy.fields.corporate_status !== '' ? <mainComps.FieldDataDisplay fieldName="corporate_status" /> : '-'}
									</b>
								</FormLabel>
								<FormLabel
									label="Limit transaksi massal"
									style={{
										// marginLeft: 10,
										width: '-webkit-fill-available',
									}}
								>
									<b>
										{dsMainProxy.fields.limit_trx_massal && dsMainProxy.fields.limit_trx_massal !== '' && dsMainProxy.fields.limit_trx_massal !== 0 ? <mainComps.FieldDataDisplay fieldName="limit_trx_massal" /> : '-'}
									</b>
								</FormLabel>
							</div>
						</div>

						<div
							style={{
								display: state.isErr ? 'block' : 'none',
							}}
						>
							{state.errMessage}
						</div>
					</div>
				);
			}

			// return value may be different depending on params
			return React.memo(AppForm);
		}

		return { componentFactory };
	}

	async function initModuleF(aReact, globals) {
		return await getImports(aReact, globals);
	}

	return initModuleF;
	// })()  //--DAFReact: deploy
} //--DAFReact: development
