import React from 'react'; //--DAFReact: development

// 'use strict'; //--DAFReact: deploy

export function ModuleDefinition() { //--DAFReact: development

  // (function () { //--DAFReact: deploy

  async function getImports(React, globals) {

    // checking required libraries, may also include asynchronously loading other remote module, using appAction.loadModule
    const { _moduleId, StdAppAction, AppFrameAction, appAction, jsdset, dswidget, DataBrowser,
      staticComponents: {
        Validator: { isExist, isEmail, isPasword, isPhone },
      }, } = globals
    if (!StdAppAction || !AppFrameAction || !appAction || !jsdset || !dswidget || !DataBrowser) {
      throw new Error('One of required components (StdAppAction, AppFrameAction, appAction, jsdset, dswidget) not found in globals')
    }

    if (!_moduleId) {
      throw new Error('_moduleId not defined')
    }

    function componentFactory(params) {

      const metadata = {
        cMSWorkflow: {
          fields: [
            { name: 'corporate_code', type: 'string', title: 'corporate_code', length: 20 },
            {
              name: 'workflow_code', type: 'string', title: 'workflow_code', length: 20, validator: (value, fieldName) => {
                let check = isExist(value, 'Kode Workflow', 3);
                return check;
              },
            },
            {
              name: 'workflow_name', type: 'string', title: 'workflow_name', length: 50, validator: (value, fieldName) => {
                let check = isExist(value, 'Nama Workflow');
                return check;
              },
            },
            { name: 'allowed_auth_group_id', type: 'string', title: 'allowed_auth_group_id', length: 20 },
            { name: 'required_num_auth', type: 'int', title: 'required_num_auth', length: 1, },
            { name: 'auth_level', type: 'string', title: 'auth_level', length: 1 },
            { name: 'allowed_release_group_id', type: 'string', title: 'allowed_release_group_id', length: 20 },
            { name: 'required_num_releaser', type: 'int', title: 'required_num_releaser', length: 1 },
            { name: 'release_level', type: 'string', title: 'release_level', length: 1 },
            { name: 'auth_need_otp', type: 'string', title: 'auth_need_otp', length: 1 },
            { name: 'release_need_otp', type: 'string', title: 'release_need_otp', length: 1 },
            { name: 'id_auth', type: 'int', title: 'id_auth', length: 1 },
          ],

          indexes: ['workflow_code']
        },

      }

      const initialData = {
        'main:cMSWorkflow': []
      }

      const serverDataMapping = {
        data: {
          dset: 'main',
          type: 'cMSWorkflow',
          fieldMapping: {
            '*': true, // allow default fields
          }
        }
      }

      const editUIData = {
        cMSWorkflow: {
          fields: {
            auth_need_otp: {
              inputType: 'radio',
              dataSets: {
                T: 'Yes',
                F: 'No'
              },
              initialValue: 'F',
              type: 'icon',
              variant: 'bordered',
            },
            release_need_otp: {
              inputType: 'radio',
              dataSets: {
                T: 'Yes',
                F: 'No'
              },
              initialValue: 'F',
              type: 'icon',
              variant: 'bordered',
            },
            required_num_auth: { thousand: true },
            required_num_releaser: { thousand: true }
          },
        },
      }

      // return value may be different depending on params
      return { metadata, initialData, serverDataMapping, editUIData }
    }

    return { componentFactory }
  }

  async function initModuleF(aReact, globals) {
    return await getImports(aReact, globals)
  }

  return initModuleF
  // })()  //--DAFReact: deploy

} //--DAFReact: development

