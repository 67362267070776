/*
  DAFReact framework module - Application form (hook)
  appname: batchTransaction
  filename: batchTransactionList.js
  moduleid: batchTransactionList
  author: IK
*/

// 'use strict';

export function ModuleDefinition () { //--DAFReact: development

// (function () { //--DAFReact: deploy

  async function getImports (React, globals) {

    const { _moduleId, StdAppAction, AppFrameAction, appAction, jsdset, dswidget, DataBrowser } = globals
    if (!_moduleId || !StdAppAction || !AppFrameAction || !appAction || !jsdset || !dswidget || !DataBrowser) {
      throw new Error('One of required components (_moduleId, StdAppAction, AppFrameAction, appAction, jsdset, dswidget, _moduleId) not found in globals')
    }

    function componentFactory (params) {

      function AppForm(props) {
        const [state, setState] = React.useState({
          popupComponent: undefined
        })

        const dsetProvider = React.useMemo(() => jsdset.dsetEmptyProvider(), [])

        React.useEffect(
          () => {
            (async function () {
              const popupComponent = await appAction.fetchMenu(_moduleId, 'popup_menu', props._authToken)
              setState({popupComponent})
            })()
          },
          [props._authToken]
        )

        return (
          <DataBrowser 
            stdAppAction={appAction}
            moduleId={_moduleId}
            dataId='listSO'
            dataProvider={dsetProvider} 
            useServerFields={true}
            dsetPath="main" 
            popupComponent={state.popupComponent}
            reqAuthToken={true}
            authToken={props._authToken}
          />
        )
      }

      return React.memo(AppForm)
    }

    return { componentFactory }
  }

  return async (aReact, globals) => { return await getImports(aReact, globals) }

// })()  //--DAFReact: deploy

} //--DAFReact: development

