/*
  DAFReact framework module - Application form (hook)
  appname: cMSWorkflowTxType
  filename: cMSWorkflowTxTypeEdit.js
  moduleid: cMSWorkflowTxTypeEdit
  author: IK
*/

//--DAFReact: development
// 'use strict'; //--DAFReact: deploy

export function ModuleDefinition() {
	//--DAFReact: development

	// (function () { //--DAFReact: deploy

	async function getImports(React, globals) {
		// checking required libraries, may also include asynchronously loading other remote module, using appAction.loadModule
		const {
			_moduleId,
			StdAppAction,
			appAction,
			jsdset,
			dswidget,
			ndswidget,
			frameAction,
			staticComponents: { Button, FormLabel, AlertComponent },
		} = globals;
		if (!_moduleId || !StdAppAction || !appAction || !jsdset || !dswidget) {
			throw new Error('One of required components (_moduleId, StdAppAction, appAction, jsdset, dswidget) not found in globals');
		}

		/* other required modules are asynchronously loaded here */
		const { metadata, initialData, serverDataMapping, editUIData } = await appAction.fetchAndExecModule('settings.cMSWorkflowTxTypeMetadata');

		const { PanelDataDisplay, PanelButton } = dswidget;
		const { FieldDataInput, FieldDataDisplay } = ndswidget;

		function componentFactory(params) {
			function AppForm(props) {
				const dataContext = React.useMemo(() => jsdset.dsetCreateContext(), []);
				const DSetProvider = React.useMemo(() => jsdset.dsetMetaProvider(dataContext, metadata, initialData, editUIData), []);
				return (
					<DSetProvider>
						<AppFormUI dataContext={dataContext} {...props} /> {/* any other props will be passed down */}
					</DSetProvider>
				);
			}

			function AppFormUI(props) {
				const [state, setState] = React.useState({
					isErr: false,
					errMessage: '',
					isEditing: false,
				});

				// bind controls to _moduleId and _authToken
				const vComps = React.useMemo(
					() =>
						appAction.connect(
							{
								PanelDataDisplay,
								FieldDataDisplay,
								PanelButton,
								FieldDataInput,
							},
							{ _moduleId, _getToken: () => props._authToken },
						),
					[],
				);

				// bind controls to datasets
				const [mainComps] = React.useMemo(
					() => [jsdset.connect({ context: props.dataContext, dsetPath: 'main' }, vComps)],
					[props.dataContext, vComps],
				);

				// obtain action objects from data context
				const [, dsMainAction, dsMainProxy] = jsdset.useDSetContext(props.dataContext, 'main');

				// load data function
				const loadData = React.useCallback(async () => {
					setState(state => ({
						...state,
						isErr: false,
						errMessage: '',
					}));
					try {
						const response = await appAction.fetchResource(
							_moduleId,
							'single_data',
							'dataSO',
							props._authToken,
							{
								corporate_code: props.corporate_code,
								tx_code: props.tx_code,
								product_code: props.product_code,
								workflow_code: props.workflow_code,
							},
							true,
						);
						if (response?.data?.length > 0) {
							dsMainAction.loadStore(response, 'std', serverDataMapping, true);
							dsMainAction.recalcFormulas();
						} else {
							appAction.frameAction.closeModal();
							handlePopup('warning', 'Data Tidak Bisa di Ubah');
						}
					} catch (err) {
						setState(prevState => ({
							...prevState,
							isErr: true,
							errMessage: err.message,
						}));
						return;
					}
				}, [dsMainAction, props._authToken, props.corporate_code]);

				// set event on component mounting
				React.useEffect(() => {
					(async function () {
						if (props.uiMode == 'edit') await loadData();
						else dsMainAction.addRow({});
					})();
				}, [dsMainAction, loadData, props.uiMode]);

				// UI event handlers
				const switchEditClick = () => {
					//
					setState(prevState => ({
						...prevState,
						isEditing: !prevState.isEditing,
					}));
				};

				const saveDataClick = async () => {
					try {
						const { fieldValidStates, fieldValidErrors } = dsMainProxy;
						const isInValid = Object.entries(fieldValidStates).filter(([i, v]) => {
							return !v;
						});

						if (isInValid.length > 0) throw new Error(fieldValidErrors[isInValid[0][0]]);

						var dataUnload = dsMainProxy.unloadStore(serverDataMapping, {
							includeLoadedRows: false,
							includeDeletedRows: true,
						});
						await appAction.postData(_moduleId, 'saveData', props._authToken, dataUnload);
						appAction.frameAction.closeModal();
						handlePopup('success', 'Simpan Data Berhasil', 'Data Perlu Diotorisasi');
					} catch (err) {
						handlePopup('alert', err.message);
					}
				};

				const handlePopup = (type, messages, body) => {
					frameAction.showModal({
						contentClass: props => <AlertComponent {...props} title={messages} type={type} body={body} />,
						size: 'small',
					});
				};

				// render
				return (
					<div>
						{/* lookup behavior of customer no is automatically set based on uiData */}
						<FormLabel label="Kode Transaksi" style={{ width: '100%' }}>
							<mainComps.FieldDataInput fieldName="tx_code" elProps={{ readOnly: true }} />
						</FormLabel>

						<FormLabel label="Kode Produk" style={{ width: '100%' }}>
							<mainComps.FieldDataInput fieldName="product_code" elProps={{ readOnly: true }} />
						</FormLabel>

						<FormLabel label="Kode Workflow" style={{ width: '100%' }}>
							<mainComps.FieldDataInput fieldName="workflow_code" elProps={{ readOnly: true }} />
						</FormLabel>
						<FormLabel label="Minimum Nominal Transaksi" style={{ width: '100%' }}>
							<mainComps.FieldDataInput fieldName="minimum_amount" />
						</FormLabel>
						<br />
						<div
							style={{
								display: `flex`,
								justifyContent: `flex-end`,
							}}
						>
							<Button onClick={() => appAction.frameAction.closeModal()} type="bordered" style={{ marginRight: 10 }}>
								Batalkan
							</Button>
							<Button onClick={saveDataClick}>Simpan Perubahan</Button>
						</div>
						<div style={{ display: state.isErr ? 'block' : 'none' }}>{state.errMessage}</div>
					</div>
				);
			}

			// return value may be different depending on params
			return React.memo(AppForm);
		}

		return { componentFactory };
	}

	async function initModuleF(aReact, globals) {
		return await getImports(aReact, globals);
	}

	return initModuleF;
	// })()  //--DAFReact: deploy
} //--DAFReact: development
