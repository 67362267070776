import React from 'react'; //--DAFReact: development

// 'use strict'; //--DAFReact: deploy

export function ModuleDefinition () { //--DAFReact: development

// (function () { //--DAFReact: deploy

  async function getImports (React, globals) {

    // checking required libraries, may also include asynchronously loading other remote module, using appAction.loadModule
    const { _moduleId, StdAppAction, AppFrameAction, appAction, jsdset, dswidget, DataBrowser } = globals
    if (!StdAppAction || !AppFrameAction || !appAction || !jsdset || !dswidget || !DataBrowser) {
      throw new Error('One of required components (StdAppAction, AppFrameAction, appAction, jsdset, dswidget) not found in globals')
    }

    if (!_moduleId) {
      throw new Error('_moduleId not defined')
    }

    function componentFactory (params) {

      const metadata = {
        batchTransaction: {
          fields: [
            {name: 'id_tx_draft', type: 'integer', title: 'id_tx_draft'},
            {name: 'ref_number', type: 'string', title: 'ref_number', length:12},
            {name: 'source_file_url', type: 'string', title: 'source_file_url', length:100},
            {name: 'file_format', type: 'string', title: 'file_format', length:1},
            {name: 'number_record', type: 'integer', title: 'number_record'},
            {name: 'conv_status', type: 'string', title: 'conv_status', length:1},
            {name: 'last_conv_error', type: 'string', title: 'last_conv_error', length:100},
            {name: 'last_conv_line', type: 'integer', title: 'last_conv_line'},
            {name: 'n_details', type: 'integer', title: 'n_details'},
            {name: 'exec_start_time', type: 'date', title: 'exec_start_time'},
            {name: 'target_tps', type: 'integer', title: 'target_tps'},
            {name: 'total_procssed', type: 'integer', title: 'total_procssed'},
            {name: 'total_success', type: 'integer', title: 'total_success'},
            {name: 'total_amount_and_fee', type: 'float', title: 'total_amount_and_fee'},
            {name: 'exec_last_seq', type: 'integer', title: 'exec_last_seq'},
            {name: 'conv_systemjob_refnumber', type: 'string', title: 'conv_systemjob_refnumber', length:10},
            {name: 'exec_systemjob_refnumber', type: 'string', title: 'exec_systemjob_refnumber', length:10},
            {name: 'is_closed', type: 'string', title: 'is_closed', length:10}
          ],
  
          indexes: ['id_tx_draft']
        },
      
      }
      
      const initialData = {
        'main:batchTransaction': []
      }
  
      const serverDataMapping = {
        data: {
          dset: 'main',
          type: 'batchTransaction',
          fieldMapping: {
            '*': true, // allow default fields
          }
        }
      }

      const editUIData = {
        batchTransaction: {
          fields: {
          },
        },
      }
  
      // return value may be different depending on params
      return { metadata, initialData, serverDataMapping, editUIData }
    }

    return { componentFactory }
  }

  async function initModuleF(aReact, globals) {
    return await getImports(aReact, globals)
  }

  return initModuleF
// })()  //--DAFReact: deploy

} //--DAFReact: development

