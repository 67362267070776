import React from 'react'; //--DAFReact: development

export function ModuleDefinition() {
	async function getImports(React, globals) {
		const {
			_moduleId,
			StdAppAction,
			appAction,
			jsdset,
			dswidget,
			ndswidget,
			frameAction,
			staticComponents: {
				FormLabel,
				Button,
				RadioGroup,
				AlertComponent,
				ReactIconFa: { FaCloudUploadAlt, FaFileDownload },
			},
		} = globals;
		if (!_moduleId || !StdAppAction || !appAction || !jsdset || !dswidget) {
			throw new Error('One of required components (_moduleId, StdAppAction, appAction, jsdset, dswidget) not found in globals');
		}

		/* other required modules are asynchronously loaded here */
		const { metadata, initialData, serverDataMapping, editUIData } = await appAction.fetchAndExecModule('settings.tags.settingTagsMetadata');

		const { PanelDataDisplay, FieldDataDisplay, PanelButton } = dswidget;

		const { FieldDataInput } = ndswidget;

		function componentFactory() {
			function AppForm(props) {
				const dataContext = React.useMemo(() => jsdset.dsetCreateContext(), []);
				const DSetProvider = React.useMemo(() => jsdset.dsetMetaProvider(dataContext, metadata, initialData, editUIData), []);
				return (
					<DSetProvider>
						<AppFormUI dataContext={dataContext} {...props} /> {/* any other props will be passed down */}
					</DSetProvider>
				);
			}

			function AppFormUI(props) {
				const [state, setState] = React.useState({
					isErr: false,
					errMessage: '',
					isEditing: false,
				});

				const vComps = React.useMemo(
					() =>
						appAction.connect(
							{
								PanelDataDisplay,
								FieldDataDisplay,
								PanelButton,
								FieldDataInput,
							},
							{ _moduleId, _getToken: () => props._authToken },
						),
					[],
				);

				// bind controls to datasets
				const [mainComps] = React.useMemo(() => [jsdset.connect({ context: props.dataContext, dsetPath: 'main' }, vComps)], [props.dataContext, vComps]);
				const [, dsMainAction, dsMainProxy] = jsdset.useDSetContext(props.dataContext, 'main');

				const loadData = React.useCallback(async () => {
					setState(state => ({
						...state,
						isErr: false,
						errMessage: '',
					}));

					try {
						const response = await appAction.fetchResource(_moduleId, 'single_data', 'dataTags', props._authToken, { tag_code: props.tag_code }, true);

						if (response?.data?.length > 0) {
							dsMainAction.loadStore(response, 'std', serverDataMapping, true);

							setState({
								...state,
								details: response?.data[0].details,
							});
						}
					} catch (err) {
						setState(prevState => ({
							...prevState,
							isErr: true,
							errMessage: err.message,
						}));
						return;
					}
				}, [dsMainAction, props._authToken, props.tag_code]);

				React.useEffect(() => {
					(async function () {
						await loadData();
					})();
				}, [props._authToken]);

				const deleteDataClick = async () => {
					try {
						var rsp = await appAction.postData(_moduleId, 'deleteData', props._authToken, { tag_code: props.tag_code }, true);
						if (rsp.status != '000') throw new Error(rsp.err_info);

						appAction.frameAction.closeModal();
						AlertHandler('Data berhasil dihapus', 'success', '');
					} catch (err) {
						AlertHandler(err.message, 'alert');
					}
				};

				const AlertHandler = async (msg, type, body) => {
					await frameAction.showModal({
						contentClass: props => <AlertComponent {...props} title={msg} type={type} body={body} />,
						size: 'small',
					});
				};

				return (
					<div>
						<div
							style={{
								display: `flex`,
								justifyContent: `center`,
								marginTop: 80,
							}}
						>
							<h3>Anda yakin ingin menghapus data ini?</h3>
						</div>

						<div
							style={{
								display: `flex`,
								justifyContent: `center`,
								marginTop: 10,
							}}
						>
							<Button onClick={() => appAction.frameAction.closeModal()} type="bordered" style={{ marginRight: 10 }}>
								Batalkan
							</Button>
							<Button onClick={deleteDataClick}>Hapus</Button>
						</div>
					</div>
				);
			}

			// return value may be different depending on params
			return React.memo(AppForm);
		}

		return { componentFactory };
	}

	async function initModuleF(aReact, globals) {
		return await getImports(aReact, globals);
	}

	return initModuleF;
	// })()  //--DAFReact: deploy
} //--DAFReact: development
