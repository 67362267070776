import React from 'react'; //--DAFReact: development

// 'use strict'; //--DAFReact: deploy

export function ModuleDefinition() {
	//--DAFReact: development

	// (function () { //--DAFReact: deploy

	async function getImports(React, globals) {
		// checking required libraries, may also include asynchronously loading other remote module, using appAction.loadModule
		const {
			_moduleId,
			StdAppAction,
			AppFrameAction,
			appAction,
			jsdset,
			dswidget,
			DataBrowser,
			staticComponents: {
				Validator: { isExist, isEmail, isPasword, isPhone },
			},
		} = globals;
		if (
			!StdAppAction ||
			!AppFrameAction ||
			!appAction ||
			!jsdset ||
			!dswidget ||
			!DataBrowser
		) {
			throw new Error(
				'One of required components (StdAppAction, AppFrameAction, appAction, jsdset, dswidget) not found in globals',
			);
		}

		if (!_moduleId) {
			throw new Error('_moduleId not defined');
		}

		function componentFactory(params) {
			const metadata = {
				corporate: {
					fields: [
						{
							name: 'is_va',
							type: 'string',
							title: 'Jenis Rekening',
							length: 20,
						},
						{
							name: 'corporate_code',
							type: 'string',
							title: 'Kode Korporat22',
							length: 6,
							validator: (value, fieldName) => {
								let check = isExist(
									value,
									'Kode Korporat',
									3,
									6,
								);
								return check;
							},
						},
						{
							name: 'corporate_name',
							type: 'string',
							title: 'Nama Korporat',
							length: 20,
						},
						{
							name: 'issuer_id',
							type: 'string',
							title: 'Issuer ID',
							length: 20,
						},
						{
							name: 'primary_cif_no',
							type: 'string',
							title: 'Primary CIF No',
							length: 20,
						},
						{
							name: 'primary_cif_VA',
							type: 'string',
							title: 'Korporat',
							length: 20,
						},
						{
							name: 'primary_cif_nonVA',
							type: 'string',
							title: 'Primary CIF No',
							length: 20,
						},
						{
							name: 'address',
							type: 'string',
							title: 'Alamat',
							length: 160,
						},
						{
							name: 'email_address',
							type: 'string',
							title: 'Email',
							length: 50,
						},
						{
							name: 'description',
							type: 'string',
							title: 'Keterangan',
							length: 100,
						},
						{
							name: 'corporate_status',
							type: 'string',
							title: 'Status',
							length: 5,
						},
						{
							name: 'corporate_status_name',
							type: 'string',
							title: 'Status',
							length: 5,
						},
						{
							name: 'is_lock',
							type: 'string',
							title: 'Is Lock',
							length: 5,
						},

						{
							name: 'user_id1',
							type: 'string',
							title: 'User ID',
							length: 20,
						},
						{
							name: 'user_name1',
							type: 'string',
							title: 'Nama User',
							length: 20,
							validator: (value, fieldName) => {
								let check = isExist(value, 'Nama User', 3);
								return check;
							},
						},
						{
							name: 'email_address_user1',
							type: 'string',
							title: 'Email',
							length: 50,
							validator: (value, fieldName) => {
								let check = isEmail(value);
								return check;
							},
						},
						{
							name: 'handphone_no1',
							type: 'string',
							title: 'No. HP',
							length: 20,
							validator: (value, fieldName) => {
								let check = isPhone(value);
								return check;
							},
						},
						{
							name: 'cms_user_status1',
							type: 'string',
							title: 'Status',
							length: 2,
						},
						{
							name: 'user_id2',
							type: 'string',
							title: 'User ID',
							length: 20,
						},
						{
							name: 'user_name2',
							type: 'string',
							title: 'Nama User',
							length: 20,
							validator: (value, fieldName) => {
								let check = isExist(value, 'Nama User', 3);
								return check;
							},
						},
						{
							name: 'email_address_user2',
							type: 'string',
							title: 'Email',
							length: 50,
							validator: (value, fieldName) => {
								let check = isEmail(value);
								return check;
							},
						},
						{
							name: 'handphone_no2',
							type: 'string',
							title: 'No. HP',
							length: 20,
							validator: (value, fieldName) => {
								let check = isPhone(value);
								return check;
							},
						},
						{
							name: 'cms_user_status2',
							type: 'string',
							title: 'Status',
							length: 2,
						},
						{
							name: 'limit_trx_massal',
							type: 'float',
							title: 'limit_trx_massal',
							length: 4,
						},
					],

					indexes: ['corporate_code'],
				},
			};



			const initialData = {
				'main:corporate': [],
			};

			const serverDataMapping = {
				data: {
					dset: 'main',
					type: 'corporate',
					fieldMapping: {
						'*': true, // allow default fields
					},
				},
			};

			const editUIData = {
				corporate: {
					fields: {
						primary_cif_nonVA: {
							lookup: {
								style: {
									idleCheck: false,
									input: true,
									button: true,
								},
								dataId: 'getVACorpList',
								selFields: [
									'primary_cif_no',
									'corporate_name',
									'address',
									'description',
								],
								fieldMap: {
									primary_cif_nonVA: 'id',
									corporate_name: 'companyname',
									primary_cif_no: 'id',
									address: 'address',
									description: 'picname',
								},
								apiParameterF: (
									fields,
									isValidation = false,
								) => {
									return isValidation
										? {
											searchMode: 1,
											searchKey: fields.corporate_code,
											hideNavButtons: true,
											resourceType: 'method',
										}
										: {
											corporate_id: fields.corporate_code,
											hideNavButtons: true,
											resourceType: 'method',
										};
								},
							},
						},
						primary_cif_VA: {
							lookup: {
								style: {
									idleCheck: false,
									input: true,
									button: true,
								},
								dataId: 'getCIFnonVA',
								selFields: [
									'primary_cif_no',
									'corporate_name',
									'address',
									'description',
								],
								fieldMap: {
									primary_cif_VA: 'id',
									corporate_name: 'companyname',
									primary_cif_no: 'id',
									address: 'address',
									description: 'picname',
								},
								apiParameterF: (
									fields,
									isValidation = false,
								) => {
									return isValidation
										? {
											searchMode: 1,
											searchKey: fields.corporate_code,
											hideNavButtons: true,
											resourceType: 'method',
										}
										: {
											corporate_id: fields.corporate_code,
											hideNavButtons: true,
											resourceType: 'method',
										};
								},
							},
						},
						is_va: {
							inputType: 'radio',
							type: 'icon',
							variant: 'bordered',
							dataSets: {
								T: 'VA',
								F: 'NonVA',
							},
							initialValue: 'T',
						},
						corporate_status: {
							inputType: 'radio',
							type: 'icon',
							variant: 'bordered',
							dataSets: {
								A: 'Active',
								S: 'Suspend',
								D: 'Deactive',
							},
							initialValue: 'A',
						},
						is_lock: {
							inputType: 'radio',
							type: 'icon',
							variant: 'bordered',
							dataSets: {
								T: 'Terkunci',
								F: 'Terbuka',
							},
							initialValue: 'F',
						},
						cms_user_status1: {
							inputType: 'radio',
							type: 'icon',
							variant: 'bordered',
							dataSets: {
								A: 'Active',
								S: 'Suspend',
								D: 'Deactive',
							},
							initialValue: 'A',
						},
						cms_user_status2: {
							inputType: 'radio',
							type: 'icon',
							variant: 'bordered',
							dataSets: {
								A: 'Active',
								S: 'Suspend',
								D: 'Deactive',
							},
							initialValue: 'A',
						},
						LimitTransaksiMassal: { thousand: true },
					},
				},
			};

			// return value may be different depending on params
			return { metadata, initialData, serverDataMapping, editUIData };
		}

		return { componentFactory };
	}

	async function initModuleF(aReact, globals) {
		return await getImports(aReact, globals);
	}

	return initModuleF;
	// })()  //--DAFReact: deploy
} //--DAFReact: development
