//--DAFReact: development

// 'use strict'; //--DAFReact: deploy

export function ModuleDefinition() {
	//--DAFReact: development

	// (function () { //--DAFReact: deploy

	async function getImports(React, globals) {
		// checking required libraries, may also include asynchronously loading other remote module, using appAction.loadModule
		const {
			_moduleId,
			StdAppAction,
			AppFrameAction,
			appAction,
			jsdset,
			dswidget,
			DataBrowser,
			staticComponents: {
				Validator: { isExist, isEmail, isPasword, isPhone },
			},
		} = globals;
		if (
			!StdAppAction ||
			!AppFrameAction ||
			!appAction ||
			!jsdset ||
			!dswidget ||
			!DataBrowser
		) {
			throw new Error(
				'One of required components (StdAppAction, AppFrameAction, appAction, jsdset, dswidget) not found in globals',
			);
		}

		if (!_moduleId) {
			throw new Error('_moduleId not defined');
		}

		function componentFactory(params) {
			const metadata = {
				cMSWorkflowTxType: {
					fields: [
						{
							name: 'corporate_code',
							type: 'string',
							title: 'corporate_code',
							length: 20,
						},
						{
							name: 'tx_code',
							type: 'string',
							title: 'tx_code',
							length: 10,
							validator: (value, fieldName) => {
								let check = isExist(value, 'Kode Transaksi', 1);
								return check;
							}
						},
						{
							name: 'tx_desc',
							type: 'string',
							title: 'tx_desc',
							length: 10,
						},
						{
							name: 'product_code',
							type: 'string',
							title: 'product_code',
							length: 20,
							validator: (value, fieldName) => {
								let check = isExist(value, 'Kode Produk', 1);
								return check;
							}
						},
						{
							name: 'product_desc',
							type: 'string',
							title: 'product_desc',
							length: 20,
						},
						{
							name: 'workflow_code',
							type: 'string',
							title: 'workflow_code',
							length: 20,
							validator: (value, fieldName) => {
								let check = isExist(value, 'Kode Workflow', 3);
								return check;
							}
						},
						{
							name: 'workflow_name',
							type: 'string',
							title: 'workflow_name',
							length: 20,
						},
						{
							name: 'minimum_amount',
							type: 'int',
							title: 'minimum_amount',
							length: 16,
							validator: (value, fieldName) => {
								let check = isExist(value, 'Minimum Nominal Transaksi');
								return check;
							}
						},
					],

					indexes: ['tx_code'],
				},
			};

			const initialData = {
				'main:cMSWorkflowTxType': [],
			};

			const serverDataMapping = {
				data: {
					dset: 'main',
					type: 'cMSWorkflowTxType',
					fieldMapping: {
						'*': true, // allow default fields
					},
				},
			};

			const editUIData = {
				cMSWorkflowTxType: {
					fields: {
						workflow_code: {
							lookup: {
								style: {
									idleCheck: false,
									input: true,
									button: true,
								},
								dataId: 'listWorkflow',
								selFields: [
									'workflow_code',
									'workflow_name',
									'required_num_auth',
									'auth_level',
									'required_num_releaser',
									'release_level',
									'auth_need_otp',
									'release_need_otp',
								],
								fieldMap: {
									workflow_code: 'workflow_code',
									workflow_name: 'workflow_name',
									required_num_auth: 'required_num_auth',
									auth_level: 'auth_level',
									required_num_releaser:
										'required_num_releaser',
									release_level: 'release_level',
									auth_need_otp: 'auth_need_otp',
									release_need_otp: 'release_need_otp',
								},
							},
						},
						tx_code: {
							lookup: {
								style: {
									idleCheck: false,
									input: true,
									button: true,
								},
								dataId: 'listTransaksi',
								selFields: ['tx_code', 'description'],
								fieldMap: {
									tx_code: 'tx_code',
									tx_desc: 'description',
									product_code: '',
									product_desc: ''
								},
							},
						},
						product_code: {
							lookup: {
								style: {
									idleCheck: false,
									input: true,
									button: true,
								},
								dataId: 'listProduk',
								selFields: ['product_code', 'product_desc'],
								fieldMap: {
									product_code: 'product_code',
									product_desc: 'product_desc',
								},
								apiParameterF: (
									fields,
									isValidation = false,
								) => {
									return isValidation
										? {
											searchMode: 1,
											searchKey: fields.tx_code,
										}
										: {
											tx_code: fields.tx_code,
										};
								},
							},
						},
						minimum_amount: {
							thousand: true,
							decimal: 2,
						},
					},
				},
			};

			// return value may be different depending on params
			return { metadata, initialData, serverDataMapping, editUIData };
		}

		return { componentFactory };
	}

	async function initModuleF(aReact, globals) {
		return await getImports(aReact, globals);
	}

	return initModuleF;
	// })()  //--DAFReact: deploy
} //--DAFReact: development
