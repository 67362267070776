/*
  DAFReact framework module - Application form (hook)
  appname: cMSWorkflow
  filename: cMSWorkflowEdit.js
  moduleid: cMSWorkflowEdit
  author: IK
*/

import React from 'react'; //--DAFReact: development
// 'use strict'; //--DAFReact: deploy

export function ModuleDefinition() {
	//--DAFReact: development

	// (function () { //--DAFReact: deploy

	async function getImports(React, globals) {
		// checking required libraries, may also include asynchronously loading other remote module, using appAction.loadModule
		const {
			_moduleId,
			StdAppAction,
			appAction,
			jsdset,
			dswidget,
			ndswidget,
			frameAction,
			staticComponents: { Button, FormLabel, AlertComponent },
		} = globals;
		if (!_moduleId || !StdAppAction || !appAction || !jsdset || !dswidget) {
			throw new Error('One of required components (_moduleId, StdAppAction, appAction, jsdset, dswidget) not found in globals');
		}

		/* other required modules are asynchronously loaded here */
		const { metadata, initialData, serverDataMapping, editUIData } = await appAction.fetchAndExecModule('settings.cMSWorkflowMetadata');

		const { PanelDataDisplay, PanelButton } = dswidget;
		const { FieldDataDisplay, FieldDataInput } = ndswidget;

		function componentFactory(params) {
			function AppForm(props) {
				const dataContext = React.useMemo(() => jsdset.dsetCreateContext(), []);
				const DSetProvider = React.useMemo(() => jsdset.dsetMetaProvider(dataContext, metadata, initialData, editUIData), []);
				return (
					<DSetProvider>
						<AppFormUI dataContext={dataContext} {...props} /> {/* any other props will be passed down */}
					</DSetProvider>
				);
			}

			function AppFormUI(props) {
				const [state, setState] = React.useState({
					isErr: false,
					errMessage: '',
					isEditing: false,
				});

				// bind controls to _moduleId and _authToken
				const vComps = React.useMemo(
					() =>
						appAction.connect(
							{
								PanelDataDisplay,
								FieldDataDisplay,
								PanelButton,
								FieldDataInput,
							},
							{ _moduleId, _getToken: () => props._authToken },
						),
					[],
				);

				// bind controls to datasets
				const [mainComps] = React.useMemo(
					() => [jsdset.connect({ context: props.dataContext, dsetPath: 'main' }, vComps)],
					[props.dataContext, vComps],
				);

				// obtain action objects from data context
				const [, dsMainAction, dsMainProxy] = jsdset.useDSetContext(props.dataContext, 'main');

				// load data function
				const loadData = React.useCallback(async () => {
					setState(state => ({
						...state,
						isErr: false,
						errMessage: '',
					}));

					try {
						const response = await appAction.fetchResource(
							_moduleId,
							'single_data',
							'dataSO',
							props._authToken,
							{ workflow_code: props.workflow_code },
							true,
						);
						if (response?.data?.length > 0) {
							dsMainAction.loadStore(response, 'std', serverDataMapping, true);
							dsMainAction.recalcFormulas();
						} else {
							appAction.frameAction.closeModal();
							handlePopup('warning', 'Data Tidak Bisa di Ubah');
						}
					} catch (err) {
						setState(prevState => ({
							...prevState,
							isErr: true,
							errMessage: err.message,
						}));
						return;
					}
				}, [dsMainAction, props._authToken, props.corporate_code]);

				// set event on component mounting
				React.useEffect(() => {
					(async function () {
						if (props.uiMode == 'edit') await loadData();
						else
							dsMainAction.addRow({
								auth_need_otp: 'F',
								release_need_otp: 'F',
							});
					})();
				}, [dsMainAction, loadData, props.uiMode]);

				// UI event handlers
				const switchEditClick = () => {
					//
					setState(prevState => ({
						...prevState,
						isEditing: !prevState.isEditing,
					}));
				};

				const saveDataClick = async () => {
					try {
						const { fieldValidStates, fieldValidErrors } = dsMainProxy;
						const isInValid = Object.entries(fieldValidStates).filter(([i, v]) => {
							return !v;
						});

						if (isInValid.length > 0) throw new Error(fieldValidErrors[isInValid[0][0]]);

						var dataUnload = dsMainProxy.unloadStore(serverDataMapping, {
							includeLoadedRows: false,
							includeDeletedRows: true,
						});
						await appAction.postData(_moduleId, 'saveData', props._authToken, dataUnload);
						appAction.frameAction.closeModal();
						handlePopup('success', 'Simpan Data Berhasil', 'Data Perlu Diotorisasi');
					} catch (err) {
						handlePopup('alert', err.message);
						// await appAction.frameAction.showMessage(
						// 	err.message,
						// 	'Error save data',
						// 	{ messageType: 'error' },
						// );
					}
				};

				const handlePopup = (type, messages, body) => {
					frameAction.showModal({
						contentClass: props => <AlertComponent {...props} title={messages} type={type} body={body} />,
						size: 'small',
					});
				};
				// render
				return (
					<div>
						{props.uiMode === 'add' ? (
							<>
								<FormLabel label="Kode Workflow" style={{ width: `100%` }}>
									<mainComps.FieldDataInput fieldName="workflow_code" />
								</FormLabel>
								<FormLabel label="Nama Workflow" style={{ width: `100%` }}>
									<mainComps.FieldDataInput fieldName="workflow_name" />
								</FormLabel>
							</>
						) : null}

						{props.uiMode === 'edit' ? (
							<>
								<div
									style={{
										display: `flex`,
										marginBottom: `1rem`,
									}}
								>
									<FormLabel label="ID Perusahaan" style={{ width: `100%` }}>
										<b>
											<mainComps.FieldDataDisplay fieldName="corporate_code" />
										</b>
									</FormLabel>
									<FormLabel label="Kode Workflow" style={{ width: `100%` }}>
										<b>
											<mainComps.FieldDataDisplay fieldName="workflow_code" />
										</b>
									</FormLabel>
								</div>
								<FormLabel label="Nama Workflow">
									<mainComps.FieldDataInput fieldName="workflow_name" />
								</FormLabel>
							</>
						) : null}

						<div
							style={{
								display: `flex`,
								marginBottom: `1rem`,
								justifyContent: `space-between`,
							}}
						>
							<FormLabel
								label="Jumlah Approver"
								style={{
									width: `100%`,
									marginRight: `0.75rem`,
								}}
							>
								<mainComps.FieldDataInput fieldName="required_num_auth" />
							</FormLabel>
							<FormLabel label="OTP Approver" style={{ width: `100%`, marginLeft: `0.75rem` }}>
								<mainComps.FieldDataInput fieldName="auth_need_otp" />
							</FormLabel>
						</div>

						<div
							style={{
								display: `flex`,
								marginBottom: `1rem`,
								justifyContent: `space-between`,
							}}
						>
							<FormLabel
								label="Jumlah Releaser"
								style={{
									width: `100%`,
									marginRight: `0.75rem`,
								}}
							>
								<mainComps.FieldDataInput fieldName="required_num_releaser" />
							</FormLabel>
							<FormLabel label="OTP Releaser" style={{ width: `100%`, marginLeft: `0.75rem` }}>
								<mainComps.FieldDataInput fieldName="release_need_otp" />
							</FormLabel>
						</div>
						<br />
						<div
							style={{
								display: `flex`,
								justifyContent: `flex-end`,
							}}
						>
							<Button onClick={() => appAction.frameAction.closeModal()} type="bordered" style={{ marginRight: 10 }}>
								Batalkan
							</Button>
							<Button onClick={saveDataClick}>Simpan Perubahan</Button>
						</div>
						<div style={{ display: state.isErr ? 'block' : 'none' }}>{state.errMessage}</div>
					</div>
				);
			}

			// return value may be different depending on params
			return React.memo(AppForm);
		}

		return { componentFactory };
	}

	async function initModuleF(aReact, globals) {
		return await getImports(aReact, globals);
	}

	return initModuleF;
	// })()  //--DAFReact: deploy
} //--DAFReact: development
