/*
	DAFReact framework module - Application form (hook)
	appname: corporate
	filename: corporateEdit.js
	moduleid: corporateEdit
	author: IK
*/

import React from 'react'; //--DAFReact: development
// 'use strict'; //--DAFReact: deploy

export function ModuleDefinition() {
	//--DAFReact: development

	// (function () { //--DAFReact: deploy

	async function getImports(React, globals) {
		// checking required libraries, may also include asynchronously loading other remote module, using appAction.loadModule
		const {
			_moduleId,
			StdAppAction,
			appAction,
			jsdset,
			dswidget,
			ndswidget,
			dswidgetex,
			frameAction,
			staticComponents: {
				Button,
				FormLabel,
				ReactIconFa: { FaSearch },
				AlertComponent,
				Input,
				RadioGroup,
			},
		} = globals;
		if (!_moduleId || !StdAppAction || !appAction || !jsdset || !dswidget) {
			throw new Error('One of required components (_moduleId, StdAppAction, appAction, jsdset, dswidget) not found in globals');
		}

		/* other required modules are asynchronously loaded here */
		const { metadata, initialData, serverDataMapping, editUIData } = await appAction.fetchAndExecModule('corporate.corporateMetadata');

		const { PanelDataDisplay, FieldDataDisplay, PanelButton } = dswidget;
		const { FieldDataInput } = ndswidget;
		const { DateFieldDataInput } = dswidgetex;

		function componentFactory(params) {
			function AppForm(props) {
				const dataContext = React.useMemo(() => jsdset.dsetCreateContext(), []);
				const DSetProvider = React.useMemo(() => {
					return jsdset.dsetMetaProvider(dataContext, metadata, initialData, editUIData);
				}, []);
				return (
					<DSetProvider>
						<AppFormUI dataContext={dataContext} {...props} />
						{/* any other props will be passed down */}
					</DSetProvider>
				);
			}

			function AppFormUI(props) {
				const [state, setState] = React.useState({
					isErr: false,
					errMessage: '',
					isEditing: false,
				});

				const ref_primary_cif_no = React.useRef(null);

				// bind controls to _moduleId and _authToken
				const vComps = React.useMemo(
					() =>
						appAction.connect(
							{
								PanelDataDisplay,
								FieldDataDisplay,
								PanelButton,
								FieldDataInput,
								DateFieldDataInput,
							},
							{ _moduleId, _getToken: () => props._authToken },
						),
					[],
				);

				// bind controls to datasets
				const [mainComps] = React.useMemo(() => [jsdset.connect({ context: props.dataContext, dsetPath: 'main' }, vComps)], [props.dataContext, vComps]);

				// obtain action objects from data context
				const [, dsMainAction, dsMainProxy] = jsdset.useDSetContext(props.dataContext, 'main');

				// load data function
				const loadData = React.useCallback(async () => {
					setState(state => ({
						...state,
						isErr: false,
						errMessage: '',
					}));

					try {
						const response = await appAction.fetchResource(_moduleId, 'single_data', 'dataSO', props._authToken, { corporate_code: props.corporate_code }, true);

						// console.log(response)

						if (response?.data?.length > 0) {
							// console.log(response?.data[0].is_va, 'is_va')

							dsMainAction.loadStore(response, 'std', serverDataMapping, true);

							for (var x in response?.data) {
								dsMainAction.setFields({
									email_address: response?.data[0].corpemail_address,
									['user_name' + (parseFloat(x) + 1)]: response?.data[x]?.user_id,
									['user_id' + (parseFloat(x) + 1)]: response?.data[x]?.user_id,
									['handphone_no' + (parseFloat(x) + 1)]: response?.data[x]?.handphone_no,
									['cms_user_status' + (parseFloat(x) + 1)]: response?.data[x]?.cms_user_status,
									['email_address_user' + (parseFloat(x) + 1)]: response?.data[x]?.email_address,
									// ['password' + (parseFloat(x) + 1)]:
									// 	response?.data[x]?.enc_password,
									// ['confirmpassword' + (parseFloat(x) + 1)]:
									// 	response?.data[x]?.enc_password,
								});
							}

							dsMainAction.recalcFormulas();
						} else {
							appAction.frameAction.closeModal();
							AlertHandler('Data Tidak Bisa di Ubah', 'warning');
						}
					} catch (err) {
						setState(prevState => ({
							...prevState,
							isErr: true,
							errMessage: err.message,
						}));
						return;
					}
				}, [dsMainAction, props._authToken, props.corporate_code]);

				// set event on component mounting
				React.useEffect(() => {
					(async function () {
						if (props.uiMode == 'edit') {
							await loadData();
						} else {
							dsMainAction.addRow({
								is_va: 'T',
								corporate_status: 'A',
								is_lock: 'F',
								cms_user_status1: 'A',
								cms_user_status2: 'A',
							});
						}
					})();
				}, [dsMainAction, loadData, props.uiMode]);

				//load data CIF
				const loadDataCIF = React.useCallback(async () => {
					setState(state => ({
						...state,
						isErr: false,
						errMessage: '',
					}));
					try {
						const response = await appAction.fetchResource(
							_moduleId,
							'method',
							'dataCIF',
							props._authToken,
							{
								primary_cif_no: ref_primary_cif_no.current.value,
							},
							true,
						);

						if (response) {
							let data = response;
							if (data.err_info) {
								throw new Error(data.err_info);
							}
							dsMainAction.setFields({
								corporate_name: data.companyname,
								address: data.address,
								corporate_status: 'A',
								description: 'PIC: ' + data.picname,
							});
						} else {
							AlertHandler('Korporat ID tidak terdaftar', 'warning');
						}
					} catch (err) {
						setState(prevState => ({
							...prevState,
							isErr: true,
							errMessage: err.message,
						}));

						AlertHandler(err.message, 'warning');
						return;
					}
				}, [dsMainAction, props._authToken]);

				const saveDataClick = async () => {
					try {
						const { fieldValidStates, fieldValidErrors } = dsMainProxy;

						const isInValid = Object.entries(fieldValidStates).filter(([i, v]) => {
							return !v;
						});

						if (isInValid.length > 0) throw new Error(fieldValidErrors[isInValid[0][0]]);

						var dataUnload = dsMainProxy.unloadStore(serverDataMapping, {
							includeLoadedRows: false,
							includeDeletedRows: true,
						});

						if (dataUnload?.data?.length > 0) {
							if (props.uiMode !== 'edit') {
								if (dataUnload.data[0].user_name1 == dataUnload.data[0].user_name2) {
									throw new Error('User ID sysAdmin tidak boleh sama');
								}
								if (dataUnload.data[0].handphone_no1 == dataUnload.data[0].handphone_no2) {
									throw new Error('Nomor telepon sysAdmin tidak boleh sama');
								}
								if (dataUnload.data[0].email_address_user1 == dataUnload.data[0].email_address_user2) {
									throw new Error('Alamat Email sysAdmin tidak boleh sama');
								}
							}

							if (dataUnload?.data[0].corporate_name !== '') {
								await appAction.postData(_moduleId, 'saveData', props._authToken, dataUnload);

								appAction.frameAction.closeModal();
								AlertHandler('Data berhasil disimpan', 'success', 'Data perlu diotorisasi');
							} else {
								throw new Error('Silahkan Lengkapi Data Korporat');
							}
						} else {
							appAction.frameAction.closeModal();
						}
					} catch (err) {
						AlertHandler(err.message, 'alert');
					}
				};

				const AlertHandler = async (msg, type, body) => {
					await frameAction.showModal({
						contentClass: props => <AlertComponent {...props} title={msg} type={type} body={body} />,
						size: 'small',
					});
				};

				const handleChangeInput = text => {
					dsMainAction.setFields({
						primary_cif_no: text,
						corporate_name: '',
						address: '',
						description: '',
					});
				};

				// console.log(dsMainProxy.fields)

				return (
					<div>
						<div
							style={{
								display: 'flex',
								width: '-webkit-fill-available',
							}}
						>
							<div
								style={{
									marginRight: 10,
									width: '-webkit-fill-available',
								}}
							>
								<h5 style={{ color: '#b7282d' }}>Data korporat</h5>

								<FormLabel label="Type Rekening Korporat">
									{/* <mainComps.FieldDataInput
										elProps={{ readOnly: props.uiMode == 'edit' ? true : false }}
										fieldName="is_va"
									/> */}

									<RadioGroup
										data={Object.entries({
											T: 'VA',
											F: 'NonVA',
										})}
										readOnly={props.uiMode == 'edit' ? true : false}
										name={'typeRekening'}
										currentValue={dsMainProxy.fields.is_va}
										initialValue={'T'}
										type={'icon'}
										varian={'bordered'}
										onChange={e => {
											dsMainAction.setFields({
												is_va: e.target.value,
												primary_cif_VA: '',
												primary_cif_nonVA: '',
												corporate_name: '',
												primary_cif_no: '',
												address: '',
												description: '',
											});
										}}
									/>
								</FormLabel>

								{dsMainProxy.fields.is_va && dsMainProxy.fields.is_va == 'T' ? (
									<FormLabel label="Korporat">
										{props.uiMode !== 'edit' ? (
											<mainComps.FieldDataInput elProps={{ readOnly: true }} fieldName="primary_cif_VA" />
										) : (
											<b>
												{dsMainProxy.fields.primary_cif_no && dsMainProxy.fields.primary_cif_no !== '' ? (
													<mainComps.FieldDataDisplay fieldName="primary_cif_no" />
												) : (
													'-'
												)}
											</b>
										)}
									</FormLabel>
								) : (
									<FormLabel label="Nomor CIF">
										{props.uiMode !== 'edit' ? (
											// <mainComps.FieldDataInput
											// 	elProps={{ readOnly: true }}
											// 	fieldName="primary_cif_nonVA"
											// />
											<div
												style={{
													display: 'flex',
													width: '100%',
													justifyContent: 'space-between',
												}}
											>
												<Input
													reference={ref_primary_cif_no}
													onChange={e => {
														handleChangeInput(e.target.value);
													}}
													onKeyPress={e => {
														if (e.key == 'Enter') {
															loadDataCIF();
														}
													}}
												/>
												<Button
													onClick={() => {
														loadDataCIF();
													}}
													style={{
														display: 'flex',
														alignItems: 'center',
														// width: 140,
													}}
												>
													<FaSearch /> &nbsp;Cari
												</Button>
											</div>
										) : (
											<b>
												{dsMainProxy.fields.primary_cif_no && dsMainProxy.fields.primary_cif_no !== '' ? (
													<mainComps.FieldDataDisplay fieldName="primary_cif_no" />
												) : (
													'-'
												)}
											</b>
										)}
									</FormLabel>
								)}
								<FormLabel label="Kode Korporat">
									{props.uiMode !== 'edit' ? (
										<mainComps.FieldDataInput fieldName="corporate_code" />
									) : (
										<b>
											{dsMainProxy.fields.corporate_code && dsMainProxy.fields.corporate_code !== '' ? (
												<mainComps.FieldDataDisplay fieldName="corporate_code" />
											) : (
												'-'
											)}
										</b>
									)}
								</FormLabel>
								<FormLabel label="Nama Korporat">
									{props.uiMode !== 'edit' ? (
										<mainComps.FieldDataInput elProps={{ disabled: true }} fieldName="corporate_name" />
									) : (
										<b>
											{dsMainProxy.fields.corporate_name && dsMainProxy.fields.corporate_name !== '' ? (
												<mainComps.FieldDataDisplay fieldName="corporate_name" />
											) : (
												'-'
											)}
										</b>
									)}
								</FormLabel>
								{/* <FormLabel label="Issuer ID">
									{props.uiMode !== 'edit' ? (
										<mainComps.FieldDataInput
											elProps={{ disabled: true }}
											fieldName="issuer_id"
										/>
									) : (
										<b>
											{dsMainProxy.fields.issuer_id && dsMainProxy.fields.issuer_id !== '' ? <mainComps.FieldDataDisplay fieldName="issuer_id" /> : '-'}

										</b>
									)}
								</FormLabel> */}
								<FormLabel label="Alamat">
									{props.uiMode !== 'edit' ? (
										<mainComps.FieldDataInput elProps={{ disabled: true }} fieldName="address" />
									) : (
										<b>{dsMainProxy.fields.address && dsMainProxy.fields.address !== '' ? <mainComps.FieldDataDisplay fieldName="address" /> : '-'}</b>
									)}
								</FormLabel>
								<FormLabel label="Email">
									{props.uiMode !== 'edit' ? (
										<mainComps.FieldDataInput elProps={{ disabled: true }} fieldName="email_address" />
									) : (
										<b>
											{dsMainProxy.fields.email_address && dsMainProxy.fields.email_address !== '' ? (
												<mainComps.FieldDataDisplay fieldName="email_address" />
											) : (
												'-'
											)}
										</b>
									)}
								</FormLabel>
								<FormLabel label="Keterangan">
									<mainComps.FieldDataInput fieldName="description" />
								</FormLabel>
								<FormLabel label="Status Korporat">
									<mainComps.FieldDataInput elProps={{ readOnly: true }} fieldName="corporate_status" />
								</FormLabel>
								<FormLabel label="Status Kunci">
									<mainComps.FieldDataInput elProps={{ readOnly: true }} fieldName="is_lock" />
								</FormLabel>
							</div>

							<div
								style={{
									width: '-webkit-fill-available',
								}}
							>
								<h5 style={{ color: '#b7282d' }}>Data SysAdmin</h5>

								{props.uiMode !== 'edit' ? (
									<>
										<FormLabel label="User ID SysAdmin 1 ( User Maker )">
											<mainComps.FieldDataInput fieldName="user_name1" />
										</FormLabel>
										<FormLabel label="Nomor Handphone SysAdmin 1 ( User Maker )">
											<mainComps.FieldDataInput fieldName="handphone_no1" />
										</FormLabel>
										<FormLabel label="Email SysAdmin 1 ( User Maker )">
											<mainComps.FieldDataInput fieldName="email_address_user1" />
										</FormLabel>
										{/* <FormLabel label="Kata Sandi SysAdmin 1">
											<mainComps.FieldDataInput
												elProps={{ type: 'password' }}
												fieldName="password1"
											/>
										</FormLabel>
										<FormLabel label="Ulangi Kata Sandi">
											<mainComps.FieldDataInput
												elProps={{ type: 'password' }}
												fieldName="confirmpassword1"
											/>
										</FormLabel> */}
									</>
								) : (
									<>
										<FormLabel label="User ID SysAdmin 1 ( User Maker )">
											<b>
												<mainComps.FieldDataDisplay fieldName="user_name1" />
											</b>
										</FormLabel>
										<FormLabel label="Nomor Handphone SysAdmin 1 ( User Maker )">
											<b>
												<mainComps.FieldDataDisplay fieldName="handphone_no1" />
											</b>
										</FormLabel>
										<FormLabel label="Email SysAdmin 1 ( User Maker )">
											<b>
												<mainComps.FieldDataDisplay fieldName="email_address_user1" />
											</b>
										</FormLabel>
									</>
								)}
								<FormLabel label="Status SysAdmin 1 ( User Maker )">
									<mainComps.FieldDataInput elProps={{ readOnly: true }} fieldName="cms_user_status1" />
								</FormLabel>

								<div
									style={{
										width: `100%`,
										height: `1px`,
										backgroundColor: `#e6e6e6`,
										margin: `2rem 0`,
									}}
								></div>

								{props.uiMode !== 'edit' ? (
									<>
										<FormLabel label="User ID SysAdmin 2 ( User Approver )">
											<mainComps.FieldDataInput fieldName="user_name2" />
										</FormLabel>
										<FormLabel label="Nomor Handphone SysAdmin 2 ( User Approver )">
											<mainComps.FieldDataInput fieldName="handphone_no2" />
										</FormLabel>
										<FormLabel label="Email SysAdmin 2 ( User Approver )">
											<mainComps.FieldDataInput fieldName="email_address_user2" />
										</FormLabel>
										{/* <FormLabel label="Kata Sandi SysAdmin 2">
											<mainComps.FieldDataInput
												elProps={{ type: 'password' }}
												fieldName="password2"
											/>
										</FormLabel>
										<FormLabel label="Ulangi Kata Sandi">
											<mainComps.FieldDataInput
												elProps={{ type: 'password' }}
												fieldName="confirmpassword2"
											/>
										</FormLabel> */}
									</>
								) : (
									<>
										<FormLabel label="User ID SysAdmin 2 ( User Approver )">
											<b>
												<mainComps.FieldDataDisplay fieldName="user_name2" />
											</b>
										</FormLabel>
										<FormLabel label="Nomor Handphone SysAdmin 2 ( User Approver )">
											<b>
												<mainComps.FieldDataDisplay fieldName="handphone_no2" />
											</b>
										</FormLabel>
										<FormLabel label="Email SysAdmin 2 ( User Approver )">
											<b>
												<mainComps.FieldDataDisplay fieldName="email_address_user2" />
											</b>
										</FormLabel>
									</>
								)}
								<FormLabel label="Status SysAdmin 2 ( User Approver )">
									<mainComps.FieldDataInput elProps={{ readOnly: true }} fieldName="cms_user_status2" />
								</FormLabel>
							</div>
						</div>
						<br />
						<div
							style={{
								display: 'flex',
								justifyContent: 'flex-end',
							}}
						>
							<Button
								onClick={() => appAction.frameAction.closeModal()}
								type="bordered"
								style={{
									marginRight: 10,
								}}
							>
								Batalkan
							</Button>
							<Button onClick={saveDataClick}>Simpan Data Korporat</Button>
						</div>

						<div
							style={{
								display: state.isErr ? 'block' : 'none',
							}}
						>
							{state.errMessage}
						</div>
					</div>
				);
			}

			// return value may be different depending on params
			return React.memo(AppForm);
		}

		return { componentFactory };
	}

	async function initModuleF(aReact, globals) {
		return await getImports(aReact, globals);
	}

	return initModuleF;
	// })()  //--DAFReact: deploy
} //--DAFReact: development
