/*
  DAFReact framework module - Application form (hook)
  appname: digitalTransaction
  filename: digitalTransactionList.js
  moduleid: digitalTransactionList
  author: IK
*/

// 'use strict';

export function ModuleDefinition() {
	//--DAFReact: development

	// (function () { //--DAFReact: deploy

	async function getImports(React, globals) {
		const {
			_moduleId,
			StdAppAction,
			AppFrameAction,
			appAction,
			jsdset,
			dswidget,
			DataBrowser,
			NDataBrowser,
			frameAction,
			staticComponents: {
				Dropdown,
				FormLabel,
				DatePicker,
				PageTitle,
				SearchBox,
				Filter,
				FilterActionButtons,
				AlertComponent,
				ReactIconFa,
				ReactIconSi:{SiMicrosoftexcel}
			},
		} = globals;
		if (
			!_moduleId ||
			!StdAppAction ||
			!AppFrameAction ||
			!appAction ||
			!jsdset ||
			!dswidget ||
			!DataBrowser
		) {
			throw new Error(
				'One of required components (_moduleId, StdAppAction, AppFrameAction, appAction, jsdset, dswidget, _moduleId) not found in globals',
			);
		}

		const { FaRetweet } = ReactIconFa;

		const { editUIData } = await appAction.fetchAndExecModule(
			'monitoring_corp.digitalTransactionCorpMetadata',
		);
		function componentFactory(params) {
			function AppForm(props) {
				const [state, setState] = React.useState({
					popupComponent: undefined,
					tx_type: [],
					status: [],
					account_no: [],
				});

				const initFilter = {
					start_date: new Date(),
					end_date: new Date(),
					tx_type: null,
					status: null,
					account_no: '',
					account_name: '',
				};
				const [filter, setFilter] = React.useState(initFilter);

				const dsetProvider = React.useMemo(
					() => jsdset.dsetEmptyProvider(),
					[],
				);

				// load data function
				const loadData = React.useCallback(async () => {
					setState(state => ({
						...state,
						isErr: false,
						errMessage: '',
					}));

					try {
						const getJenisTransaksi = await appAction.fetchResource(
							_moduleId,
							'method',
							'getJenisTransaksi',
							props._authToken,
							{},
							true,
						);

						const getStatusTransaksi =
							await appAction.fetchResource(
								_moduleId,
								'method',
								'getStatusTransaksi',
								props._authToken,
								{},
								true,
							);

						const getRekening = await appAction.fetchResource(
							_moduleId,
							'single_data',
							'getRekening',
							props._authToken,
							{},
							true,
						);
						console.log(
							getRekening,
							getStatusTransaksi,
							getJenisTransaksi,
						);
						if (
							getJenisTransaksi?.rows &&
							getStatusTransaksi?.rows &&
							getRekening?.data
						) {
							let gjt = [{ value: null, name: 'Semua' }];
							let gst = [{ value: null, name: 'Semua' }];
							let rek = [{ value: null, name: 'Semua' }];

							for (var x of getJenisTransaksi?.rows) {
								gjt.push({
									value: x.id,
									name: x.description,
								});
							}

							for (var x of getStatusTransaksi?.rows) {
								gst.push({
									value: x.id,
									name: x.description,
								});
							}

							for (var x of getRekening?.data) {
								rek.push({
									value: x.account_no,
									name: x.account_no + ' - ' + x.account_name,
								});
							}

							setState(prevState => ({
								...prevState,
								tx_type: gjt,
								status: gst,
								account_no: rek,
							}));
						}

						// dsMainAction.recalcFormulas();
					} catch (err) {
						setState(prevState => ({
							...prevState,
							isErr: true,
							errMessage: err.message,
						}));
						return;
					}
				}, [props._authToken]);

				React.useEffect(() => {
					(async function () {
						const popupComponent = await appAction.fetchMenu(
							_moduleId,
							'popup_menu',
							props._authToken,
						);
						setState({ popupComponent });
						loadData();
					})();
				}, [props._authToken]);

				const ActionButtons = React.useCallback(clearFilter => {
					return [
						{
							type: 'bordered',
							name: 'Export Excel',
							onClick: async () => {
								try{
									await appAction.postData(
										_moduleId,
										'importList',
										props._authToken,
										{ ...params, ...filter },
									);
								}
								catch(err){
									AlertHandler(err.message, 'alert')
								}
							},
							render: () => <SiMicrosoftexcel />,
						},
						{
							type: 'bordered',
							name: 'Reload',
							onClick: () => {
								setFilter(initFilter);
								clearFilter();
							},
							render: () => <FaRetweet />,
						},
					];
				}, [filter]);

				const AlertHandler = async (msg, type) => {
					await frameAction.showModal({
						contentClass: props => (
							<AlertComponent
								{...props}
								title={msg}
								type={type}
							/>
						),
						size: 'small',
					});
				}

				const handleFilter = React.useCallback(
					e => {
						setFilter(prev => ({
							...prev,
							start_date: e.start_date || prev.start_date,
							end_date: e.end_date || prev.end_date,
						}));
					},
					[filter],
				);

				return (
					<>
						<PageTitle
							title="Histori Transaksi (Single)"
							subtitle="Monitoring"
							style={{ marginBottom: `1.5rem` }}
						/>

						<NDataBrowser
							stdAppAction={appAction}
							moduleId={_moduleId}
							dataId="listSO"
							dataProvider={dsetProvider}
							authToken={props._authToken}
							dsetPath="main"
							uiData={editUIData}
							params={filter}
							popupComponent={state.popupComponent}
							filterComponent={({
								sortFields,
								clearFilter,
								changeParams,
								params,
							}) => {
								return (
									<div
										style={{
											border: `1px solid rgba(0,0,0,0.1)`,
											backgroundColor: `#eeeeee`,
											padding: `1rem`,
											borderRadius: `5px`,
											margin: `1rem 0`,
										}}
									>
										<h5>Cari Histori Transaksi</h5>
										<Filter>
											{/* Rekening Sumber */}
											<FormLabel
												label="Rekening Sumber"
												style={{
													maxWidth: '250px',
													marginRight: '1rem',
												}}
											>
												{state.account_no ? (
													<Dropdown
														onChange={e => {
															setFilter(
																prevState => ({
																	...prevState,
																	account_no:
																		e.target
																			.value,
																}),
															);
														}}
														value={
															filter.account_no ||
															null
														}
														item={state.account_no}
														width="-webkit-fill-available"
													/>
												) : null}
											</FormLabel>
											{/* Jenis Transaksi */}
											<FormLabel
												label="Jenis Transaksi"
												style={{
													maxWidth: '200px',
													marginRight: '1rem',
												}}
											>
												{state.tx_type ? (
													<Dropdown
														onChange={e => {
															setFilter(
																prevState => ({
																	...prevState,
																	tx_type:
																		e.target
																			.value,
																}),
															);
														}}
														value={
															filter.tx_type ||
															null
														}
														item={state.tx_type}
														width="-webkit-fill-available"
													/>
												) : null}
											</FormLabel>
											{/* Status Transaksi */}
											<FormLabel
												label="Status Transaksi"
												style={{ maxWidth: '200px' }}
											>
												{state.tx_type ? (
													<Dropdown
														item={state.status}
														width="-webkit-fill-available"
														onChange={e => {
															setFilter(
																prevState => ({
																	...prevState,
																	status: e
																		.target
																		.value,
																}),
															);
														}}
														value={
															filter.status ||
															null
														}
													/>
												) : null}
											</FormLabel>
										</Filter>

										<Filter>
											{/* Tanggal */}
											<FormLabel
												label="Tanggal Transaksi"
												style={{
													maxWidth: '250px',
													marginRight: '1rem',
												}}
											>
												<div
													style={{
														display: 'flex',
													}}
												>
													<DatePicker
														value={
															filter.start_date
														}
														onSelect={e => {
															handleFilter({
																start_date: e,
															});
														}}
													/>
													<div
														style={{ padding: 10 }}
													>
														S/D
													</div>
													<DatePicker
														value={filter.end_date}
														onSelect={e => {
															handleFilter({
																end_date: e,
															});
														}}
													/>
												</div>
											</FormLabel>

											{/* Sort & Search */}
											<FormLabel
												label="Urutkan & Cari"
												style={{
													maxWidth: '200px',
													marginRight: '1rem',
												}}
											>
												<Dropdown
													item={sortFields}
													width="-webkit-fill-available"
													value={params.sortField}
													onChange={e =>
														changeParams({
															sortField:
																e.target.value,
														})
													}
												/>
											</FormLabel>
											<FormLabel label="&nbsp;">
												<SearchBox
													label="Tampilkan"
													style={{
														marginLeft: 5,
														width: '300px',
													}}
													handleSearch={src => {
														changeParams({
															start_date:
																filter.start_date,
															end_date:
																filter.end_date,
															src: src,
															tx_type:
																filter.tx_type,
															status: filter.status,
															account_no:
																filter.account_no,
														});
													}}
													srcText={params.src}
												/>
											</FormLabel>
											<FormLabel label="&nbsp;">
												<FilterActionButtons
													buttons={ActionButtons(
														clearFilter,
													)}
												/>
											</FormLabel>
										</Filter>
									</div>
								);
							}}
						/>
					</>
				);
			}

			return React.memo(AppForm);
		}

		return { componentFactory };
	}

	return async (aReact, globals) => {
		return await getImports(aReact, globals);
	};

	// })()  //--DAFReact: deploy
} //--DAFReact: development
