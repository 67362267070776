import React from 'react'; //--DAFReact: development
// 'use strict'; //--DAFReact: deploy

export function ModuleDefinition() {
	//--DAFReact: development

	// (function () { //--DAFReact: deploy

	async function getImports(React, globals) {
		// checking required libraries, may also include asynchronously loading other remote module, using appAction.loadModule
		const {
			_moduleId,
			StdAppAction,
			appAction,
			jsdset,
			dswidget,
			ndswidget,
			frameAction,
			NDataBrowser,
			staticComponents: {
				AlertComponent,
				Button,
				FormLabel,
				Filter,
				DatePicker,
				SearchBox,
				ReactIconFa: { FaRetweet },
				FilterActionButtons,
				Dropdown,
			},
		} = globals;
		if (!_moduleId || !StdAppAction || !appAction || !jsdset || !dswidget) {
			throw new Error(
				'One of required components (_moduleId, StdAppAction, appAction, jsdset, dswidget) not found in globals',
			);
		}

		/* other required modules are asynchronously loaded here */
		const { metadata, initialData, serverDataMapping, editUIData } =
			await appAction.fetchAndExecModule(
				'monitoring_corp.batchTransactionCorpMetadata',
			);

		const { PanelDataDisplay, PanelButton } = dswidget;
		const { FieldDataInput, FieldDataDisplay } = ndswidget;

		function componentFactory(params) {
			function AppForm(props) {
				const dataContext = React.useMemo(
					() => jsdset.dsetCreateContext(),
					[],
				);
				const DSetProvider = React.useMemo(() => {
					return jsdset.dsetMetaProvider(
						dataContext,
						metadata,
						initialData,
						editUIData,
					);
				}, []);
				return (
					<DSetProvider>
						<AppFormUI dataContext={dataContext} {...props} />
						{/* any other props will be passed down */}
					</DSetProvider>
				);
			}

			function AppFormUI(props) {
				const [state, setState] = React.useState({
					popupComponent: undefined,
					isErr: false,
					errMessage: '',
				});

				const [filter, setFilter] = React.useState({
					start_date: new Date(),
					end_date: new Date(),
				});

				// bind controls to _moduleId and _authToken
				const vComps = React.useMemo(
					() =>
						appAction.connect(
							{
								PanelDataDisplay,
								FieldDataDisplay,
								PanelButton,
								FieldDataInput,
							},
							{ _moduleId, _getToken: () => props._authToken },
						),
					[],
				);

				// bind controls to datasets
				const [mainComps] = React.useMemo(
					() => [
						jsdset.connect(
							{ context: props.dataContext, dsetPath: 'main' },
							vComps,
						),
					],
					[props.dataContext, vComps],
				);

				// obtain action objects from data context
				const [, dsMainAction, dsMainProxy] = jsdset.useDSetContext(
					props.dataContext,
					'main',
				);

				// load data function
				const loadData = React.useCallback(async () => {
					setState(state => ({
						...state,
						isErr: false,
						errMessage: '',
					}));

					try {
						const response = await appAction.fetchResource(
							_moduleId,
							'single_data',
							'dataSO',
							props._authToken,
							{ id_tx_draft: props.id_tx_draft },
							true,
						);

						dsMainAction.loadStore(
							response,
							'std',
							serverDataMapping,
							true,
						);

						dsMainAction.recalcFormulas();
					} catch (err) {
						setState(prevState => ({
							...prevState,
							isErr: true,
							errMessage: err.message,
						}));
						return;
					}
				}, [dsMainAction, props._authToken, props.id_tx_draft]);

				// set event on component mounting
				React.useEffect(() => {
					(async function () {
						if (props.uiMode == 'edit') {
							await loadData();

							const popupComponent = await appAction.fetchMenu(
								_moduleId,
								'popup_menu',
								props._authToken,
							);

							setState({
								...state,
								popupComponent,
							});
						} else {
							dsMainAction.addRow({});
						}
					})();
				}, [dsMainAction, loadData, props.uiMode]);

				// const saveDataClick = async () => {
				// 	try {
				// 		const { fieldValidStates, fieldValidErrors } =
				// 			dsMainProxy;
				// 		const isInValid = Object.entries(
				// 			fieldValidStates,
				// 		).filter(([i, v]) => {
				// 			return !v;
				// 		});

				// 		if (isInValid.length > 0)
				// 			throw new Error(fieldValidErrors[isInValid[0][0]]);

				// 		var dataUnload = dsMainProxy.unloadStore(
				// 			serverDataMapping,
				// 			{
				// 				includeLoadedRows: false,
				// 				includeDeletedRows: true,
				// 			},
				// 		);

				// 		await appAction.postData(
				// 			_moduleId,
				// 			'saveData',
				// 			props._authToken,
				// 			dataUnload,
				// 		);

				// 		appAction.frameAction.closeModal();
				// 		AlertHandler('Data berhasil disimpan', 'success');
				// 	} catch (err) {
				// 		AlertHandler(err.message, 'alert');
				// 	}
				// };

				const dsetProvider = React.useMemo(
					() => jsdset.dsetEmptyProvider(),
					[],
				);

				const AlertHandler = async (msg, type) => {
					await frameAction.showModal({
						contentClass: props => (
							<AlertComponent
								{...props}
								title={msg}
								type={type}
							/>
						),
						size: 'small',
					});
				};

				const ActionButtons = React.useCallback(clearFilter => {
					return [
						{
							type: 'bordered',
							name: 'Reload',
							onClick: () => clearFilter(),
							render: () => <FaRetweet />,
						},
					];
				}, []);

				const handleFilter = React.useCallback(
					e => {
						setFilter(prev => ({
							...prev,
							start_date: e.start_date || prev.start_date,
							end_date: e.end_date || prev.end_date,
						}));
					},
					[filter],
				);
				console.log(props);
				return (
					<div>
						<FormLabel label="ID Transaksi">
							<b>{props.id_tx_draft}</b>
						</FormLabel>
						<NDataBrowser
							stdAppAction={appAction}
							moduleId={_moduleId}
							dataId="listSO"
							dataProvider={dsetProvider}
							dsetPath="main"
							uiData={editUIData}
							authToken={props._authToken}
							params={{ id_tx_draft: props.id_tx_draft, ...filter }}
							filterComponent={({
								sortFields,
								clearFilter,
								changeParams,
								params,
							}) => {
								return (
									<Filter>
										{/* Tanggal */}
										{/* <FormLabel
												label="Tanggal Transaksi"
												style={{
													maxWidth: '250px',
													marginRight: '1rem',
												}}
											>
												<div
													style={{
														display: 'flex',
													}}
												>
													<DatePicker
														value={
															filter.start_date
														}
														onSelect={e => {
															handleFilter({
																start_date: e,
															});
														}}
													/>
													<div
														style={{ padding: 10 }}
													>
														S/D
													</div>
													<DatePicker
														value={filter.end_date}
														onSelect={e => {
															handleFilter({
																end_date: e,
															});
														}}
													/>
												</div>
											</FormLabel> */}

										{/* Sort & Search */}
										<FormLabel
											label="Urutkan & Cari"
											style={{
												maxWidth: '200px',
												marginRight: '1rem',
											}}
										>
											<Dropdown
												item={sortFields}
												width="-webkit-fill-available"
												value={params.sortField}
												onChange={e =>
													changeParams({
														sortField:
															e.target.value,
													})
												}
											/>
										</FormLabel>
										<FormLabel label="&nbsp;">
											<SearchBox
												label="Tampilkan"
												style={{
													marginLeft: 5,
													width: '300px',
												}}
												handleSearch={src => {
													changeParams({
														start_date:
															filter.start_date,
														end_date:
															filter.end_date,
														src: src,
														tx_type: filter.tx_type,
														status: filter.status,
														account_no:
															filter.account_no,
													});
												}}
												srcText={params.src}
											/>
										</FormLabel>
										<FormLabel label="&nbsp;">
											<FilterActionButtons
												buttons={ActionButtons(
													clearFilter,
												)}
											/>
										</FormLabel>
									</Filter>
								);
							}}
							popupComponent={p => (
								<state.popupComponent
									{...p}
									tx_code={props.tx_code}
									metode={dsMainProxy.fields.product_code}
									product_code={
										dsMainProxy.fields.product_code
									}
								/>
							)}
						/>

						<div
							style={{
								display: state.isErr ? 'block' : 'none',
							}}
						>
							{state.errMessage}
						</div>
					</div>
				);
			}

			// return value may be different depending on params
			return React.memo(AppForm);
		}

		return { componentFactory };
	}

	async function initModuleF(aReact, globals) {
		return await getImports(aReact, globals);
	}

	return initModuleF;
	// })()  //--DAFReact: deploy
} //--DAFReact: development
