import React from 'react'; //--DAFReact: development

// 'use strict'; //--DAFReact: deploy

export function ModuleDefinition () { //--DAFReact: development

// (function () { //--DAFReact: deploy

  async function getImports (React, globals) {

    // checking required libraries, may also include asynchronously loading other remote module, using appAction.loadModule
    const { _moduleId, StdAppAction, AppFrameAction, appAction, jsdset, dswidget, DataBrowser } = globals
    if (!StdAppAction || !AppFrameAction || !appAction || !jsdset || !dswidget || !DataBrowser) {
      throw new Error('One of required components (StdAppAction, AppFrameAction, appAction, jsdset, dswidget) not found in globals')
    }

    if (!_moduleId) {
      throw new Error('_moduleId not defined')
    }

    function componentFactory (params) {

      const metadata = {
        batchTransactionCorp: {
          fields: [
            {name: 'id_batch_detail', type: 'int', title: 'id_batch_detail'},
            {name: 'ref_number', type: 'string', title: 'ref_number', length:12},
            {name: 'credit_account_no', type: 'string', title: 'credit_account_no', length:200},
            {name: 'credit_account_name', type: 'string', title: 'credit_account_name', length:20},
            {name: 'detail_description', type: 'string', title: 'detail_description',length:100},
            {name: 'amount_type', type: 'string', title: 'amount_type', length:10},
            {name: 'amount', type: 'float', title: 'amount'},
            {name: 'amount_fee', type: 'float', title: 'amount_fee'},
            {name: 'total_amount_and_fee', type: 'float', title: 'total_amount_and_fee'},
          ],
  
          indexes: ['id_batch_detail']
        },
      
      }
      
      const initialData = {
        'main:batchTransactionCorp': []
      }
  
      const serverDataMapping = {
        data: {
          dset: 'main',
          type: 'batchTransactionCorp',
          fieldMapping: {
            '*': true, // allow default fields
          }
        }
      }

      const editUIData = {
        batchTransactionCorp: {
          fields: {
            amount: { thousand: true, decimal: 2 },
            amount_fee: { thousand: true, decimal: 2 },
            total_amount_and_fee: { thousand: true, decimal: 2 },
          },
        },
      }
  
      // return value may be different depending on params
      return { metadata, initialData, serverDataMapping, editUIData }
    }

    return { componentFactory }
  }

  async function initModuleF(aReact, globals) {
    return await getImports(aReact, globals)
  }

  return initModuleF
// })()  //--DAFReact: deploy

} //--DAFReact: development

