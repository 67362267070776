import React from 'react'; //--DAFReact: development

// 'use strict'; //--DAFReact: deploy

export function ModuleDefinition () { //--DAFReact: development

// (function () { //--DAFReact: deploy

  async function getImports (React, globals) {

    // checking required libraries, may also include asynchronously loading other remote module, using appAction.loadModule
    const { _moduleId, StdAppAction, AppFrameAction, appAction, jsdset, dswidget, DataBrowser } = globals
    if (!StdAppAction || !AppFrameAction || !appAction || !jsdset || !dswidget || !DataBrowser) {
      throw new Error('One of required components (StdAppAction, AppFrameAction, appAction, jsdset, dswidget) not found in globals')
    }

    if (!_moduleId) {
      throw new Error('_moduleId not defined')
    }

    function componentFactory (params) {

      const metadata = {
        transactionCode: {
          fields: [
            {name: 'tx_code', type: 'string', title: 'tx_code', length:10},
            {name: 'description', type: 'string', title: 'description', length:50}
          ],
  
          indexes: ['tx_code']
        },
      
      }
      
      const initialData = {
        'main:transactionCode': []
      }
  
      const serverDataMapping = {
        data: {
          dset: 'main',
          type: 'transactionCode',
          fieldMapping: {
            '*': true, // allow default fields
          }
        }
      }

      const editUIData = {
        transactionCode: {
          fields: {
          },
        },
      }
  
      // return value may be different depending on params
      return { metadata, initialData, serverDataMapping, editUIData }
    }

    return { componentFactory }
  }

  async function initModuleF(aReact, globals) {
    return await getImports(aReact, globals)
  }

  return initModuleF
// })()  //--DAFReact: deploy

} //--DAFReact: development

