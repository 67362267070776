import React from 'react'; //--DAFReact: development

// 'use strict'; //--DAFReact: deploy

export function ModuleDefinition () { //--DAFReact: development

// (function () { //--DAFReact: deploy

  async function getImports (React, globals) {

    // checking required libraries, may also include asynchronously loading other remote module, using appAction.loadModule
    const { _moduleId, StdAppAction, AppFrameAction, appAction, jsdset, dswidget, DataBrowser } = globals
    if (!StdAppAction || !AppFrameAction || !appAction || !jsdset || !dswidget || !DataBrowser) {
      throw new Error('One of required components (StdAppAction, AppFrameAction, appAction, jsdset, dswidget) not found in globals')
    }

    if (!_moduleId) {
      throw new Error('_moduleId not defined')
    }

    function componentFactory (params) {

      const metadata = {
        digitalTransaction: {
          fields: [
            {name: 'id_transaction', type: 'integer', title: 'id_transaction'},
            {name: 'corporate_code', type: 'string', title: 'corporate_code', length:20},
            {name: 'ref_number', type: 'string', title: 'ref_number', length:20},
            {name: 'inquiry_ref_number', type: 'string', title: 'inquiry_ref_number', length:12},
            {name: 'account_no', type: 'string', title: 'account_no', length:20},
            {name: 'virtual_acc_code', type: 'string', title: 'virtual_acc_code', length:20},
            {name: 'channel_typ', type: 'string', title: 'channel_typ', length:10},
            {name: 'exec_status', type: 'string', title: 'exec_status', length:1},
            {name: 'issuer_ref', type: 'string', title: 'issuer_ref', length:20},
            {name: 'issuer_status_code', type: 'string', title: 'issuer_status_code', length:3},
            {name: 'user_id', type: 'string', title: 'user_id', length:20},
            {name: 'terminal_id', type: 'string', title: 'terminal_id', length:32},
            {name: 'tx_date', type: 'date', title: 'tx_date'},
            {name: 'tx_timestamp', type: 'date', title: 'tx_timestamp'},
            {name: 'tx_type', type: 'string', title: 'tx_type', length:10},
            {name: 'tx_code', type: 'string', title: 'tx_code', length:10},
            {name: 'amount_net', type: 'float', title: 'amount_net'},
            {name: 'amount', type: 'float', title: 'amount'},
            {name: 'amount_ex', type: 'float', title: 'amount_ex'},
            {name: 'amount_fee', type: 'float', title: 'amount_fee'},
            {name: 'redeem_point_code', type: 'string', title: 'redeem_point_code', length:20},
            {name: 'redeem_point', type: 'float', title: 'redeem_point'},
            {name: 'promotion_code', type: 'string', title: 'promotion_code', length:20},
            {name: 'service_ref', type: 'string', title: 'service_ref', length:12},
            {name: 'product_code', type: 'string', title: 'product_code', length:20},
            {name: 'credit_account_no', type: 'string', title: 'credit_account_no', length:10},
            {name: 'credit_account_name', type: 'string', title: 'credit_account_name', length:10},
            {name: 'credit_status', type: 'string', title: 'credit_status', length:10},
            {name: 'credit_status_code', type: 'string', title: 'credit_status_code', length:10},
            {name: 'balance_before', type: 'float', title: 'balance_before'},
            {name: 'balance_after', type: 'float', title: 'balance_after'},
            {name: 'tx_mode', type: 'string', title: 'tx_mode', length:10},
            {name: 'vaccount_no', type: 'string', title: 'vaccount_no', length:10},
            {name: 'tx_va_refnumber', type: 'string', title: 'tx_va_refnumber', length:10},
            {name: 'billing_id', type: 'integer', title: 'billing_id'}
          ],
  
          indexes: ['id_transaction']
        },
      
      }
      
      const initialData = {
        'main:digitalTransaction': []
      }
  
      const serverDataMapping = {
        data: {
          dset: 'main',
          type: 'digitalTransaction',
          fieldMapping: {
            '*': true, // allow default fields
          }
        }
      }

      const editUIData = {
        digitalTransaction: {
          fields: {
          },
        },
      }
  
      // return value may be different depending on params
      return { metadata, initialData, serverDataMapping, editUIData }
    }

    return { componentFactory }
  }

  async function initModuleF(aReact, globals) {
    return await getImports(aReact, globals)
  }

  return initModuleF
// })()  //--DAFReact: deploy

} //--DAFReact: development

