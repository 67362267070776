import React from 'react'; //--DAFReact: development

// 'use strict'; //--DAFReact: deploy

export function ModuleDefinition() { //--DAFReact: development

  // (function () { //--DAFReact: deploy

  async function getImports(React, globals) {

    // checking required libraries, may also include asynchronously loading other remote module, using appAction.loadModule
    const { _moduleId, StdAppAction, AppFrameAction, appAction, jsdset, dswidget, DataBrowser } = globals
    if (!StdAppAction || !AppFrameAction || !appAction || !jsdset || !dswidget || !DataBrowser) {
      throw new Error('One of required components (StdAppAction, AppFrameAction, appAction, jsdset, dswidget) not found in globals')
    }

    if (!_moduleId) {
      throw new Error('_moduleId not defined')
    }

    function componentFactory(params) {

      const metadata = {
        auth_Control: {
          fields: [
            { name: 'id_auth', type: 'string', title: 'ref_number', length: 10 },
            { name: 'data_name', type: 'string', title: 'data_name', length: 50 },
            { name: 'operation_id', type: 'string', title: 'operation_id', length: 1 },
            { name: 'description', type: 'string', title: 'description', length: 100 },
            { name: 'entry_user_id', type: 'string', title: 'entry_user_id', length: 20 },
            { name: 'entry_user_name', type: 'string', title: 'entry_user_id', length: 50 },
            { name: 'entry_datetime', type: 'date', title: 'entry_datetime' },
          ],
          indexes: ['id_auth']
        },

      }

      const initialData = {
        'main:auth_Control': []
      }

      const serverDataMapping = {
        data: {
          dset: 'main',
          type: 'auth_Control',
          fieldMapping: {
            '*': true, // allow default fields
          }
        }
      }

      const editUIData = {
        auth_Control: {
          fields: {
          },
        },
      }

      // return value may be different depending on params
      return { metadata, initialData, serverDataMapping, editUIData }
    }

    return { componentFactory }
  }

  async function initModuleF(aReact, globals) {
    return await getImports(aReact, globals)
  }

  return initModuleF
  // })()  //--DAFReact: deploy

} //--DAFReact: development

