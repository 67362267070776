/*
	DAFReact framework module - Application form (hook)
	appname: auth_Control
	filename: auth_ControlEdit.js
	moduleid: auth_ControlEdit
	author: IK
*/

import React from 'react'; //--DAFReact: development

// 'use strict'; //--DAFReact: deploy

export function ModuleDefinition() {
	//--DAFReact: development

	// (function () { //--DAFReact: deploy

	async function getImports(React, globals) {
		// checking required libraries, may also include asynchronously loading other remote module, using appAction.loadModule
		const {
			_moduleId,
			StdAppAction,
			appAction,
			jsdset,
			dswidget,
			frameAction,
			ndswidget,
			dswidgetex,
			DataBrowser,
			staticComponents: {
				RadioGroup,
				Button,
				FormLabel,
				RadioIcon,
				DatePicker,
				ReactIconFa: { FaSearch, FaCheck },
			},
		} = globals;
		if (!_moduleId || !StdAppAction || !appAction || !jsdset || !dswidget) {
			throw new Error(
				'One of required components (_moduleId, StdAppAction, appAction, jsdset, dswidget) not found in globals',
			);
		}

		/* other required modules are asynchronously loaded here */
		const { metadata, initialData, serverDataMapping, editUIData } =
			await appAction.fetchAndExecModule(
				'auth_control.auth_ControlMetadata',
			);

		const { PanelDataDisplay, PanelButton } = dswidget;
		const { FieldDataDisplay, FieldDataInput } = ndswidget;
		const { DateFieldDataInput } = dswidgetex;

		function componentFactory(params) {
			function AppForm(props) {
				const dataContext = React.useMemo(
					() => jsdset.dsetCreateContext(),
					[],
				);
				const DSetProvider = React.useMemo(
					() =>
						jsdset.dsetMetaProvider(
							dataContext,
							metadata,
							initialData,
							editUIData,
						),
					[],
				);
				return (
					<DSetProvider>
						<AppFormUI dataContext={dataContext} {...props} />
						{/* any other props will be passed down */}
					</DSetProvider>
				);
			}

			function AppFormUI(props) {
				const [state, setState] = React.useState({
					isErr: false,
					errMessage: '',
					isEditing: false,
					detail: []
				});

				// bind controls to _moduleId and _authToken
				const vComps = React.useMemo(
					() =>
						appAction.connect(
							{
								PanelDataDisplay,
								FieldDataDisplay,
								PanelButton,
								FieldDataInput,
							},
							{ _moduleId, _getToken: () => props._authToken },
						),
					[],
				);

				// bind controls to datasets
				const [mainComps] = React.useMemo(
					() => [
						jsdset.connect(
							{ context: props.dataContext, dsetPath: 'main' },
							vComps,
						),
					],
					[props.dataContext, vComps],
				);

				// obtain action objects from data context
				const [, dsMainAction, dsMainProxy] = jsdset.useDSetContext(
					props.dataContext,
					'main',
				);

				// load data function
				const loadData = React.useCallback(async () => {
					setState(state => ({
						...state,
						isErr: false,
						errMessage: '',
					}));

					try {
						const response = await appAction.fetchResource(
							_moduleId,
							'method',
							'getAuthDet',
							props._authToken,
							{ id_auth: props.id_auth },
							true,
						);

						// console.log('=======', response);
						if (response?.data[0].details) {
							console.log(response?.data[0].details)
							setState({
								...state,
								detail: response?.data[0].details
							})
						}
						dsMainAction.loadStore(
							response,
							'std',
							serverDataMapping,
							true,
						);
						dsMainAction.recalcFormulas();
					} catch (err) {
						setState(prevState => ({
							...prevState,
							isErr: true,
							errMessage: err.message,
						}));
						return;
					}
				}, [dsMainAction, props._authToken, props.id_auth]);

				// set event on component mounting
				React.useEffect(() => {
					(async function () {
						loadData()
					})();
				}, [dsMainAction, loadData, props.uiMode]);

				const Capital = (text) => {
					text = text.replace('_', ' ')
					if (text) {
						return text
							.toString()
							.toLowerCase()
							.replace(/\b[a-z]/g, function (letter) {
								return letter.toUpperCase();
							});
					} else {
						return text;
					}
				}

				const HandleDetail = ({ data }) => {
					let datas = (Object.entries(data))
					return (
						<div>
							{
								datas.map((item, index) => {
									if (item[0] !== 'id_auth') {
										return (
											<FormLabel key={index} label={Capital(item[0]).replace('_', ' ')}>
												<b>
													{item[1] !== '' && item[1] !== null ? item[1] : '-'}
												</b>
											</FormLabel>
										)
									} else {
										return null
									}
								})
							}
						</div>
					)
				}

				// render
				return (
					<div>
						<div
							style={{
								display: 'flex',
								width: '-webkit-fill-available',
							}}
						>
							<div
								style={{
									marginRight: 10,
									width: '-webkit-fill-available',
								}}
							>
								<FormLabel label="ID otorisasi">
									<b>
										<mainComps.FieldDataDisplay fieldName="id_auth" />
									</b>
								</FormLabel>
								{/* <FormLabel label="Nama Data">
									<b>
										<mainComps.FieldDataDisplay fieldName="data_name" />
									</b>
								</FormLabel> */}
								<FormLabel label="ID Operasi">
									<b>
										<mainComps.FieldDataDisplay fieldName="operation_id" />
									</b>
								</FormLabel>
								<FormLabel label="Deskripsi">
									<b>
										<mainComps.FieldDataDisplay fieldName="description" />
									</b>
								</FormLabel>
								<FormLabel label="ID User Entry">
									<b>
										<mainComps.FieldDataDisplay fieldName="entry_user_id" />
									</b>
								</FormLabel>
								<FormLabel label="User Entry">
									<b>
										<mainComps.FieldDataDisplay fieldName="entry_user_name" />
									</b>
								</FormLabel>
								<FormLabel label="Tanggal Entry">
									<b>
										<mainComps.FieldDataDisplay fieldName="entry_datetime" />
									</b>
								</FormLabel>

							</div>
							<div
								style={{
									// marginRight: 10,
									width: '-webkit-fill-available',
								}}
							>
								{state?.detail?.length > 0 ?
									<HandleDetail data={state?.detail[0]} />
									: null}

							</div>
						</div>


						<br />
						{/* <button onClick={saveDataClick}>Save</button> */}

						<div
							style={{ display: state.isErr ? 'block' : 'none' }}
						>
						</div>
					</div>
				);
			}

			// return value may be different depending on params
			return React.memo(AppForm);
		}

		return { componentFactory };
	}

	async function initModuleF(aReact, globals) {
		return await getImports(aReact, globals);
	}

	return initModuleF;
	// })()  //--DAFReact: deploy
} //--DAFReact: development
