/*
  DAFReact framework module - Application form (hook)
  appname: finProductCode
  filename: finProductCodeList.js
  moduleid: finProductCodeList
  author: IK
*/

// 'use strict';

export function ModuleDefinition() {
	//--DAFReact: development

	// (function () { //--DAFReact: deploy

	async function getImports(React, globals) {
		const {
			_moduleId,
			StdAppAction,
			AppFrameAction,
			frameAction,
			appAction,
			jsdset,
			dswidget,
			DataBrowser,
			NDataBrowser,
			staticComponents: {
				PageTitle,
				SearchBox,
				Filter,
				FilterActionButtons,
				ReactIconFa: { FaRetweet },
				ReactIconSi: { SiMicrosoftexcel },
				Dropdown, AlertComponent
			},
		} = globals;
		if (
			!_moduleId ||
			!StdAppAction ||
			!AppFrameAction ||
			!appAction ||
			!jsdset ||
			!dswidget ||
			!DataBrowser
		) {
			throw new Error(
				'One of required components (_moduleId, StdAppAction, AppFrameAction, appAction, jsdset, dswidget, _moduleId) not found in globals',
			);
		}

		const { metadata, initialData, serverDataMapping, editUIData } =
			await appAction.fetchAndExecModule(
				'parameter.finProductCodeMetadata',
			);

		function componentFactory(params) {
			function AppForm(props) {
				const [state, setState] = React.useState({
					popupComponent: undefined,
				});

				const dsetProvider = React.useMemo(
					() => jsdset.dsetEmptyProvider(),
					[],
				);

				React.useEffect(() => {
					(async function () {
						const popupComponent = await appAction.fetchMenu(
							_moduleId,
							'popup_menu',
							props._authToken,
						);
						setState({ popupComponent });
					})();
				}, [props._authToken]);


				const AlertHandler = async (msg, type, body) => {
					await frameAction.showModal({
						contentClass: props => (
							<AlertComponent
								{...props}
								title={msg}
								type={type}
								body={body}
							/>
						),
						size: 'small',
					});
				};

				const ActionButtons = React.useCallback(
					({ clearFilter, params }) => {
					return [
						{
							type: 'bordered',
							name: 'Export Excel',
							onClick: async () => {
								try {

									await appAction.postData(
										_moduleId,
										'exportList',
										props._authToken,
										{
											...params,
											// ...filter 
										},
										{}
									);
								} catch (error) {
									AlertHandler(error.message, 'alert');
								}

							},
							render: () => <SiMicrosoftexcel />,
						},
						{
							type: 'bordered',
							name: 'Reload',
							onClick: () => {
								clearFilter();
							},
							render: () => <FaRetweet />,
						},
					];
				});

				return (
					<>
						<PageTitle
							subtitle="Management Korporat"
							title="Parameter Jenis Produk"
							style={{ marginBottom: `1.5rem` }}
						/>

						<NDataBrowser
							stdAppAction={appAction}
							moduleId={_moduleId}
							dataId="listSO"
							dataProvider={dsetProvider}
							dsetPath="main"
							popupComponent={state.popupComponent}
							authToken={props._authToken}
							uiData={editUIData}
							filterComponent={({
								sortFields,
								clearFilter,
								changeParams,
								params,
							}) => (
								<Filter>
									<Dropdown
										label="Sort and search: "
										onChange={e =>
											changeParams({
												sortField: e.target.value,
											})
										}
										item={sortFields}
										value={params.sortField}
									/>

									<SearchBox
										style={{
											marginLeft: 5,
										}}
										label="Tampilkan"
										handleSearch={src =>
											changeParams({ src })
										}
										srcText={params.src}
									/>

									{/* actionbox */}
									<FilterActionButtons
										buttons={ActionButtons({
											clearFilter,
											params,
										})}
									/>
								</Filter>
							)}
						/>
					</>
				);
			}

			return React.memo(AppForm);
		}

		return { componentFactory };
	}

	return async (aReact, globals) => {
		return await getImports(aReact, globals);
	};

	// })()  //--DAFReact: deploy
} //--DAFReact: development
