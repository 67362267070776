export function ModuleDefinition() {
	async function getImports(React, globals) {
		const {
			_moduleId,
			StdAppAction,
			appAction,
			jsdset,
			dswidget,
			ndswidget,
			frameAction,
			staticComponents: { FormLabel, Button, AlertComponent },
		} = globals;
		if (!_moduleId || !StdAppAction || !appAction || !jsdset || !dswidget) {
			throw new Error('One of required components (_moduleId, StdAppAction, appAction, jsdset, dswidget) not found in globals');
		}

		const { metadata, initialData, serverDataMapping, editUIData } = await appAction.fetchAndExecModule('settings.autosweep.autosweep-metadata');

		const { FieldDataInput, FieldDataDisplay } = ndswidget;

		function componentFactory() {
			function AppForm(props) {
				const dataContext = React.useMemo(() => jsdset.dsetCreateContext(), []);
				const DSetProvider = React.useMemo(() => jsdset.dsetMetaProvider(dataContext, metadata, initialData, editUIData), []);
				return (
					<DSetProvider>
						<AppFormUI dataContext={dataContext} {...props} />
					</DSetProvider>
				);
			}

			function AppFormUI(props) {
				const [state, setState] = React.useState({
					isErr: false,
					errMessage: '',
					isEditing: false,
				});

				const vComps = React.useMemo(
					() =>
						appAction.connect(
							{
								FieldDataDisplay,
								FieldDataInput,
							},
							{ _moduleId, _getToken: () => props._authToken },
						),
					[],
				);

				// bind controls to datasets
				const [mainComps] = React.useMemo(() => [jsdset.connect({ context: props.dataContext, dsetPath: 'main' }, vComps)], [props.dataContext, vComps]);
				const [, dsMainAction, dsMainProxy] = jsdset.useDSetContext(props.dataContext, 'main');

				const loadData = React.useCallback(async () => {
					setState(state => ({
						...state,
						isErr: false,
						errMessage: '',
					}));

					try {
						const response = await appAction.fetchResource(
							_moduleId,
							'method',
							'getSingleData',
							props._authToken,
							{ main_account: props.main_account, second_account: props.second_account },
							true,
						);

						if (response?.data?.length > 0) {
							dsMainAction.loadStore(response, 'std', serverDataMapping, true);
						} else {
							appAction.frameAction.closeModal();
							AlertHandler('Data Tidak Bisa di Ubah', 'warning');
						}
					} catch (err) {
						setState(prevState => ({
							...prevState,
							isErr: true,
							errMessage: err.message,
						}));
						return;
					}
				}, [dsMainAction, props._authToken, props.user_id]);

				React.useEffect(() => {
					(async function () {
						if (props.uiMode == 'edit') {
							await loadData();
						} else {
							dsMainAction.addRow({});
						}
					})();
				}, [props._authToken]);

				const saveDataClick = async () => {
					try {
						const { fieldValidStates, fieldValidErrors } = dsMainProxy;

						const isInValid = Object.entries(fieldValidStates).filter(([i, v]) => {
							return !v;
						});

						if (isInValid.length > 0) throw new Error(fieldValidErrors[isInValid[0][0]]);

						if (dsMainProxy?.fields) {
							await appAction.postData(_moduleId, 'saveData', props._authToken, { ...dsMainProxy?.fields, ac_type: dsMainProxy?.fields?.type });
							appAction.frameAction.closeModal();
							AlertHandler('Data berhasil disimpan', 'success', '');
						} else {
							appAction.frameAction.closeModal();
						}
					} catch (err) {
						AlertHandler(err.message, 'alert');
					}
				};

				const AlertHandler = async (msg, type, body) => {
					await frameAction.showModal({
						contentClass: props => <AlertComponent {...props} title={msg} type={type} body={body} />,
						size: 'small',
					});
				};

				return (
					<div>
						<FormLabel label="Nomor Rekening">
							<mainComps.FieldDataInput fieldName="main_account" />
						</FormLabel>
						<FormLabel label="Nomor Rekening Tujuan / Sumber">
							<mainComps.FieldDataInput fieldName="second_account" />
						</FormLabel>
						<FormLabel label="Batas Minimum">
							<mainComps.FieldDataInput fieldName="thresh_min" />
						</FormLabel>
						<FormLabel label="Batas Maksimum">
							<mainComps.FieldDataInput fieldName="thresh_max" />
						</FormLabel>
						<FormLabel label="Jenis Sweep">
							<mainComps.FieldDataInput fieldName="sweep_type" />
						</FormLabel>
						<FormLabel label="Tipe Sweep">
							<mainComps.FieldDataInput fieldName="type" />
						</FormLabel>
						<div
							style={{
								display: `flex`,
								justifyContent: `flex-end`,
								marginTop: 10,
							}}
						>
							<Button onClick={() => appAction.frameAction.closeModal()} type="bordered" style={{ marginRight: 10 }}>
								Batalkan
							</Button>
							<Button onClick={saveDataClick}>Simpan</Button>
						</div>
					</div>
				);
			}

			// return value may be different depending on params
			return React.memo(AppForm);
		}

		return { componentFactory };
	}

	async function initModuleF(aReact, globals) {
		return await getImports(aReact, globals);
	}

	return initModuleF;
}
