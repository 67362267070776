/*
  DAFReact framework module - Application form (hook)
  appname: digitalTransaction
  filename: digitalTransactionList.js
  moduleid: digitalTransactionList
  author: IK
*/


// 'use strict';

export function ModuleDefinition() { //--DAFReact: development

  // (function () { //--DAFReact: deploy

  async function getImports(React, globals) {

    const { _moduleId, StdAppAction, AppFrameAction, appAction, jsdset, dswidget, DataBrowser, frameAction,
      staticComponents: {
        Button,
        PageTitle,
        SearchBox,
        RadioIcon,
        Filter,
        DatePicker,
        FilterActionButtons,
        ReactIconFa,
        ReactIconSi,
        Input,
        FormLabel,
        Dropdown
      }, } = globals
    if (!_moduleId || !StdAppAction || !AppFrameAction || !appAction || !jsdset || !dswidget || !DataBrowser) {
      throw new Error('One of required components (_moduleId, StdAppAction, AppFrameAction, appAction, jsdset, dswidget, _moduleId) not found in globals')
    }


    const { FaPlusCircle, FaCheck, FaUserPlus } = ReactIconFa;
    const { SiMicrosoftexcel } = ReactIconSi;


    function componentFactory(params) {

      function AppForm(props) {
        const [state, setState] = React.useState({
          popupComponent: undefined,
          maxRow: 20,
        })

        const dsetProvider = React.useMemo(() => jsdset.dsetEmptyProvider(), [])

        React.useEffect(
          () => {
            (async function () {
              const popupComponent = await appAction.fetchMenu(_moduleId, 'popup_menu', props._authToken)
              setState({ popupComponent })
            })()
          },
          [props._authToken]
        )


        const handleChangeMaxRow = maxRow => {
          return setState({ ...state, maxRow });
        };

        return (
          <>
            {/* PageTitle */}
            <div
              style={{
                display: `flex`,
                justifyContent: `space-between`,
                alignItems: `center`,
              }}
            >
              <PageTitle
                subtitle="Monitoring"
                title="Histori Transaksi (Single)"
                style={{ marginBottom: `1.5rem` }}
              />

            </div>

            {/* Separator */}
            <div
              style={{
                width: `100%`,
                height: `1px`,
                backgroundColor: `#e6e6e6`,
                margin: `2rem 0`,
              }}
            ></div>


            <DataBrowser
              stdAppAction={appAction}
              moduleId={_moduleId}
              dataId='listSO'
              dataProvider={dsetProvider}
              useServerFields={true}
              dsetPath="main"
              popupComponent={state.popupComponent}
              reqAuthToken={true}
              authToken={props._authToken}
              visualProps={{
                filterComponent: ({ loadPaging, srcText, setSrcText, rows }) => (
                  <>
                    <h5>Cari History Transaksi</h5>
                    <Filter >

                      <FormLabel label="ID Perusahaaan">
                        <Dropdown item={[{ name: 1, value: 1 }, { name: 2, value: 2 }, { name: 3, value: 3 },]} style={{
                          flexGrow: 1
                        }} />
                      </FormLabel>
                      <FormLabel label="Tanggal Transaksi" >
                        <div style={{
                          display: 'flex'
                        }}>
                          <DatePicker /><div style={{ padding: 10 }}>Sampai</div>
                          <DatePicker />
                        </div>
                      </FormLabel>


                    </Filter>
                    <Filter >

                      {/* search box  */}
                      <SearchBox
                        handleSearch={src =>
                          loadPaging('search', src)
                        }
                        srcText={srcText}
                      />


                    </Filter>
                  </>
                ),
                setMaxRow: handleChangeMaxRow,
                maxRow: state.maxRow,
              }}
              maxRow={state.maxRow}
              hideNavButtons={true}
              hideSortOptions={true}
              hideSearch={true}
            />
          </>
        )
      }

      return React.memo(AppForm)
    }

    return { componentFactory }
  }

  return async (aReact, globals) => { return await getImports(aReact, globals) }

  // })()  //--DAFReact: deploy

} //--DAFReact: development

