/*
  DAFReact framework module - Application form (hook)
  appname: auth_ControlHist
  filename: auth_ControlHistList.js
  moduleid: auth_ControlHistList
  author: IK
*/

// 'use strict';

export function ModuleDefinition() {
	//--DAFReact: development

	// (function () { //--DAFReact: deploy

	async function getImports(React, globals) {
		const {
			_moduleId,
			StdAppAction,
			AppFrameAction,
			appAction,
			jsdset,
			dswidget,
			DataBrowser,
			NDataBrowser,
			frameAction,
			staticComponents: {
				Button,
				PageTitle,
				SearchBox,
				RadioIcon,
				Filter,
				FilterActionButtons,
				ReactIconFa,
				ReactIconSi,
				RadioGroup,
				FormLabel,
				DatePicker,
				AlertComponent,
				Dropdown,
			},
		} = globals;
		if (
			!_moduleId ||
			!StdAppAction ||
			!AppFrameAction ||
			!appAction ||
			!jsdset ||
			!dswidget ||
			!DataBrowser
		) {
			throw new Error(
				'One of required components (_moduleId, StdAppAction, AppFrameAction, appAction, jsdset, dswidget, _moduleId) not found in globals',
			);
		}

		const { metadata, initialData, serverDataMapping, editUIData } =
			await appAction.fetchAndExecModule(
				'auth_control_corp.auth_ControlHistCorpMetadata',
			);

		const { FaPlusCircle, FaRetweet } = ReactIconFa;
		const { SiMicrosoftexcel } = ReactIconSi;

		function componentFactory(params) {
			function AppForm(props) {
				const [state, setState] = React.useState({
					popupComponent: undefined,
					maxRow: 10,
				});

				const dsetProvider = React.useMemo(
					() => jsdset.dsetEmptyProvider(),
					[],
				);

				const initialFilter = {
					start_date: new Date(),
					end_date: new Date(),
					status: '',
				};

				const [filter, setFilter] = React.useState(initialFilter);

				React.useEffect(() => {
					(async function () {
						const popupComponent = await appAction.fetchMenu(
							_moduleId,
							'popup_menu',
							props._authToken,
						);
						setState({ popupComponent });
					})();
				}, [props._authToken]);

				const AlertHandler = async (msg, type, body) => {
					await frameAction.showModal({
						contentClass: props => (
							<AlertComponent
								{...props}
								title={msg}
								type={type}
								body={body}
							/>
						),
						size: 'small',
					});
				};

				const ActionButtons = React.useCallback(
					({ clearFilter, params }) => {
						return [
							{
								type: 'bordered',
								name: 'Export Excel',
								onClick: async () => {
									try {
										await appAction.postData(
											_moduleId,
											'exportList',
											props._authToken,
											{
												...params,
												// ...filter
											},
											{},
										);
									} catch (error) {
										AlertHandler(error.message, 'alert');
									}
								},
								render: () => <SiMicrosoftexcel />,
							},
							{
								type: 'bordered',
								name: 'Reload',
								onClick: () => {
									clearFilter();
									handleFilter({
										end_date: new Date(),
										start_date: new Date(),
										status: '',
									});
								},
								render: () => <FaRetweet />,
							},
						];
					},
					[],
				);

				const handleFilter = React.useCallback(
					e => {
						setFilter(
							e
								? {
										...filter,
										...e,
								  }
								: initialFilter,
						);
					},
					[filter],
				);

				return (
					<>
						{/* PageTitle */}
						<PageTitle
							subtitle="Data Otorisasi"
							title="Histori Otorisasi"
						></PageTitle>

						<NDataBrowser
							stdAppAction={appAction}
							moduleId={_moduleId}
							dataId="listSO"
							dataProvider={dsetProvider}
							authToken={props._authToken}
							dsetPath="main"
							uiData={editUIData}
							filterComponent={({
								sortFields,
								loadDataClick,
								clearFilter,
								changeParams,
								params,
							}) => {
								// setLoadFunction(loadDataClick);

								return (
									<>
										<Filter>
											<FormLabel
												label="Tanggal Transaksi"
												style={{
													marginRight: 10,
													width: '-webkit-fill-available',
												}}
											>
												<div
													style={{
														display: 'flex',
													}}
												>
													<DatePicker
														value={
															filter.start_date
														}
														onChange={e => {
															handleFilter({
																start_date: e,
															});
														}}
													/>
													<div
														style={{ padding: 10 }}
													>
														Sampai
													</div>
													<DatePicker
														value={filter.end_date}
														onChange={e => {
															handleFilter({
																end_date: e,
															});
														}}
													/>
													{/* <div style={{ padding: 5 }}></div>
                                                <Button  >Tampilkan</Button>
                                                <div style={{ padding: 5 }}></div> */}
												</div>
											</FormLabel>
										</Filter>
										<Filter>
											<FormLabel
												label="Status"
												style={{
													width: '-webkit-fill-available',
												}}
											>
												<div
													style={{
														display: 'flex',
														alignItems: 'center',
													}}
												>
													<RadioGroup
														data={Object.entries({
															'': 'Semua',
															T: 'Disetujui',
															D: 'Ditolak',
														})}
														name={'status'}
														currentValue={
															filter?.status
														}
														initialValue={''}
														type={'icon'}
														varian={'bordered'}
														onChange={e => {
															handleFilter({
																status: e.target
																	.value,
															});
														}}
													/>
												</div>
											</FormLabel>
											<FormLabel
												label="Urutkan dan Cari"
												style={{
													width: '-webkit-fill-available',
													marginRight: 20,
												}}
											>
												<Dropdown
													label="Sort and search: "
													onChange={e =>
														changeParams({
															sortField:
																e.target.value,
														})
													}
													item={sortFields}
													value={params.sortField}
												/>
											</FormLabel>
											<FormLabel label="Kata Kunci">
												<SearchBox
													style={{
														width: 500,
													}}
													label="Tampilkan"
													handleSearch={src => {
														console.log({
															status: filter.status,
															start_date:
																filter.start_date,
															end_date:
																filter.end_date,
															src: src,
														});
														changeParams({
															status: filter.status,
															start_date:
																filter.start_date,
															end_date:
																filter.end_date,
															src: src,
														});
													}}
													srcText={params.src}
												/>
											</FormLabel>

											{/* actionbox */}
											<FormLabel
												label="&nbsp;"
												style={{
													width: '-webkit-fill-available',
												}}
											>
												<FilterActionButtons
													buttons={ActionButtons({
														clearFilter,
														params,
													})}
												/>
											</FormLabel>
										</Filter>
									</>
								);
							}}
							popupComponent={props => (
								<state.popupComponent {...props} />
							)}
						/>
					</>
				);
			}

			return React.memo(AppForm);
		}

		return { componentFactory };
	}

	return async (aReact, globals) => {
		return await getImports(aReact, globals);
	};

	// })()  //--DAFReact: deploy
} //--DAFReact: development
