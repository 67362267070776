/*
  DAFReact framework module - Application form (hook)
  appname: auth_Control
  filename: auth_ControlList.js
  moduleid: auth_ControlList
  author: IK
*/

// 'use strict';

export function ModuleDefinition() {
	//--DAFReact: development

	// (function () { //--DAFReact: deploy

	async function getImports(React, globals) {
		const {
			_moduleId,
			StdAppAction,
			AppFrameAction,
			appAction,
			jsdset,
			dswidget,
			DataBrowser,
			NDataBrowser,
			frameAction,
			staticComponents: {
				PageTitle,
				SearchBox,
				Filter,
				FilterActionButtons,
				ReactIconFa,
				ReactIconSi,
				FormLabel,
				AlertComponent,
				Dropdown,
			},
		} = globals;
		if (!_moduleId || !StdAppAction || !AppFrameAction || !appAction || !jsdset || !dswidget || !DataBrowser) {
			throw new Error(
				'One of required components (_moduleId, StdAppAction, AppFrameAction, appAction, jsdset, dswidget, _moduleId) not found in globals',
			);
		}

		const { metadata, initialData, serverDataMapping, editUIData } = await appAction.fetchAndExecModule(
			'auth_control_corp.auth_ControlCorpMetadata',
		);

		const { FaRetweet } = ReactIconFa;
		const { SiMicrosoftexcel } = ReactIconSi;

		function componentFactory(params) {
			function AppForm(props) {
				const [state, setState] = React.useState({
					popupComponent: undefined,
					maxRow: 10,
					filter: {
						status: '',
					},
				});
				const dsetProvider = React.useMemo(() => {
					return jsdset.dsetEmptyProvider();
				}, []);

				React.useEffect(() => {
					(async function () {
						const popupComponent = await appAction.fetchMenu(_moduleId, 'popup_menu', props._authToken);
						setState({ ...state, popupComponent });
					})();
				}, [props._authToken]);

				const AlertHandler = async (msg, type, body) => {
					await frameAction.showModal({
						contentClass: props => <AlertComponent {...props} title={msg} type={type} body={body} />,
						size: 'small',
					});
				};

				const ActionButtons = React.useCallback(({ clearFilter, params }) => {
					return [
						{
							type: 'bordered',
							name: 'Export Excel',
							onClick: async () => {
								try {
									await appAction.postData(
										_moduleId,
										'exportList',
										props._authToken,
										{
											...params,
											// ...filter
										},
										{},
									);
								} catch (error) {
									AlertHandler(error.message, 'alert');
								}
							},
							render: () => <SiMicrosoftexcel />,
						},
						{
							type: 'bordered',
							name: 'Reload',
							onClick: () => clearFilter(),
							render: () => <FaRetweet />,
						},
					];
				}, []);

				return (
					<>
						{/* PageTitle */}

						<PageTitle subtitle="Data Otorisasi" title="Belum Otorisasi"></PageTitle>

						<NDataBrowser
							stdAppAction={appAction}
							moduleId={_moduleId}
							dataId="listSO"
							dataProvider={dsetProvider}
							authToken={props._authToken}
							dsetPath="main"
							uiData={editUIData}
							filterComponent={({ sortFields, loadDataClick, clearFilter, changeParams, params }) => {
								// setLoadFunction(loadDataClick);

								return (
									<Filter>
										{/* sort and search by */}
										<FormLabel
											label="Urutkan dan Cari"
											style={{
												width: 500,
												marginRight: 20,
											}}
										>
											<Dropdown
												label="Sort and search: "
												onChange={e =>
													changeParams({
														sortField: e.target.value,
													})
												}
												item={sortFields}
												value={params.sortField}
											/>
										</FormLabel>

										{/* search box  */}
										<FormLabel label="Kata Kunci">
											<SearchBox
												style={{
													width: 500,
												}}
												handleSearch={src => changeParams({ src })}
												srcText={params.src}
											/>
										</FormLabel>

										{/* actionbox */}
										<FormLabel
											label="&nbsp;"
											style={{
												width: '-webkit-fill-available',
											}}
										>
											<FilterActionButtons
												buttons={ActionButtons({
													clearFilter,
													params,
												})}
											/>
										</FormLabel>
									</Filter>
								);
							}}
							popupComponent={props => <state.popupComponent {...props} />}
						/>
					</>
				);
			}

			return React.memo(AppForm);
		}

		return { componentFactory };
	}

	return async (aReact, globals) => {
		return await getImports(aReact, globals);
	};

	// })()  //--DAFReact: deploy
} //--DAFReact: development
