// 'use strict'; //--DAFReact: deploy

export function ModuleDefinition() {
	//--DAFReact: development

	// (function () { //--DAFReact: deploy

	async function getImports(React, globals) {
		// checking required libraries, may also include asynchronously loading other remote module, using appAction.loadModule
		const {
			_moduleId,
			StdAppAction,
			AppFrameAction,
			appAction,
			jsdset,
			dswidget,
			DataBrowser,
			staticComponents: {
				Validator: { isExist },
			},
		} = globals;
		if (
			!StdAppAction ||
			!AppFrameAction ||
			!appAction ||
			!jsdset ||
			!dswidget ||
			!DataBrowser
		) {
			throw new Error(
				'One of required components (StdAppAction, AppFrameAction, appAction, jsdset, dswidget) not found in globals',
			);
		}

		if (!_moduleId) {
			throw new Error('_moduleId not defined');
		}

		function componentFactory() {
			const metadata = {
				cMSUserGroup: {
					fields: [
						{
							name: 'corporate_code',
							type: 'string',
							title: 'Kode Korporat',
							length: 10,
						},
						{
							name: 'group_id',
							type: 'string',
							title: 'Group Id',
							length: 15,
							validator: (value, fieldName) => {
								if (!value || value === "") {
									return [false, 'Group ID tidak boleh kosong'];
								} else {
									var passRegx = /^\S*$/;
									return value.match(passRegx) ? [true, ''] : [false, 'Group ID tidak boleh menggunakan spasi'];
								}
							},
						},
						{
							name: 'group_name',
							type: 'string',
							title: 'Nama Group',
							length: 100,
							validator: value => {
								let check = isExist(value, 'Nama Group', 5, 20);
								return check;
							},
						},
						{
							name: 'is_operator',
							type: 'string',
							title: 'Operator',
							length: 1,
						},
						{
							name: 'is_supervisor',
							type: 'string',
							title: 'Approver',
							length: 1,
						},
						{
							name: 'is_treasurer',
							type: 'string',
							title: 'Releaser',
							length: 1,
						},
						{
							name: 'cms_group_status',
							type: 'string',
							title: 'Status',
							length: 1,
						},
					],

					indexes: ['corporate_code'],
				},
			};

			const initialData = {
				'main:cMSUserGroup': [],
			};

			const serverDataMapping = {
				data: {
					dset: 'main',
					type: 'cMSUserGroup',
					fieldMapping: {
						'*': true, // allow default fields
					},
				},
			};

			const editUIData = {
				cMSUserGroup: {
					fields: {
						is_operator: {
							inputType: 'checkbox',
						},
						is_supervisor: {
							inputType: 'checkbox',
						},
						is_treasurer: {
							inputType: 'checkbox',
						},
						cms_group_status: {
							inputType: 'radio',
							readOnly: true,
							type: 'icon',
							variant: 'bordered',
							dataSets: {
								A: 'Aktif',
								S: 'Suspend',
								D: 'Nonaktif',
							},
							initialValue: 'A',
						},
					},
				},
			};

			// return value may be different depending on params
			return { metadata, initialData, serverDataMapping, editUIData };
		}

		return { componentFactory };
	}

	async function initModuleF(aReact, globals) {
		return await getImports(aReact, globals);
	}

	return initModuleF;
	// })()  //--DAFReact: deploy
} //--DAFReact: development
